import './LoaderSecondary.css';
import PropTypes from 'prop-types';

function LoaderSecondary({ size = '48px', color = 'light', className = '' }) {
    return (
        <div className={`okzou-secondary-loader ${color} ${className}`}>
            <span className="loader" style={{ width: size, height: size }}></span>
        </div>
    );
}

LoaderSecondary.propTypes = {
    size: PropTypes.string,
    color: PropTypes.oneOf(['light', 'dark', 'accent']),
    className: PropTypes.string,
};

export default LoaderSecondary;

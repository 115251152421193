import './Import.css';

// Libraries
import React, { useState, useEffect } from 'react';
import { usePapaParse } from 'react-papaparse';
import { useQuery, GET_TABLES_AND_PROPERTIES } from 'utils/graphql';

// Components
import Modal from 'components/Modal/Modal';
import Dropdown from 'components/Form/Dropdown/Dropdown';
import ContentPanel from 'components/ContentPanel/ContentPanel';
import DataTable from 'components/DataTable/DataTable';

// Asset
import { ReactComponent as CheckIcon } from 'assets/icons/check_icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon.svg';
import Loader from 'components/Loader/Loader';
import FileInput from 'components/Form/FileInput/FileInput';
import Button from 'components/Buttons/Button/Button';

function Import() {
    const { readRemoteFile } = usePapaParse();

    const [csvFile, setCsvFile] = useState();
    const [csvColumns, setCsvColumns] = useState();
    const [objectId, setObjectId] = useState();
    const [objectColumns, setObjectColumns] = useState();
    const [delimiter, setDelimiter] = useState(',');
    const [fileRequired, setFileRequired] = useState(false);
    const [fileValidated, setFileValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [modalOptions, setModalOptions] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);

    const getObjectColumnsOptions = () => {
        const options = [{ text: 'ignore', value: 'ignore' }];
        for (const col of objectColumns) {
            options.push({ text: col.property, value: col.property });
        }
        return options;
    };
    const tabFormats = [
        {
            value: ',',
            text: ',',
        },
        {
            value: '|',
            text: '|',
        },
        {
            value: '\t',
            text: 'Tab',
        },
        {
            value: ';',
            text: ';',
        },
    ];

    const columns = [
        {
            name: '#',
            sortable: false,
            selector: (row, i) => i,
        },
        {
            name: 'CSV Column',
            sortable: false,
            selector: (row) => row.name,
        },
        {
            name: 'Object Column',
            sortable: false,
            selector: (row) => {
                return (
                    <>
                        <Dropdown
                            width="150px"
                            value={row.existingCol || 'undefined'}
                            values={getObjectColumnsOptions()}
                            setValue={(e) => {
                                const updatedColumns = csvColumns.map((col) => {
                                    if (col.name === row.name) {
                                        col.existingCol = e.target.value;
                                    }
                                    return col;
                                });
                                setCsvColumns(updatedColumns);
                            }}
                        />
                        {row.error && <div className="error-text">Must be unique.</div>}
                    </>
                );
            },
        },
    ];

    const { data, loading } = useQuery(GET_TABLES_AND_PROPERTIES, {
        variables: { offset: 0, limit: 99 },
    });

    const onObjectChange = (objectId) => {
        setObjectId(objectId);

        // Set selected object columns
        const selectedObject = getObject(objectId);
        const columns = selectedObject.node.properties.edges.map((col) => col.node);
        columns.push({ id: 'id', property: 'id' });
        setObjectColumns(columns);
    };

    const getObject = (id) => {
        return data?.tableSchemaList.edges.find((table) => table.node.id === id);
    };

    const setModalType = (type = 'success') => {
        if (type === 'success') {
            setModalOptions({
                title: 'Success',
                content: (
                    <div>
                        <CheckIcon fill="green" />
                        <p>
                            CSV file import has started. You will be notified by email when it is
                            complete.
                        </p>
                    </div>
                ),
                width: '250px',
                textAlign: 'center',
            });
        } else if (type === 'error') {
            setModalOptions({
                title: 'Error',
                content: (
                    <div>
                        <ErrorIcon fill="red" />
                        <p>All object columns must be unique or set to "ignore".</p>
                    </div>
                ),
                width: '250px',
                textAlign: 'center',
            });
        }
    };

    const resetForm = () => {
        setCsvFile(null);
        setDelimiter(',');
        setCsvColumns();
        setObjectColumns();
        setFileRequired(false);
        setFileValidated(false);
        onObjectChange(data?.tableSchemaList.edges[0].node.id);
    };

    const validateColumns = () => {
        // Resetting all column errors
        for (const col of csvColumns) {
            col.error = false;
        }
        let errors = false;
        const _columns = [];
        for (const col of csvColumns) {
            if (col.existingCol === 'ignore') continue;
            const matchingCol = _columns.find((_col) => _col.existingCol === col.existingCol);
            if (matchingCol) {
                matchingCol.error = true;
                col.error = true;
                errors = true;
            }
            _columns.push(col);
        }

        if (errors) {
            setModalType('error');
            setIsOpen(true);
            return false;
        }
        return _columns;
    };

    const handleFileChange = (file) => {
        setCsvFile(file);
        setFileRequired(false);
    };

    const handleImport = async () => {
        // Validate all columns have a selection
        const _columns = validateColumns();
        if (!_columns) return false;

        setSubmitting(true);
        let formData = new FormData();
        formData.append('file', csvFile);
        formData.append('tableName', getObject(objectId)?.node.name);
        formData.append('delimiter', delimiter);
        formData.append('tableColumns', JSON.stringify(_columns));
        fetch(`${process.env.REACT_APP_BACKEND_URL}/queue/createCsvFileImport`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('okzou-cdp-token')}`,
            },
        })
            .then(() => {
                setModalType('success');
                setIsOpen(true);
                setSubmitting(false);
                resetForm();
            })
            .catch((err) => {
                console.log(err);
                setSubmitting(false);
            });
    };

    const validateFile = () => {
        if (!csvFile) {
            setFileRequired(true);
            return;
        }
        const config = {
            header: false,
            preview: 2,
            delimiter: delimiter,
            complete: function (results) {
                const firstRow = results.data[0][0];
                let cols;
                if (firstRow.startsWith('sep=')) {
                    cols = results.data[1];
                } else {
                    cols = results.data[0];
                }
                setCsvColumns(
                    cols?.map((col) => {
                        return {
                            name: col,
                            existingCol:
                                objectColumns.find((oCol) => col === oCol.property)?.property ||
                                'ignore',
                            error: false,
                        };
                    })
                );
                setFileValidated(true);
                setFileRequired(false);
            },
        };
        readRemoteFile(csvFile, config);
    };

    useEffect(() => {
        if (!data?.tableSchemaList.edges[0] || objectId) return;
        onObjectChange(data?.tableSchemaList.edges[0].node.id);
    }, [data]);

    return (
        <ContentPanel title="Import">
            {loading ? (
                <div className="import">
                    <Loader />
                </div>
            ) : (
                <div className="import">
                    {!fileValidated ? (
                        <div className="import-step-one">
                            <p className="import-input-label">Object</p>
                            <Dropdown
                                width="100%"
                                value={objectId}
                                values={data?.tableSchemaList.edges.map((table, i) => {
                                    return {
                                        text: table.node.name,
                                        value: table.node.id,
                                    };
                                })}
                                setValue={(e) => {
                                    onObjectChange(e.target.value);
                                }}
                            />
                            <p className="import-input-label">Separator</p>
                            <Dropdown
                                width="100%"
                                value={delimiter}
                                values={tabFormats}
                                setValue={(e) => {
                                    setDelimiter(e.target.value);
                                }}
                            />
                            <FileInput
                                className="csv-file-input"
                                width="400px"
                                file={csvFile}
                                setFile={handleFileChange}
                                label="Select CSV file"
                                accept=".csv"
                                error={fileRequired ? 'CSV file is required.' : null}
                            />
                            <div className="import-btn-row">
                                <Button onClick={validateFile}>Continue</Button>
                            </div>
                        </div>
                    ) : (
                        <div className="import-step-two">
                            <h3>Importing data into table: {getObject(objectId)?.node.name}</h3>
                            <DataTable
                                className="import-datatable"
                                columns={columns}
                                data={csvColumns}
                                pagination={false}
                                headerBackground={false}
                            />
                            <div className="import-btn-row">
                                <Button
                                    variant="secondary"
                                    onClick={() => setFileValidated(false)}
                                    isDisabled={submitting}
                                >
                                    Back
                                </Button>
                                <Button onClick={handleImport} isDisabled={submitting}>
                                    Import
                                </Button>
                            </div>
                        </div>
                    )}
                    <Modal options={modalOptions} isOpen={modalIsOpen} setOpen={setIsOpen} />
                </div>
            )}
        </ContentPanel>
    );
}

export default Import;

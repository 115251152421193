import { gql } from '@apollo/client';

export const GET_KPI = gql`
    query kpi($id: ID!) {
        kpi(id: $id) {
            primaryTableId
            name
            graph
            displayAs
            kpiDimensions {
                edges {
                    node {
                        id
                        name
                        tableId
                        tablePropertyId
                        aggregateFunction
                        alias
                        ntile
                        distinct
                        groupedBy
                        joinPath {
                            id
                            path
                        }
                    }
                }
            }
        }
    }
`;

export const GET_KPI_LIST_COUNT = gql`
    query ($search: String, $searchProperties: [String]) {
        kpiList(search: $search, searchProperties: $searchProperties) {
            totalCount
        }
    }
`;

export const GET_KPIS_FOR_TABLE = gql`
    query ($id: ID!) {
        kpiListForTable(tableId: $id)
    }
`;

export const GET_KPIS = gql`
    query (
        $offset: Int
        $limit: Int
        $search: String
        $searchProperties: [String]
        $orderBy: String
        $orderByDirection: GraphQLDirection
    ) {
        kpiList(
            offset: $offset
            limit: $limit
            search: $search
            searchProperties: $searchProperties
            orderBy: $orderBy
            orderByDirection: $orderByDirection
        ) {
            edges {
                node {
                    id
                    name
                    graph
                }
            }
        }
    }
`;

export const PREVIEW_KPI = gql`
    query ($columns: [KpiColumnInput], $primaryTableId: String!, $limit: Int, $graph: Graph!) {
        kpiPreview(primaryTableId: $primaryTableId, columns: $columns, limit: $limit, graph: $graph)
    }
`;

export const CREATE_KPI = gql`
    mutation ($kpi: CreateKpiInput!) {
        createKpi(kpi: $kpi) {
            kpi {
                id
            }
        }
    }
`;

export const UPDATE_KPI = gql`
    mutation ($kpi: UpdateKpiInput!) {
        updateKpi(kpi: $kpi) {
            kpi {
                id
            }
        }
    }
`;

export const DELETE_KPI = gql`
    mutation ($id: ID!) {
        deleteKpi(id: $id) {
            id
        }
    }
`;

import './FileInput.css';

// Libraries
import { useState } from 'react';

// Components
import InputError from '../InputError/InputError';

// Assets
import { ReactComponent as AttachIcon } from 'assets/icons/attach_file_icon_24.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon_24.svg';

const FileInput = ({
    label = null,
    file,
    setFile = () => {},
    accept = '*',
    error = null,
    width = '100%',
    minWidth = '100px',
    maxWidth,
    className = '',
}) => {
    const [inputKey, setInputKey] = useState(0);
    return (
        <div className={`file-input ${className}`} style={{ width, minWidth, maxWidth }}>
            {label && <div className="file-input-label">{label}</div>}
            <div
                className="file-input-drop"
                style={{ justifyContent: file ? 'space-between' : 'start' }}
            >
                <input
                    type="file"
                    accept={accept}
                    key={inputKey}
                    onChange={(event) => setFile(event.target.files[0])}
                />
                {file ? (
                    <>
                        <span className="file-name">{file.name}</span>
                        <CloseIcon
                            className="remove-file-btn"
                            onClick={(e) => {
                                setFile(null);
                                setInputKey(inputKey + 1);
                                e.stopPropagation();
                            }}
                        />
                    </>
                ) : (
                    <>
                        <AttachIcon className="file-input-icon" />
                        Select or Drop File
                    </>
                )}
            </div>
            <InputError error={error} />
        </div>
    );
};

export default FileInput;

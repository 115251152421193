import './Loader.css';
import { memo } from 'react';

const Loader = memo(({ size = 80 }) => {
    const generateWavePath = (startY, midY, endY, waveWidth, step) => {
        let path = `M-${waveWidth},${startY} `;
        for (let x = -waveWidth + step; x <= size + waveWidth; x += step) {
            const ctrlY = x % (2 * step) === 0 ? midY : endY;
            path += `Q ${x - step / 2},${ctrlY} ${x},${startY} `;
        }
        path += `V${size} H-${waveWidth} Z`;
        return path;
    };

    const viewBox = `0 0 ${size} ${size}`;
    const circleRadius = size / 2 - 5;
    const innerCircleRadius = circleRadius / 2;

    const duration = size <= 100 ? '4.5s' : '4.5s';
    const waveStartYDenominator = size <= 100 ? 1.2 : 1.26;
    const waveMidCalc = size <= 100 ? 10 : 10;
    const step = size <= 100 ? 15 : 15;

    // Adjust wave position
    const waveWidth = 300;
    const waveStartY = size / waveStartYDenominator;
    const waveMidY = waveStartY - waveMidCalc;
    const waveEndY = waveStartY + waveMidCalc;

    const wavePath = generateWavePath(waveStartY, waveMidY, waveEndY, waveWidth, step);

    return (
        <div className="okzou-loader">
            <svg width={size} height={size} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
                <defs>
                    {/* using mask to have the background color of the parent component showing below the loader */}
                    <mask id="circleMask">
                        <rect x="0" y="0" width={size} height={size} fill="white" />
                        {/* Inner circle is masked out */}
                        <circle cx={size / 2} cy={size / 2} r={innerCircleRadius} fill="black" />
                        {/* Animated wave is masked out */}
                        <path fill="black" stroke="black" d={wavePath}>
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="translate"
                                from="-150 0"
                                to="150 0"
                                dur={duration}
                                repeatCount="indefinite"
                            />
                        </path>
                    </mask>
                </defs>
                {/* Blue circle */}
                <circle
                    fill="#0075eb"
                    cx={size / 2}
                    cy={size / 2}
                    r={circleRadius}
                    stroke="#0075eb"
                    strokeWidth="5"
                    mask="url(#circleMask)"
                />
            </svg>
        </div>
    );
});

export default Loader;

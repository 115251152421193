// Libraries
import React, { useEffect } from 'react';

function Logout({ setToken }) {
    useEffect(() => {
        // Unset token
        setToken(null);

        // Send to login
        window.location.href = '/';
    }, []);

    return <></>;
}

export default Logout;

import './Filter.css';

// Libraries
import { useRef, useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

// Variables & Models
import {
    DraggableItemTypes,
    FilterFunctions,
    Operators,
} from 'views/Segmentation/models/definitions';
import { default as FilterModel } from 'views/Segmentation/models/filter';

// Assets
import { ReactComponent as RemoveIcon } from 'assets/icons/remove_icon.svg';
import { ReactComponent as RouteIcon } from 'assets/icons/route_icon_24.svg';

// Components
import IconButton from 'components/Buttons/IconButton/IconButton';
import SelectTableJoinPathModal from 'components/Segment/SelectTableJoinPathModal/SelectTableJoinPathModal';

const Filter = ({
    index,
    filter,
    filterGroup,
    updateFilterGroup,
    setIsCombinePreviewActive,
    isDraggingPlaceholder = false,
    placeholderWidth = null,
    primaryTableId,
}) => {
    const filterRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [isDragAllowed, setIsDragAllowed] = useState(true);
    const [isTableJoinPathModalOpen, setIsTableJoinPathModalOpen] = useState(false);

    const [{}, drag, preview] = useDrag(
        {
            type: DraggableItemTypes.FILTER,
            item: {
                index,
                type: DraggableItemTypes.FILTER,
                data: { filter, parent: filterGroup },
                width,
            },
            canDrag: (monitor) => {
                return isDragAllowed;
            },
            end: (item, monitor) => {
                // drop happened outside any filter group, reverting back filters to same order
                if (!monitor.didDrop()) {
                    updateFilterGroup({ filters: [...item.data.parent.filters] });
                    setIsCombinePreviewActive(false);
                }
            },
        },
        [filter, filterGroup, width, isDragAllowed]
    );

    drag(filterRef);

    const [displayValueInput, setDisplayValueInput] = useState(
        filter?.operator?.displayValue || false
    );

    const removeFilter = (i) => {
        filterGroup.deleteFilter(i);
        updateFilterGroup();
    };

    const setValue = (value) => {
        filter.value = value;
        updateFilterGroup();
    };

    const getFilterType = () => {
        return filter.isFunctionSelected ? 'Function' : filter.filterType;
    };

    const setOperator = (value) => {
        const operator = Operators[getFilterType()][value];
        filter.operator = operator;
        if (!operator.displayValue) {
            filter.value = '';
        }
        if (filter.filterType === 'Date') {
            const isDate = ![7, 8].includes(filter.operator.id);
            if (isDate && String(filter.value).indexOf('-') < 0) {
                filter.value = new Date().toISOString().substring(0, 10);
            } else if (!isDate && String(filter.value).indexOf('-') >= 0) {
                filter.value = 0;
            }
        }
        setDisplayValueInput(operator.displayValue || false);
        updateFilterGroup();
    };

    const setFunction = (e) => {
        filter.aggregateFunction = e.target.value;
        if (filter.filterType === 'Date') {
            setOperator(0);
        }
        updateFilterGroup();
    };

    const renderOperatorField = (type) => {
        return Operators[type]?.map((op, i) => (
            <option key={i} value={op.id}>
                {op.text}
            </option>
        ));
    };

    const renderFunctionField = (type) => {
        const functions =
            type === 'Date'
                ? FilterFunctions.filter((fn) => fn.value !== 'sum' && fn.value !== 'avg')
                : FilterFunctions;
        return functions.map((fn, i) => (
            <option key={i} value={fn.value}>
                {fn.text}
            </option>
        ));
    };

    const renderValueField = (type) => {
        switch (type) {
            case 'Function':
            case 'Number':
                return (
                    <input
                        type="number"
                        value={filter.value || ''}
                        onChange={(e) => setValue(e.target.value)}
                        onMouseEnter={() => setIsDragAllowed(false)}
                        onMouseLeave={() => setIsDragAllowed(true)}
                    />
                );
            case 'UUID':
            case 'Text':
                return (
                    <input
                        type="text"
                        value={filter.value || ''}
                        onChange={(e) => setValue(e.target.value)}
                        onMouseEnter={() => setIsDragAllowed(false)}
                        onMouseLeave={() => setIsDragAllowed(true)}
                    />
                );
            case 'Date':
                // is 'last x days' or 'next x days' operator selected? which expects a number value
                const inputType = [7, 8].includes(filter.operator.id) ? 'number' : 'date';
                return (
                    <input
                        type={inputType}
                        value={(() => {
                            try {
                                if (inputType === 'date') {
                                    return new Date(filter.value).toISOString().substring(0, 10);
                                } else {
                                    return parseInt(filter.value);
                                }
                            } catch (error) {
                                return '';
                            }
                        })()}
                        onChange={(e) => setValue(e.target.value)}
                        onMouseEnter={() => setIsDragAllowed(false)}
                        onMouseLeave={() => setIsDragAllowed(true)}
                    />
                );
            case 'Boolean':
                let value = '';
                if (
                    typeof filter.value == 'boolean' ||
                    filter.value === 'true' ||
                    filter.value === 'false'
                ) {
                    value = filter.value;
                }
                return (
                    <select
                        className="segment-dropdown"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    >
                        <option value="" disabled></option>
                        <option value={true}>true</option>
                        <option value={false}>false</option>
                    </select>
                );
        }
    };

    /**
     * Drag animation & Date Initialization
     */
    useEffect(() => {
        if (isDraggingPlaceholder) return;
        if (getFilterType() === 'Date' && !filter.value) {
            // initializing date filter
            setValue(new Date().toISOString().substring(0, 10));
        }
        preview(getEmptyImage(), { captureDraggingState: true });
        const filterRefElement = filterRef.current;
        if (!filterRefElement) return;
        const timeOutId = setTimeout(() => {
            setWidth(filterRefElement.getBoundingClientRect().width);
        }, 100);
        return () => clearTimeout(timeOutId);
    }, []);

    return (
        <div
            ref={filterRef}
            className={`filter ${!filter.isValid ? 'invalid' : ''} ${
                filter.isPlaceholder ? 'placeholder' : ''
            } ${isDraggingPlaceholder ? 'is-dragging-placeholder' : ''}`}
            style={{
                height: `${FilterModel.height}px`,
                marginBottom: `${FilterModel.bottomMargin}px`,
                width: isDraggingPlaceholder ? `${placeholderWidth}px` : '100%',
            }}
        >
            <div className="filter-name">
                <div>
                    <p>{filter.tableProperty.tableName}</p>
                    <p>{filter.tableProperty.propertyName}</p>
                </div>
                {filter.hasMultipleJoinTreePaths && (
                    <>
                        <IconButton
                            tooltip="Select Path"
                            onClick={() => setIsTableJoinPathModalOpen(true)}
                        >
                            <RouteIcon height="24" width="24" />
                        </IconButton>
                        <SelectTableJoinPathModal
                            isOpen={isTableJoinPathModalOpen}
                            setOpen={setIsTableJoinPathModalOpen}
                            tableProperty={filter.tableProperty}
                        />
                    </>
                )}
            </div>
            {filter.allowFunctions(primaryTableId) && (
                <div className="filter-function">
                    <p>Function</p>
                    <select
                        className="segment-dropdown"
                        value={filter.aggregateFunction || ''}
                        onChange={(e) => {
                            setFunction(e);
                        }}
                    >
                        {renderFunctionField(filter.filterType)}
                    </select>
                </div>
            )}
            <div className="filter-operator">
                <p>Operator</p>
                <select
                    className="segment-dropdown"
                    value={filter.operator.id}
                    onChange={(e) => {
                        setOperator(e.target.value);
                    }}
                >
                    {filter.isFunctionSelected
                        ? renderOperatorField('Function')
                        : renderOperatorField(filter.filterType)}
                </select>
            </div>
            {displayValueInput && (
                <div className="filter-value">
                    <p>Value</p>
                    {renderValueField(filter.filterType)}
                </div>
            )}
            <RemoveIcon
                className="filter-remove"
                fill="var(--error-color)"
                onClick={() => removeFilter(index)}
            />
        </div>
    );
};

export default Filter;

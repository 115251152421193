import { v4 as uuidv4 } from 'uuid';
import TableProperty from 'views/Segmentation/models/tableProperty';

class Filter {
    static height = 70;
    static bottomMargin = 10;
    static combineMiddleHeight = 10;
    constructor(
        tableProperty,
        operator,
        value,
        primaryTableTree,
        aggregateFunction = null,
        isNew = true,
        isPlaceholder = false,
        joinPath = null,
        id = uuidv4()
    ) {
        this.tableProperty = new TableProperty(
            tableProperty,
            primaryTableTree,
            joinPath?.path,
            joinPath?.id
        );
        this.operator = operator;
        this.value = value;
        this.id = id;
        this.isNew = isNew;
        this.isValid = true;
        this.aggregateFunction = aggregateFunction;
        this.isPlaceholder = isPlaceholder;
    }

    get filterType() {
        return this.tableProperty.type;
    }

    get isFilter() {
        return true;
    }

    get isFunctionSelected() {
        return this.aggregateFunction !== null && this.aggregateFunction !== '';
    }

    get joinPath() {
        return this.tableProperty.joinPath;
    }

    get joinPathId() {
        return this.tableProperty.joinPathId;
    }

    get hasMultipleJoinTreePaths() {
        return this.tableProperty.treePaths && this.tableProperty.treePaths.length > 1;
    }

    get joinTreePaths() {
        return this.tableProperty.treePaths;
    }

    setSelectedTreePathIndex(index) {
        this.selectedTreePathIndex = index;
    }

    allowFunctions(primaryTableId) {
        return (
            primaryTableId !== this.tableProperty.tableGlobalId &&
            (this.filterType === 'Number' || this.filterType === 'Date')
        );
    }

    validate() {
        this.isValid = true;
        if (this.operator.displayValue) {
            this.isValid = this.value != null && String(this.value) != '';
        }
        return this.isValid;
    }
}

export default Filter;

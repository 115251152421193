import { TOKEN_NAMESPACE, TOKEN_EXP_NAMESPACE } from 'components/useToken';
import { useEffect, useState } from 'react';

const exec = (url, config) => {
    return new Promise((resolve, reject) => {
        const { method = 'GET', headers = { 'Content-Type': 'application/json' }, body } = config;
        const token = localStorage.getItem(TOKEN_NAMESPACE);
        const exp = localStorage.getItem(TOKEN_EXP_NAMESPACE);

        if (config.timeout) {
            setTimeout(() => {
                reject('Request timed out');
            }, config.timeout);
        }

        // Check locally if token is expired
        const timestamp = new Date().getTime();
        if (exp && exp < timestamp) {
            reject('Token Expired.');
            window.location.href = '/logout';
        } else if (!token) {
            reject('Token Expired.');
            window.location.href = '/logout';
        }

        fetch(process.env.REACT_APP_BACKEND_URL + url, {
            method,
            headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
            },
            body,
        })
            .then((res) => res.json())
            .then((res) => resolve(res))
            .catch((err) => {
                // if (err.status === 401) {
                //     window.location.href = '/logout';
                // }
                reject(err);
            });
    });
};

export const useFetch = (url, config = {}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [data, setData] = useState();

    const { skip = false } = config;

    useEffect(() => {
        if (skip) return;
        exec(url, config)
            .then((res) => {
                setLoading(false);
                setData(res);
            })
            .catch((err) => {
                setLoading(false);
                setError(err);
            });
    }, [skip]);

    return {
        loading,
        data,
        error,
    };
};

// export const useSend = (url, config = {}) => {
//     return fetch(process.env.REACT_APP_BACKEND_URL + url, {
//         method: config.method || 'POST',
//         headers: {
//             Authorization: `Bearer ${token}`,
//         },
//     })
//         .then((res) => res.json())
//         .then((data) => {});
// };

import './Header.css';

// Libraries
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PermissionsContext } from 'App';

// Utils
import User from 'utils/user.util';
import { capFirst } from 'utils/text.util';

// Components
import CardList from 'components/CardList/CardList';

// Assets
import logo from 'assets/okzou_logo.png';
import { ReactComponent as AccountIcon } from 'assets/icons/account_icon.svg';
import { ReactComponent as SettingIcon } from 'assets/icons/settings_icon.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout_icon.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person_icon.svg';

function Header() {
    let navigate = useNavigate();
    const [showDropDown, setShowDropDown] = useState(false);
    const [canViewSettings, setCanViewSettings] = useState(false);
    const { permissions } = useContext(PermissionsContext);

    const handleClick = () => {
        setShowDropDown(!showDropDown);
    };

    const onMenuItemClick = (path) => {
        handleClick();
        navigate(path);
    };

    const menuItems = () => {
        let items = [
            {
                content: (
                    <div className="dropdown-item">
                        <div className="icon">
                            <AccountIcon />
                        </div>
                        Account
                    </div>
                ),
                clickCallBack: () => onMenuItemClick('/account'),
            },
            {
                content: (
                    <div className="dropdown-item">
                        <div className="icon">
                            <LogoutIcon />{' '}
                        </div>
                        Logout
                    </div>
                ),
                clickCallBack: () => onMenuItemClick('/logout'),
            },
        ];

        if (canViewSettings) {
            items.splice(1, 0, {
                content: (
                    <div className="dropdown-item">
                        <div className="icon">
                            <SettingIcon />
                        </div>
                        Settings
                    </div>
                ),
                clickCallBack: () => onMenuItemClick('/settings'),
            });
        }

        return items;
    };

    useEffect(() => {
        if (!permissions) return;

        permissions.forEach((permission) => {
            if (permission.object.includes('settings') && permission.action === 'read') {
                return setCanViewSettings(true);
            }
        });
    }, [permissions]);

    return (
        <div className="header-wrapper">
            <div className="logo" onClick={() => navigate('/')}>
                <img src={logo} />
            </div>
            <div className="menu">
                <div className="username">
                    <h2>{capFirst(User.getUserName())}</h2>
                    <p>{capFirst(User.getRole())}</p>
                </div>
                <div className="profile">
                    <div className="picture">
                        <button type="button" className="dropdownbtn" onClick={handleClick}>
                            <PersonIcon fill="white" />
                        </button>
                        <CardList
                            items={(() => menuItems())()}
                            onClickOutside={handleClick}
                            getCardItemContent={(item) => {
                                return item.content;
                            }}
                            show={showDropDown}
                            width="160px"
                            top="75px"
                            right="0"
                            className="dropdown"
                            showDivider="last"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;

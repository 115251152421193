import './Visualization.css';

// Libraries
import { useEffect, useState } from 'react';

// Components
import BarChart from 'components/SegmentationCharts/BarChart/BarChart';
import PieChart from 'components/SegmentationCharts/PieChart/PieChart';
import BubbleChart from 'components/SegmentationCharts/BubbleChart/BubbleChart';
import MatrixChart from 'components/SegmentationCharts/MatrixChart/MatrixChart';
import SingleValue from 'components/SegmentationCharts/SingleValue/SingleValue';

// Variables & models
import LineChartData from 'components/SegmentationCharts/models/lineChartData';
import { visualTypes, singleValueDisplayTypes } from 'views/Kpis/KpiBuilder/definitions';

// Utils
import { accentColorRgb, convertToRgbaString, generateSimilarColors } from 'utils/color.util';
import { generateRandomString } from 'utils/text.util';

const Visualization = ({ title, chartData, visualStyle, chartRef, results }) => {
    const [chart, setChart] = useState();

    useEffect(() => {
        if (!chartData) return;
        const xAxisTitle = chartData.xAxis.title ? chartData.xAxis.title : chartData.xAxis.alias;
        const yAxisTitle = chartData.yAxis?.title ? chartData.yAxis?.title : chartData.yAxis?.alias;
        const zAxisTitle = chartData.zAxis?.title ? chartData.zAxis?.title : chartData.zAxis?.alias;
        switch (visualStyle) {
            case visualTypes.BAR:
                let _xLabels, _yData;
                if (results) {
                    _xLabels = results.map((data) => data[chartData.xAxis.alias]);
                    _yData = results.map((data) => data[chartData.yAxis.alias]);
                } else {
                    _xLabels = Array.from({ length: 40 }, () => generateRandomString(5));
                    _yData = Array.from({ length: 40 }, () => Math.floor(Math.random() * 40));
                }
                setChart(
                    <BarChart
                        innerRef={chartRef}
                        title={title ? title : 'Bar Chart Preview'}
                        data={
                            new LineChartData(_xLabels, [
                                {
                                    label: yAxisTitle,
                                    data: _yData,
                                    borderColor: convertToRgbaString(accentColorRgb),
                                    backgroundColor: convertToRgbaString(accentColorRgb, 0.8),
                                    borderWidth: 1,
                                },
                            ])
                        }
                        xAxisTitle={xAxisTitle}
                        yAxisTitle={yAxisTitle}
                    />
                );
                break;
            case visualTypes.GROUPED_BAR:
                let primaryLabels = [];
                let groupByLabels = [];
                const resultsDictionary = {};

                if (results) {
                    for (const result of results) {
                        const groupByLabel = result[chartData.groupBy.alias];
                        const label = result[chartData.xAxis.alias];
                        if (resultsDictionary[label]) {
                            resultsDictionary[label][groupByLabel] = result[chartData.yAxis.alias];
                        } else {
                            resultsDictionary[label] = {
                                [groupByLabel]: result[chartData.yAxis.alias],
                            };
                        }
                        if (!groupByLabels.includes(groupByLabel)) {
                            groupByLabels.push(groupByLabel);
                        }
                    }
                    primaryLabels = Object.keys(resultsDictionary);
                } else {
                    primaryLabels = Array.from({ length: 20 }, () => generateRandomString(5));
                    groupByLabels = Array.from({ length: 5 }, () => generateRandomString(5));
                    for (let label of primaryLabels) {
                        for (let groupByLabel of groupByLabels) {
                            if (resultsDictionary[label]) {
                                resultsDictionary[label][groupByLabel] = Math.floor(
                                    Math.random() * 40
                                );
                            } else {
                                resultsDictionary[label] = {
                                    [groupByLabel]: Math.floor(Math.random() * 40),
                                };
                            }
                        }
                    }
                }
                const bgColors = generateSimilarColors(groupByLabels.length);
                setChart(
                    <BarChart
                        innerRef={chartRef}
                        title={title ? title : 'Grouped Bar Chart Preview'}
                        data={
                            new LineChartData(
                                primaryLabels,
                                groupByLabels.map((label, i) => {
                                    const data = [];
                                    for (let resultKey in resultsDictionary) {
                                        let resultValue = resultsDictionary[resultKey];
                                        data.push(resultValue[label] || 0);
                                    }
                                    return {
                                        label: label,
                                        data: data,
                                        borderColor: convertToRgbaString(accentColorRgb),
                                        backgroundColor: bgColors[i],
                                    };
                                })
                            )
                        }
                        xAxisTitle={xAxisTitle}
                        yAxisTitle={yAxisTitle}
                    />
                );
                break;
            case visualTypes.PIE:
                let _labels, _data;
                if (results) {
                    _labels = results.map((data) => data[chartData.xAxis.alias]);
                    _data = results.map((data) => data[chartData.yAxis.alias]);
                } else {
                    _labels = Array.from({ length: 5 }, () => generateRandomString(5));
                    _data = Array.from({ length: 5 }, () => Math.floor(Math.random() * 40));
                }
                setChart(
                    <PieChart
                        innerRef={chartRef}
                        title={title ? title : 'Pie Chart Preview'}
                        data={{
                            labels: _labels,
                            datasets: [
                                {
                                    label: yAxisTitle,
                                    data: _data,
                                    backgroundColor: generateSimilarColors(_data.length),
                                    borderColor: _data.map((d) =>
                                        convertToRgbaString(accentColorRgb)
                                    ),
                                    borderWidth: 1,
                                },
                            ],
                        }}
                    />
                );
                break;
            case visualTypes.BUBBLE:
                let bubbleData = [];
                if (results) {
                    for (const result of results) {
                        const existingDataPoint = bubbleData.find(
                            (dataPoint) =>
                                dataPoint.x === result[chartData.xAxis.alias] &&
                                dataPoint.y === result[chartData.yAxis.alias]
                        );
                        if (existingDataPoint) {
                            existingDataPoint.r =
                                parseInt(existingDataPoint.r) +
                                parseInt(result[chartData.zAxis.alias]);
                        } else {
                            bubbleData.push({
                                x: result[chartData.xAxis.alias],
                                y: result[chartData.yAxis.alias],
                                r: result[chartData.zAxis.alias],
                            });
                        }
                    }
                } else {
                    for (let i = 1; i <= 30; i++) {
                        const x = Math.floor(Math.random() * chartData.xAxis.ntile) + 1;
                        const y = Math.floor(Math.random() * chartData.yAxis.ntile) + 1;
                        const r = Math.floor(Math.random() * chartData.zAxis.ntile) + 1;
                        const existingDataPoint = bubbleData.find(
                            (bubbleData) => bubbleData.x === x && bubbleData.y === y
                        );
                        if (existingDataPoint) {
                            existingDataPoint.r = parseInt(existingDataPoint.r) + parseInt(r);
                        } else {
                            bubbleData.push({ x, y, r });
                        }
                    }
                }
                setChart(
                    <BubbleChart
                        innerRef={chartRef}
                        title={title ? title : 'Bubble Chart Preview'}
                        datasets={[
                            {
                                label: 'z-axis',
                                data: bubbleData,
                                borderColor: convertToRgbaString(accentColorRgb),
                                backgroundColor: convertToRgbaString(accentColorRgb, 0.8),
                            },
                        ]}
                        xAxisTitle={xAxisTitle}
                        yAxisTitle={yAxisTitle}
                        zAxisTitle={zAxisTitle}
                    />
                );
                break;
            case visualTypes.MATRIX:
                let _dataSet = [];
                let _xAxisLabels, _yAxisLabels;

                if (results) {
                    for (const result of results) {
                        const existingDataPoint = _dataSet.find(
                            (dataPoint) =>
                                dataPoint.x === result[chartData.xAxis.alias] &&
                                dataPoint.y === result[chartData.yAxis.alias]
                        );
                        if (existingDataPoint) {
                            existingDataPoint.z =
                                parseInt(existingDataPoint.z) +
                                parseInt(result[chartData.zAxis.alias]);
                        } else {
                            _dataSet.push({
                                x: result[chartData.xAxis.alias],
                                y: result[chartData.yAxis.alias],
                                z: result[chartData.zAxis.alias],
                            });
                        }
                    }
                } else {
                    for (let i = 1; i <= 100; i++) {
                        const x = Math.floor(Math.random() * chartData.xAxis.ntile) + 1;
                        const y = Math.floor(Math.random() * chartData.yAxis.ntile) + 1;
                        const z = Math.floor(Math.random() * chartData.zAxis.ntile) + 1;
                        const existingDataPoint = _dataSet.find(
                            (dataPoint) =>
                                dataPoint.x.toString() === x.toString() &&
                                dataPoint.y.toString() === y.toString()
                        );
                        if (existingDataPoint) {
                            existingDataPoint.z = (
                                parseInt(existingDataPoint.z) + parseInt(z)
                            ).toString();
                        } else {
                            _dataSet.push({ x: x.toString(), y: y.toString(), z: z.toString() });
                        }
                    }
                }
                _xAxisLabels = Array.from({ length: chartData.xAxis.ntile }, (_, i) =>
                    (i + 1).toString()
                );
                _yAxisLabels = Array.from({ length: chartData.yAxis.ntile }, (_, i) =>
                    (i + 1).toString()
                );

                setChart(
                    <MatrixChart
                        innerRef={chartRef}
                        title={title ? title : 'Matrix Chart Preview'}
                        datasets={[
                            {
                                data: _dataSet,
                            },
                        ]}
                        xAxisTitle={xAxisTitle}
                        yAxisTitle={yAxisTitle}
                        zAxisTitle={zAxisTitle}
                        xAxisLabels={_xAxisLabels}
                        yAxisLabels={_yAxisLabels}
                        backgroundRgbBaseColor={accentColorRgb}
                    />
                );
                break;
            case visualTypes.SINGLE_VALUE:
                let numerator = Math.floor(Math.random() * 300);
                let denominator =
                    chartData.displayType === singleValueDisplayTypes.FRACTION
                        ? Math.floor(Math.random() * 1000)
                        : 1;
                if (results && results.length >= 1) {
                    numerator = results[0][chartData.xAxis.alias];
                    denominator = chartData.useDenominator ? results[0][chartData.yAxis.alias] : 1;
                }
                setChart(
                    <SingleValue
                        title={title ? title : 'Single Value Preview'}
                        numerator={parseFloat(numerator)}
                        denominator={parseFloat(denominator)}
                        displayType={chartData.displayType}
                    />
                );
                break;
        }
    }, [title, chartData, visualStyle, results]);

    return (
        <div
            className="visualization"
            style={{ maxWidth: visualStyle === visualTypes.PIE ? '750px' : '100%' }}
        >
            {chart}
        </div>
    );
};

export default Visualization;

export const Operators = {
    Text: [
        { id: 0, text: 'is equal to', displayValue: true },
        { id: 1, text: 'is not equal to', displayValue: true },
        { id: 2, text: 'begins with', displayValue: true },
        { id: 3, text: 'ends with', displayValue: true },
        { id: 4, text: 'contains', displayValue: true },
        { id: 5, text: 'does not contain', displayValue: true },
        { id: 6, text: 'is empty', displayValue: false },
        { id: 7, text: 'is not empty', displayValue: false },
    ],
    Number: [
        { id: 0, text: 'is equal to', displayValue: true },
        { id: 1, text: 'is not equal to', displayValue: true },
        { id: 2, text: 'is greater than', displayValue: true },
        { id: 3, text: 'is greater than or equal to', displayValue: true },
        { id: 4, text: 'is less than', displayValue: true },
        { id: 5, text: 'is less than or equal to', displayValue: true },
        { id: 6, text: 'is empty', displayValue: false },
        { id: 7, text: 'is not empty', displayValue: false },
    ],
    Boolean: [
        { id: 0, text: 'is', displayValue: true },
        { id: 1, text: 'is not', displayValue: true },
    ],
    Date: [
        { id: 0, text: 'is equal to', displayValue: true },
        { id: 1, text: 'is not equal to', displayValue: true },
        { id: 2, text: 'is after', displayValue: true },
        { id: 3, text: 'is before', displayValue: true },
        { id: 4, text: 'is empty', displayValue: false },
        { id: 5, text: 'is not empty', displayValue: false },
        { id: 6, text: 'today', displayValue: false },
        { id: 7, text: 'last x days', displayValue: true },
        { id: 8, text: 'next x days', displayValue: true },
    ],
    UUID: [
        { id: 0, text: 'is equal to', displayValue: true },
        { id: 1, text: 'is not equal to', displayValue: true },
    ],
    Function: [
        { id: 0, text: 'is equal to', displayValue: true },
        { id: 1, text: 'is not equal to', displayValue: true },
        { id: 2, text: 'is greater than', displayValue: true },
        { id: 3, text: 'is greater than or equal to', displayValue: true },
        { id: 4, text: 'is less than', displayValue: true },
        { id: 5, text: 'is less than or equal to', displayValue: true },
    ],
};

export const FilterFunctions = [
    { text: 'None', value: '' },
    { text: 'SUM', value: 'sum' },
    { text: 'MIN', value: 'min' },
    { text: 'MAX', value: 'max' },
    { text: 'AVG', value: 'avg' },
];

export const DraggableItemTypes = {
    PROPERTY: 'property',
    FILTER: 'filter',
    FILTERGROUP: 'filterGroup',
};

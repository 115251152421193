import { gql } from './apollo';

export const GET_DATA_TYPES = gql`
    query {
        datatypeList {
            edges {
                node {
                    id
                    type
                }
            }
        }
    }
`;

import './SelectedTablePropertyPill.css';

// Assets
import { ReactComponent as MoreIcon } from 'assets/icons/more_vertical_icon2.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon_24.svg';
import { ReactComponent as FunctionIcon } from 'assets/icons/function_icon_24.svg';
import { ReactComponent as RouteIcon } from 'assets/icons/route_icon_24.svg';

// Libraries
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import SelectTableJoinPathModal from 'components/Segment/SelectTableJoinPathModal/SelectTableJoinPathModal';
import CardList from 'components/CardList/CardList';
import IconButton from 'components/Buttons/IconButton/IconButton';

function SelectedTablePropertyPill({
    primaryTableId,
    selectedTableProperty,
    removeFieldFunc,
    showMoreBtn = false,
    updateSelectedTableProperty = () => {},
}) {
    const [isTableJoinPathModalOpen, setIsTableJoinPathModalOpen] = useState(false);
    const [isAggregateFunctionMenuOpen, setIsAggregateFunctionMenuOpen] = useState(false);
    const [propertyDisplayName, setPropertyDisplayName] = useState(
        selectedTableProperty.tableProperty.propertyName
    );

    const setAggregateFunction = (aggregateFunction) => {
        selectedTableProperty.setAggregateFunction(aggregateFunction);
        if (typeof updateSelectedTableProperty === 'function') {
            updateSelectedTableProperty();
        }
        setIsAggregateFunctionMenuOpen(false);
    };

    useEffect(() => {
        let propertyName = selectedTableProperty.tableProperty.propertyName;
        if (propertyName === 'id') {
            propertyName = 'id (CDP id)';
        } else if (
            selectedTableProperty.aggregateFunction &&
            selectedTableProperty.aggregateFunction !== 'None'
        ) {
            propertyName = `${selectedTableProperty.aggregateFunction}(${propertyName})`;
        }
        setPropertyDisplayName(propertyName);
    }, [selectedTableProperty, selectedTableProperty.aggregateFunction]);

    return (
        <div className="selected-table-property-pill">
            <div
                className={`selected-pill ${
                    selectedTableProperty.hasError ? 'selected-pill-error' : ''
                }`}
            >
                <div className="selected-pill-icon" onClick={removeFieldFunc}>
                    <CloseIcon height={18} width={18} fill="#fff" />
                </div>
                <div className="selected-pill-text">
                    <span className="selected-pill-table">
                        <small>{selectedTableProperty.tableProperty.tableName}</small>
                    </span>
                    <span className="selected-pill-property">{propertyDisplayName}</span>
                </div>
                {selectedTableProperty.tableProperty.tableGlobalId !== primaryTableId && (
                    <>
                        <IconButton
                            padding="5px"
                            tooltip="Select Path"
                            onClick={() => setIsTableJoinPathModalOpen(true)}
                        >
                            <RouteIcon height={20} width={20} fill="#fff" />
                        </IconButton>
                        <SelectTableJoinPathModal
                            isOpen={isTableJoinPathModalOpen}
                            setOpen={setIsTableJoinPathModalOpen}
                            tableProperty={selectedTableProperty.tableProperty}
                        />
                    </>
                )}
                {selectedTableProperty.tableProperty.type === 'Number' && (
                    <>
                        <div className="selected-pill-function">
                            <IconButton
                                padding="5px"
                                tooltip="Select Function"
                                onClick={() => setIsAggregateFunctionMenuOpen(true)}
                            >
                                <FunctionIcon height={20} width={20} fill="#fff" />
                            </IconButton>
                        </div>
                        <CardList
                            items={['None', 'AVG', 'MAX', 'MIN', 'SUM']}
                            onClickOutside={() => setIsAggregateFunctionMenuOpen(false)}
                            onClickItemCallback={(item) => {
                                if (item === 'None') {
                                    setAggregateFunction(null);
                                } else {
                                    setAggregateFunction(item);
                                }
                            }}
                            getCardItemContent={(item) => item}
                            show={isAggregateFunctionMenuOpen}
                            width="auto"
                            top="40px"
                            right="10px"
                            showDivider="all"
                        />
                    </>
                )}
            </div>
            {showMoreBtn && (
                <div className="selected-pill-more">
                    <MoreIcon fill="var(--dark-grey-color)" />
                </div>
            )}
        </div>
    );
}

SelectedTablePropertyPill.propTypes = {
    primaryTableId: PropTypes.string,
    tableProperty: PropTypes.object,
    removeFieldFunc: PropTypes.func,
    showMoreBtn: PropTypes.bool,
};

export default SelectedTablePropertyPill;

import React from 'react';
import { Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import PropTypes from 'prop-types';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

function BubbleChart({
    title,
    datasets,
    xAxisTitle = null,
    yAxisTitle = null,
    zAxisTitle = null,
    tooltipTitleCallback = () => {
        return '';
    },
    innerRef = null,
}) {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: title,
                text: title,
            },
            tooltip: {
                callbacks: {
                    title: tooltipTitleCallback,
                    label(context) {
                        const item = context.dataset.data[context.dataIndex];
                        return [
                            `${xAxisTitle ? xAxisTitle : 'x'}: ${item.x}`,
                            `${yAxisTitle ? yAxisTitle : 'y'}: ${item.y}`,
                            `${zAxisTitle ? zAxisTitle : 'z'}: ${item.r}`,
                        ];
                    },
                },
            },
        },
        scales: {
            x: {
                offset: true,
                title: {
                    display: xAxisTitle != null,
                    text: xAxisTitle,
                    font: {
                        size: 15,
                    },
                },
                ticks: {
                    display: true,
                    stepSize: 1,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                offset: true,
                title: {
                    display: yAxisTitle != null,
                    text: yAxisTitle,
                    font: {
                        size: 15,
                    },
                },
                ticks: {
                    display: true,
                    stepSize: 1,
                },
                grid: {
                    display: false,
                },
            },
        },
    };

    return <Bubble options={options} data={{ datasets }} ref={innerRef} />;
}

BubbleChart.propTypes = {
    title: PropTypes.string,
    datasets: PropTypes.arrayOf(PropTypes.object).isRequired,
    xAxisTitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    zAxisTitle: PropTypes.string,
    tooltipTitleCallback: PropTypes.func,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

export default BubbleChart;

import './ComponentsDemo.css';

// Libraries
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Components
import Alert from 'components/Alert/Alert';
import LoadingIconButton from 'components/Buttons/LoadingIconButton/LoadingIconButton';
import ContentHeader from 'components/ContentHeader/ContentHeader';
import ContentPanel from 'components/ContentPanel/ContentPanel';
import Button from 'components/Buttons/Button/Button';
import IconButton from 'components/Buttons/IconButton/IconButton';
import CardList from 'components/CardList/CardList';
import DataTable from 'components/DataTable/DataTable';
import DraggablePill from 'components/DraggablePill/DraggablePill';

// Assets
import { ReactComponent as AddIcon } from 'assets/icons/add_icon.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save_icon.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/download_icon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit_icon.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/password_icon.svg';
import { ReactComponent as ErrorIconBig } from 'assets/icons/error_icon.svg';

import Dropdown from 'components/Form/Dropdown/Dropdown';
import FileInput from 'components/Form/FileInput/FileInput';
import Input from 'components/Form/Input/Input';
import Loader from 'components/Loader/Loader';
import LoaderSecondary from 'components/LoaderSecondary/LoaderSecondary';
import Modal from 'components/Modal/Modal';
import Search from 'components/Search/Search';
import Tooltip from 'components/Tooltip/Tooltip';

const ComponentsDemo = () => {
    const [isSaving, setIsSaving] = useState(false);
    const [showCardList, setShowCardList] = useState(false);
    const [showCardListTwo, setShowCardListTwo] = useState(false);
    const [dropdownValue, setDropdownValue] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [inputValue, setInputValue] = useState('');
    const [numberValue, setNumberValue] = useState('');
    const [dateValue, setDateValue] = useState('');
    const [isChecked, setIsChecked] = useState('');
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isConfModalOpen, setIsConfModalOpen] = useState(false);

    const users = [
        {
            firstName: 'Daniil',
            lastName: 'Karpov',
        },
        {
            firstName: 'Johnny',
            lastName: 'Brown',
        },
        {
            firstName: 'Donald',
            lastName: 'Duck',
        },
    ];

    const columns = [
        {
            name: 'First Name',
            sortable: true,
            selector: (row) => row.firstName,
        },
        {
            name: 'Last Name',
            sortable: true,
            selector: (row) => row.lastName,
        },
        {
            name: (() => {
                return (
                    <IconButton onClick={() => {}} padding="8px">
                        <AddIcon fill="var(--accent-color)" height="24" width="24" />
                    </IconButton>
                );
            })(),
            grow: 0,
            sortable: false,
            width: 'auto',
            selector: (row) => (
                <IconButton onClick={() => {}} padding="8px">
                    <EditIcon fill="var(--font-color)" height="24" width="24" />
                </IconButton>
            ),
        },
    ];

    return (
        <div className="components-demo">
            <ContentHeader title="I'm a Content Header" />
            <ContentHeader
                style={{ justifyContent: 'space-between' }}
                title="Can contain more complex elements like Input fields instead of a simple Header"
            >
                <div className="header-buttons">
                    <LoadingIconButton
                        isLoading={isSaving}
                        onClick={() => setIsSaving(true)}
                        className="content-header-save"
                        loaderColor="dark"
                    >
                        <SaveIcon fill="var(--accent-color)" height="24" width="24" />
                    </LoadingIconButton>
                </div>
            </ContentHeader>
            <ContentPanel title="I'm a Content Panel">
                <div className="content-panel-body">
                    <h2>Alerts</h2>
                    <Alert>I'm a Success Alert, with width set to auto by default!</Alert>
                    <Alert variant="error" width="100%">
                        I'm an Error Alert, with width set to 100%!
                    </Alert>
                    <Alert variant="warning" width="1000px" textAlign="start">
                        I'm an Warning Alert, with width set to 1000px and text align set to start!
                    </Alert>

                    <h2>Colors</h2>

                    <h3 style={{ color: 'var(--font-color)' }}>This is the font-color: #0f0f0f</h3>
                    <h3 style={{ color: 'var(--accent-color)' }}>
                        This is the accent-color: #0075eb
                    </h3>
                    <h3
                        style={{
                            color: 'var(--font-color)',
                            background: 'var(--accent-color-lime)',
                            padding: '10px',
                        }}
                    >
                        This is the accent-color-lime: #cff800 (currently only used to highlight the
                        header in ContentHeader).
                    </h3>
                    <h3 style={{ color: 'var(--dark-grey-color)' }}>
                        This is the dark-grey-color: #575757
                    </h3>
                    <h3
                        style={{
                            color: 'var(--font-color)',
                            background: 'var(--greyscale-color)',
                            padding: '10px',
                        }}
                    >
                        This is the greyscale-color: #fbfbfb (shown as the background)
                    </h3>
                    <h3
                        style={{
                            color: 'var(--font-color)',
                            background: 'var(--greyscale-highlight-color)',
                            padding: '10px',
                        }}
                    >
                        This is the greyscale-highlight-color: #f2f2f2 (shown as the background)
                    </h3>
                    <h3 style={{ color: 'var(--error-color)' }}>
                        This is the error-color: #ff5252
                    </h3>
                    <h3 style={{ color: 'var(--success-color)' }}>
                        This is the success-color: #4c9f70
                    </h3>
                    <h3 style={{ background: 'var(--bg-color-error)', padding: '10px' }}>
                        This is the bg-color-error: #f8d7da (currently only used as the background
                        color for Error Alert).
                    </h3>
                    <h3 style={{ background: 'var(--border-color-error)', padding: '10px' }}>
                        This is the border-color-error: #f5c2c7 (currently only used as the border
                        color for Error Alert).
                    </h3>
                    <h3 style={{ background: 'var(--bg-color-success)', padding: '10px' }}>
                        This is the bg-color-success: #d1e7dd (currently only used as the background
                        color for Success Alert).
                    </h3>
                    <h3 style={{ background: 'var(--border-color-success)', padding: '10px' }}>
                        This is the border-color-success: #badbcc (currently only used as the border
                        color for Success Alert).
                    </h3>
                    <h3 style={{ background: 'var(--bg-color-warning)', padding: '10px' }}>
                        This is the bg-color-warning: #fff3cd (currently only used as the background
                        color for Warning Alert).
                    </h3>
                    <h3 style={{ background: 'var(--border-color-warning)', padding: '10px' }}>
                        This is the border-color-warning: #ffecb5 (currently only used as the border
                        color for Warning Alert).
                    </h3>

                    <h2>Border Radius</h2>

                    <h3
                        style={{
                            background: '#000',
                            color: '#fff',
                            padding: '10px 20px',
                            borderRadius: 'var(--border-radius-23)',
                        }}
                    >
                        This is the border radius of 23px that we use across the app.
                    </h3>

                    <h2>Buttons</h2>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ marginRight: '10px' }}>Button Component:</h4>
                        <div style={{ marginRight: '10px' }}>
                            <Button width="150px">Primary Button</Button>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Button width="200px" tooltip="click me">
                                Primary Button with tooltip
                            </Button>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Button width="150px" variant="secondary">
                                Secondary Button
                            </Button>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Button width="150px" variant="danger">
                                Danger Button
                            </Button>
                        </div>
                        <Button width="200px" isDisabled={true}>
                            Disabled Primary Button
                        </Button>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ marginRight: '10px' }}>Icon Button Component:</h4>
                        <div style={{ marginRight: '10px' }}>
                            <IconButton onClick={() => {}} tooltip="Icon button with tooltip">
                                <AddIcon height="24" width="24" />
                            </IconButton>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <IconButton
                                onClick={() => {}}
                                tooltip="Icon button with less padding and smaller icon"
                                padding="5px"
                            >
                                <ImportIcon height="18" width="18" />
                            </IconButton>
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ marginRight: '10px' }}>Loading Icon Button Component:</h4>
                        <div style={{ marginRight: '10px' }}>
                            <LoadingIconButton
                                isLoading={isSaving}
                                onClick={() => setIsSaving(true)}
                                loaderColor="dark"
                                tooltip="Click me to see what happens"
                            >
                                <SaveIcon fill="var(--accent-color)" height="24" width="24" />
                            </LoadingIconButton>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <LoadingIconButton
                                isLoading={isSaving}
                                onClick={() => setIsSaving(true)}
                                loaderColor="light"
                                tooltip="Click me to see what happens"
                            >
                                <SaveIcon fill="var(--accent-color)" height="24" width="24" />
                            </LoadingIconButton>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <LoadingIconButton
                                isLoading={isSaving}
                                onClick={() => setIsSaving(true)}
                                loaderColor="accent"
                                tooltip="Click me to see what happens"
                            >
                                <SaveIcon fill="var(--accent-color)" height="24" width="24" />
                            </LoadingIconButton>
                        </div>
                    </div>

                    <h2>Card List</h2>

                    <div style={{ display: 'flex' }}>
                        <div style={{ position: 'relative', marginRight: '10px' }}>
                            <Button width="250px" onClick={() => setShowCardList(true)}>
                                Click to show CardList above
                            </Button>
                            <CardList
                                items={[
                                    {
                                        name: 'Item 1',
                                        clickCallBack: () => {},
                                    },
                                    {
                                        name: 'Item 2',
                                        clickCallBack: () => {},
                                    },
                                ]}
                                onClickOutside={() => setShowCardList(false)}
                                getCardItemContent={(item) => {
                                    return item.name;
                                }}
                                show={showCardList}
                                width="80px"
                                top="-70px"
                                left="85px"
                                showDivider="all"
                            />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <Button
                                width="250px"
                                variant="secondary"
                                onClick={() => setShowCardListTwo(true)}
                            >
                                Click to show CardList below
                            </Button>
                            <CardList
                                items={[
                                    {
                                        name: 'Longer Item 1',
                                        clickCallBack: () => {},
                                    },
                                    {
                                        name: 'Longer Item 2',
                                        clickCallBack: () => {},
                                    },
                                    {
                                        name: 'Longer Item 2',
                                        clickCallBack: () => {},
                                    },
                                ]}
                                onClickOutside={() => setShowCardListTwo(false)}
                                getCardItemContent={(item) => {
                                    return item.name;
                                }}
                                show={showCardListTwo}
                                width="120px"
                                top="50px"
                                left="65px"
                                showDivider="all"
                            />
                        </div>
                    </div>

                    <h2>Data Table</h2>

                    <DataTable
                        columns={columns}
                        data={users}
                        pagination={true}
                        defaultSortFieldId={1}
                        highlightOnHover={true}
                        onRowClicked={() => {}}
                        persistTableHead={true}
                    />

                    <h2>Draggable Pill</h2>

                    <DndProvider backend={HTML5Backend}>
                        <DraggablePill
                            index={0}
                            type="draggable_pill"
                            data={{}}
                            text="Draggable Pill"
                        />
                    </DndProvider>

                    <h2>Form Inputs</h2>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <h4 style={{ marginRight: '10px' }}>Dropdown:</h4>
                        <div style={{ marginRight: '10px' }}>
                            <Dropdown
                                value={dropdownValue}
                                values={[
                                    { value: 'Item 1', text: 'Item 1' },
                                    { value: 'Item 2', text: 'Item 2' },
                                    { value: 'Item 3', text: 'Item 3' },
                                ]}
                                setValue={(e) => {
                                    console.log(e.target.value);
                                }}
                            />
                        </div>
                        <Dropdown
                            readOnly={true}
                            width="150px"
                            value={dropdownValue}
                            values={[
                                { value: 'Item 1', text: 'Item 1' },
                                { value: 'Item 2', text: 'Item 2' },
                                { value: 'Item 3', text: 'Item 3' },
                            ]}
                            setValue={(e) => {
                                console.log(e.target.value);
                            }}
                        />
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <h4 style={{ marginRight: '10px' }}>File Input:</h4>
                        <div style={{ marginRight: '10px' }}>
                            <FileInput file={selectedFile} setFile={() => {}} accept=".zip" />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <FileInput
                                width="250px"
                                file={selectedFile}
                                setFile={() => {}}
                                label="File Input With Label"
                                accept=".zip"
                            />
                        </div>
                        <FileInput
                            width="250px"
                            file={selectedFile}
                            setFile={() => {}}
                            accept=".zip"
                            error={'File Input with error'}
                        />
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '20px',
                            flexWrap: 'wrap',
                        }}
                    >
                        <h4 style={{ marginRight: '10px' }}>Input:</h4>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                value={inputValue}
                                setValue={(e) => setInputValue(e.target.value)}
                                type="text"
                                placeholder="Text Input Field"
                            />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                value={inputValue}
                                setValue={(e) => setInputValue(e.target.value)}
                                type="text"
                                editIcon={true}
                                placeholder="Input with Edit Icon"
                            />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                value={inputValue}
                                disabled={true}
                                readOnly={true}
                                placeholder="Disabled Input"
                            />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                value={numberValue}
                                setValue={(e) => setNumberValue(e.target.value)}
                                type="number"
                                placeholder="Number Input"
                            />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                value={dateValue}
                                setValue={(e) => setDateValue(e.target.value)}
                                type="date"
                                placeholder="Date Input"
                            />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                value={isChecked}
                                setValue={(e) => setIsChecked(e.target.checked)}
                                type="checkbox"
                                placeholder="Checkbox"
                            />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                label="Input with label"
                                value={inputValue}
                                setValue={(e) => setInputValue(e.target.value)}
                                type="text"
                                placeholder="Text Input"
                            />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                value={inputValue}
                                setValue={(e) => setInputValue(e.target.value)}
                                type="text"
                                placeholder="Input with error"
                                error="Error msg"
                                displayError={true}
                            />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                value={inputValue}
                                setValue={(e) => setInputValue(e.target.value)}
                                type="text"
                                placeholder="Input with Icon"
                                inputIcon={<PasswordIcon fill="var(--dark-grey-color)" />}
                            />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                value={inputValue}
                                setValue={(e) => setInputValue(e.target.value)}
                                type="text"
                                placeholder="Light Input Variant"
                                variant="light"
                            />
                        </div>
                    </div>

                    <h2>Loaders</h2>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '20px',
                        }}
                    >
                        <div style={{ marginRight: '10px' }}>
                            <Loader />
                        </div>{' '}
                        <div style={{ marginRight: '10px' }}>
                            <LoaderSecondary color="dark" />
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <LoaderSecondary color="accent" />
                        </div>
                        <div
                            style={{
                                marginRight: '10px',
                                background: 'var(--accent-color)',
                                padding: '10px',
                            }}
                        >
                            <LoaderSecondary color="light" />
                        </div>
                    </div>

                    <h2>Modal</h2>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '20px',
                        }}
                    >
                        <div style={{ marginRight: '10px' }}>
                            <Button
                                width="250px"
                                variant="secondary"
                                onClick={() => setIsErrorModalOpen(true)}
                            >
                                Click to show error modal
                            </Button>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Button
                                width="250px"
                                variant="secondary"
                                onClick={() => setIsConfModalOpen(true)}
                            >
                                Click to show confirmation modal
                            </Button>
                        </div>
                        <Modal
                            options={{
                                title: <ErrorIconBig fill="var(--error-color)" />,
                                content: 'Error modal.',
                                width: '250px',
                                textAlign: 'center',
                            }}
                            isOpen={isErrorModalOpen}
                            setOpen={setIsErrorModalOpen}
                        />
                        <Modal
                            options={{
                                type: 'confirmation',
                                title: 'Warning',
                                content: 'Are you sure you want to proceed?',
                                confirmBtnText: 'Proceed',
                                cancelBtnText: 'Cancel',
                                width: '250px',
                                textAlign: 'center',
                                confirmationBtnAction: () => {},
                            }}
                            isOpen={isConfModalOpen}
                            setOpen={setIsConfModalOpen}
                        />
                    </div>

                    <h2>Tooltips</h2>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '20px',
                        }}
                    >
                        <div style={{ marginRight: '10px' }}>
                            <Tooltip tip="Bottom center tooltip" position="bottom-center">
                                Hover over me!
                            </Tooltip>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Tooltip tip="Top center tooltip" position="top-center">
                                Hover over me!
                            </Tooltip>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Tooltip tip="Left center tooltip" position="left-center">
                                Hover over me!
                            </Tooltip>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <Tooltip tip="Right center tooltip" position="right-center">
                                Hover over me!
                            </Tooltip>
                        </div>
                    </div>

                    <h2>Search Input</h2>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '20px',
                        }}
                    >
                        <Search />
                    </div>
                </div>
            </ContentPanel>
        </div>
    );
};

export default ComponentsDemo;

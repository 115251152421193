// Libraries
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Component & Views
import useToken from 'components/useToken';

function TokenRefresh({ children }) {
    const location = useLocation();
    const [previousLocation, setPreviousLocation] = useState();
    const { refresh } = useToken();

    useEffect(() => {
        if (previousLocation?.pathname != location?.pathname) {
            setPreviousLocation(location);

            // Check if token needs to be refreshed on render
            // it will only update if within 5 minutes of expiring
            refresh();
        }
    }, [location]);

    return <>{children}</>;
}

export default TokenRefresh;

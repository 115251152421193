import './Button.css';

// Libraries
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Tooltip from 'components/Tooltip/Tooltip';
import LoaderSecondary from 'components/LoaderSecondary/LoaderSecondary';

const Button = ({
    children,
    onClick,
    isDisabled = false,
    type = 'button',
    className = '',
    height = '45px',
    width = '100px',
    tooltip = null,
    variant = 'primary',
    isLoading = false,
}) => {
    const [loaderColor, setLoaderColor] = useState('light');
    const btn = (
        <button
            className={`button ${variant} ${className}`}
            onClick={(e) => {
                onClick && onClick(e);
            }}
            onMouseEnter={() => variant === 'secondary' && setLoaderColor('light')}
            onMouseLeave={() => variant === 'secondary' && setLoaderColor('dark')}
            disabled={isDisabled || isLoading}
            type={type}
            style={{ width, height }}
        >
            {isLoading && <LoaderSecondary size="20px" className="loader" color={loaderColor} />}
            <div
                className="button-content"
                style={{ visibility: isLoading ? 'hidden' : 'visible' }}
            >
                {children}
            </div>
        </button>
    );
    useEffect(() => {
        setLoaderColor(variant === 'secondary' ? 'dark' : 'light');
    }, [variant]);
    return <>{tooltip ? <Tooltip tip={tooltip}>{btn}</Tooltip> : btn}</>;
};

Button.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    tooltip: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'danger', 'secondary']),
};

export default Button;

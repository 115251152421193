import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// Components
import Loader from 'components/Loader/Loader';

// Utils
import User from 'utils/user.util';

const handleOAuth2 = async (app, name) => {
    const state = JSON.stringify({
        name,
        app_id: app.id,
        redirect: `${window.location.origin}/settings/connections/oauth2`,
    });

    return fetch(
        process.env.REACT_APP_BACKEND_URL + '/api/connected_apps/services/oauth2/get_url',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${User.getToken()}`,
            },
            body: JSON.stringify({
                app_id: app.id,
                state,
            }),
        }
    )
        .then((response) => response.json())
        .then((response) => {
            if (response?.error) {
                return { error: true };
            }
            window.location.href = response?.oauth_url;
        })
        .catch((e) => console.log(e));
};

function OAuth2() {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const { app_id, name } = JSON.parse(searchParams.get('state'));

    const saveCredentials = (credentials) => {
        if (!credentials) throw Error('Missing Credentials');

        // Split credentials and meta data
        const meta_data = { ...credentials?.meta_data };
        delete credentials?.meta_data;

        fetch(process.env.REACT_APP_BACKEND_URL + '/api/connected_apps/tenant/connections', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${User.getToken()}`,
            },
            body: JSON.stringify({
                name,
                app_id,
                app_credentials: credentials,
                meta_data: meta_data,
            }),
        }).then(() => (window.location.href = '/settings/connections'));
    };

    /**
     * Exchange short-lived token for long-term token.
     */
    const tokenExchange = async () => {
        fetch(
            process.env.REACT_APP_BACKEND_URL +
                '/api/connected_apps/services/oauth2/exchange_token',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${User.getToken()}`,
                },
                body: JSON.stringify({
                    app_id,
                    code,
                }),
            }
        )
            .then((res) => res.json())
            .then((res) => {
                if (!res.error) {
                    saveCredentials(res);
                }
            })
            .catch(function (error) {
                // Todo: error message
                console.log(error);
            });
    };

    useEffect(() => {
        tokenExchange();
    }, []);

    return <Loader />;
}

export { OAuth2, handleOAuth2 };

import { gql } from './apollo';

export const GET_ROLES = gql`
    query {
        roleList {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

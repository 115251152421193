export const visualTypes = {
    BAR: 'BAR',
    GROUPED_BAR: 'GROUPED_BAR',
    PIE: 'PIE',
    BUBBLE: 'BUBBLE',
    MATRIX: 'MATRIX',
    SINGLE_VALUE: 'SINGLE_VALUE',
};

export const singleValueDisplayTypes = {
    INT: 'INTEGER',
    DOLLAR: 'DOLLAR',
    FRACTION: 'FRACTION',
    PERCENT: 'PERCENTAGE',
};

import './UnifyExceptions.css';

// Libraries
import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

// Utils & hooks
import { useLazyQuery, GET_TABLE, buildTableSearchFilters } from 'utils/graphql';
import { hasPermissionFor } from 'utils/permission.util';

// Components
import Search from 'components/Search/Search';
import DataTable from 'components/DataTable/DataTable';
import Loader from 'components/Loader/Loader';
import Tooltip from 'components/Tooltip/Tooltip';
import IconButton from 'components/Buttons/IconButton/IconButton';
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';

// Assets
import { ReactComponent as AddIcon } from 'assets/icons/add_icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_icon.svg';

const UnifyExceptions = ({ exceptions, setExceptions, table, tableProperties }) => {
    const [query, setQuery] = useState('');
    const [loadingObjects, setLoadingObjects] = useState(false);
    const [columns, setColumns] = useState([]);
    const [exceptionCols, setExceptionCols] = useState([]);
    const [exceptionObjects, setExceptionObjects] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [totalRows, setTotalRows] = useState(0);
    const { permissionSet } = useContext(CurrentPermissionContext);

    const dataTableStyles = {
        rows: {
            style: {
                backgroundColor: 'var(--greyscale-color)',
            },
        },
        table: {
            style: {
                backgroundColor: 'var(--greyscale-color)',
            },
        },
        noData: {
            style: {
                backgroundColor: 'var(--greyscale-color)',
                fontWeight: 'bold',
                marginBottom: '30px',
            },
        },
        pagination: {
            style: {
                backgroundColor: 'var(--greyscale-color)',
            },
        },
    };

    const [getTableData] = useLazyQuery(GET_TABLE, {
        fetchPolicy: 'network-only',
        skip: !table,
        variables: {
            name: table?.name,
            offset: 0,
            limit: perPage,
        },
        onCompleted: (data) => {
            setExceptionObjects(data.table.records.map((record) => record[table?.name]));
            setTotalRows(data.table.count);
            setLoadingObjects(false);
        },
        onError: (error) => {
            console.log(error);
            setLoadingObjects(false);
        },
    });

    const addException = useCallback(
        (exception) => {
            setExceptions((prev) => {
                const existingException = prev.find((e) => e.id === exception.id);
                if (!existingException) {
                    return [...prev, exception];
                }
                return prev;
            });
        },
        [setExceptions]
    );

    const removeException = useCallback(
        (index) => setExceptions((prev) => prev.filter((_, i) => i !== index)),
        [setExceptions]
    );

    useEffect(() => {
        if (!tableProperties || !query) return;
        setLoadingObjects(true);
        getTableData({
            variables: {
                filter: buildTableSearchFilters(query, tableProperties),
                offset: perPage * (page - 1),
                limit: perPage,
            },
        });
    }, [tableProperties, query, perPage, page, getTableData]);

    useEffect(() => {
        if (!tableProperties) return;
        const encryptReadPermission = hasPermissionFor(permissionSet, 'database.encrypted', 'read');
        const cols = [
            {
                name: 'id',
                style: {
                    whiteSpace: 'nowrap',
                    maxWidth: '150px',
                },
                selector: (row) => row.id,
                cell: (row) => (
                    <Tooltip tip={row.id}>
                        <div className="truncated-cell">{row.id}</div>
                    </Tooltip>
                ),
            },
            ...tableProperties.map((tp) => ({
                name: tp.property,
                style: {
                    whiteSpace: 'nowrap',
                    maxWidth: '150px',
                },
                selector: (row) => row[tp.property]?.toString(),
                cell: (row) => {
                    if (tp.encrypt && !encryptReadPermission) {
                        return <div style={{ filter: 'blur(3px)' }}>encrypted</div>;
                    }
                    return (
                        <Tooltip tip={row[tp.property]?.toString()}>
                            <div className="truncated-cell">{row[tp.property]?.toString()}</div>
                        </Tooltip>
                    );
                },
            })),
        ];
        setColumns([
            {
                name: 'add',
                style: {
                    whiteSpace: 'nowrap',
                    maxWidth: '150px',
                },
                cell: (row) => (
                    <IconButton
                        onClick={() => addException(row)}
                        padding="8px"
                        tooltip="Add Exception"
                    >
                        <AddIcon
                            fill="var(--accent-color)"
                            height="24"
                            width="24"
                            tooltip="Add Exception"
                        />
                    </IconButton>
                ),
            },
            ...cols,
        ]);
        setExceptionCols([
            {
                name: 'remove',
                style: {
                    whiteSpace: 'nowrap',
                    maxWidth: '150px',
                },
                cell: (row, index) => (
                    <IconButton
                        onClick={() => removeException(index)}
                        padding="8px"
                        tooltip="Remove Exception"
                    >
                        <DeleteIcon fill="var(--error-color)" height="24" width="24" />
                    </IconButton>
                ),
            },
            ...cols,
        ]);
    }, [tableProperties, setExceptionCols, removeException, addException, permissionSet]);

    return (
        <div className="unify-exceptions">
            <Search handler={(query) => setQuery(query)} width="100%" />
            {(exceptionObjects?.length > 0 || loadingObjects) && (
                <DataTable
                    columns={columns}
                    data={exceptionObjects}
                    pagination={true}
                    paginationServer={true}
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    paginationDefaultPage={page}
                    highlightOnHover={true}
                    onChangeRowsPerPage={(rowsPerPage) => setPerPage(rowsPerPage)}
                    onChangePage={(page) => setPage(page)}
                    progressPending={loadingObjects}
                    progressComponent={<Loader />}
                    className="objects-table"
                    customStyles={dataTableStyles}
                />
            )}
            <div className="exceptions">
                <h3>Exceptions</h3>
                <DataTable
                    columns={exceptionCols}
                    data={exceptions}
                    noDataComponent={`Need to add an exception? Use the search bar to find ${table.name} 
                                        objects to add as exceptions so they can never be unified.`}
                    pagination={false}
                    highlightOnHover={true}
                    className="exceptions-table"
                    onRowClicked={(row) => addException(row)}
                    customStyles={dataTableStyles}
                />
            </div>
        </div>
    );
};

UnifyExceptions.propTypes = {
    exceptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    setExceptions: PropTypes.func.isRequired,
    table: PropTypes.object.isRequired,
    tableProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UnifyExceptions;

export function capFirst(string) {
    const text = string.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function ucWords(string) {
    let text = string.toLowerCase();
    return text.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (s) {
        return s.toUpperCase();
    });
}

export function generateRandomString(length, characters = 'abcdefghijklmnopqrstuvwxyz') {
    let result = '';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function formatNumber(value) {
    if (typeof value !== 'number' || isNaN(value)) {
        throw new Error('Input must be a valid number');
    }
    if (Number.isInteger(value)) {
        return value.toLocaleString();
    } else {
        return value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }
}

import './Alert.css';

// variants: success, error, warning
// textAlign: start, center, end
function Alert({
    children,
    variant = 'success',
    textAlign = 'center',
    width = 'auto',
    className = '',
}) {
    return (
        <div className={`alert alert-${variant} text-${textAlign} ${className}`} style={{ width }}>
            {children}
        </div>
    );
}

export default Alert;

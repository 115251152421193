import './KpiBuilder.css';

// Libraries
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';
import Button from 'components/Buttons/Button/Button';
import Alert from 'components/Alert/Alert';
import SideMenu from 'components/SideMenu/SideMenu';
import CardList from 'components/CardList/CardList';
import Accordion from 'components/Accordion/Accordion';
import Dropdown from 'components/Form/Dropdown/Dropdown';
import Input from 'components/Form/Input/Input';
import EditableHighlightField from 'components/EditableHighlightField/EditableHighlightField';
import Modal from 'components/Modal/Modal';
import InputError from 'components/Form/InputError/InputError';
import Loader from 'components/Loader/Loader';
import IconButton from 'components/Buttons/IconButton/IconButton';
import SelectTableJoinPathModal from 'components/Segment/SelectTableJoinPathModal/SelectTableJoinPathModal';
import Visualization from 'components/Visualization/Visualization';

// Assets
import { ReactComponent as BarChartIcon } from 'assets/icons/bar_chart_icon_24.svg';
import { ReactComponent as GroupedBarChartIcon } from 'assets/icons/grouped_bar_chart_icon_24.svg';
import { ReactComponent as PieChartIcon } from 'assets/icons/pie_chart_icon_24.svg';
import { ReactComponent as BubbleChartIcon } from 'assets/icons/bubble_chart_icon_24.svg';
import { ReactComponent as MatrixChartIcon } from 'assets/icons/matrix_chart_icon_24.svg';
import { ReactComponent as OneIcon } from 'assets/icons/one_icon_24.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';
import { ReactComponent as RouteIcon } from 'assets/icons/route_icon_24.svg';

// Variables & Models
import { FilterFunctions } from 'views/Segmentation/models/definitions';
import { singleValueDisplayTypes, visualTypes } from './definitions';
import KpiTableAxis from './models/kpiTableAxis';
import TableProperty from 'views/Segmentation/models/tableProperty';

// Utils & hooks
import {
    useQuery,
    GET_TABLES_AND_PROPERTIES,
    useLazyQuery,
    PREVIEW_KPI,
    useMutation,
    CREATE_KPI,
    GET_KPI,
    UPDATE_KPI,
    GET_SEGMENT_TABLE_TREE,
} from 'utils/graphql';
import { useNavCollapse } from 'contexts/NavCollapseContext';
import useScreenResize from 'hooks/useScreenResize';
import { getTableArrayFromTableTree } from 'utils/segment.util';

const KpiBuilder = ({}) => {
    let navigate = useNavigate();
    const [selectedVisualStyle, setSelectedVisualStyle] = useState();
    const [error, setError] = useState();
    const [onSecondStep, setOnSecondStep] = useState(false);
    const [chartTypeMenuOpen, setChartTypeMenuOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [finishedLoadingDimensions, setFinishedLoadingDimensions] = useState(false);
    const [finishedLoadingKpi, setFinishedLoadingKpi] = useState(false);
    const [firstSetupComplete, setFirstSetupComplete] = useState(false);

    const [primaryTableId, setPrimaryTableId] = useState();
    const [primaryTableTree, setPrimaryTableTree] = useState();
    const [tables, setTables] = useState([]);
    const [joinTables, setJoinTables] = useState([]);
    const [graphDimensions, setGraphDimensions] = useState([]);
    const [kpiName, setKpiName] = useState('');
    const [chartTitle, setChartTitle] = useState('');
    const [kpiNameError, setKpiNameError] = useState('');
    const [dimensionErrors, setDimensionErrors] = useState([]);

    const [xAxis, setXAxis] = useState();
    const [yAxis, setYAxis] = useState();
    const [zAxis, setZAxis] = useState();

    const [groupedById, setGroupedById] = useState();
    const [groupedByTable, setGroupedByTable] = useState();
    const [groupedByProperty, setGroupedByProperty] = useState();
    const [groupedByDisplayName, setGroupedByDisplayName] = useState('');
    const [groupedByPreviewName, setGroupedByPreviewName] = useState(null);

    const [displayAs, setDisplayAs] = useState(singleValueDisplayTypes.INT);
    const [useDenominator, setUseDenominator] = useState(false);

    const [results, setResults] = useState(null);
    const [chartData, setChartData] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalType, setModalType] = useState();

    const [isNavMenuResizing, setIsNavMenuResizing] = useState(false);
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);
    const [chartPreviewWidth, setChartPreviewWidth] = useState('calc(100% - 300px)');
    const [chartPreviewTop, setChartPreviewTop] = useState(0);
    const [chartPreviewLeftPos, setChartPreviewLeftPos] = useState();
    const previewRef = useRef(null);
    const chartRef = useRef(null);
    const navCollapsed = useNavCollapse();

    const [isTableJoinPathModalOpen, setIsTableJoinPathModalOpen] = useState(false);
    const [joinPathTableProperty, setJoinPathTableProperty] = useState(null);

    const handleScrollAndResize = () => {
        if (!previewRef.current) return;
        const chartPreviewBreakPoint =
            window.scrollY + previewRef.current.getBoundingClientRect().top;
        // checking if break point is hit (top of chart preview element)
        if (window.scrollY >= chartPreviewBreakPoint) {
            setChartPreviewTop(-previewRef.current.getBoundingClientRect().top + 10);
        } else {
            setChartPreviewTop(0);
        }
        updatePreviewWidthAndPosition();
    };
    const kpiBuilderRef = useScreenResize(handleScrollAndResize);

    let { kpi_id } = useParams();
    if (kpi_id === 'new') {
        kpi_id = null;
    }

    const visualizationStyles = [
        {
            id: visualTypes.BAR,
            name: 'Bar Chart',
            icon: BarChartIcon,
        },
        {
            id: visualTypes.GROUPED_BAR,
            name: 'Grouped Bar Chart',
            icon: GroupedBarChartIcon,
        },
        {
            id: visualTypes.PIE,
            name: 'Pie Chart',
            icon: PieChartIcon,
        },
        {
            id: visualTypes.BUBBLE,
            name: 'Bubble Chart',
            icon: BubbleChartIcon,
        },
        {
            id: visualTypes.MATRIX,
            name: 'Matrix Chart',
            icon: MatrixChartIcon,
        },
        {
            id: visualTypes.SINGLE_VALUE,
            name: 'Single Value',
            icon: OneIcon,
        },
    ];

    const { data: kpiData } = useQuery(GET_KPI, {
        skip: !kpi_id,
        variables: { id: kpi_id },
        fetchPolicy: 'no-cache',
    });

    // Loadign table tree associated with primary table
    const { data: tableTree, loading: loadingTableTree } = useQuery(GET_SEGMENT_TABLE_TREE, {
        skip: !primaryTableId,
        variables: { tableId: primaryTableId },
    });

    const { loading: loadingTables } = useQuery(GET_TABLES_AND_PROPERTIES, {
        onCompleted: (results) => {
            const tables = results?.tableSchemaList?.edges.map((edge) => ({
                ...edge.node,
                properties: edge.node.properties.edges.map((edge) => edge.node),
            }));
            setTables(tables);
            if (!kpi_id) {
                setPrimaryTableId(tables[0].id);
            }
        },
    });

    const [previewKpi] = useLazyQuery(PREVIEW_KPI, {
        fetchPolicy: 'no-cache',
        onError: (error) => {
            console.log(error);
        },
    });

    const [createKpi] = useMutation(CREATE_KPI);
    const [updateKpi] = useMutation(UPDATE_KPI);

    const nextStep = () => {
        setError(null);
        if (!selectedVisualStyle) {
            setError('Select a visualization style before proceeding to the next step.');
            return;
        }
        setOnSecondStep(true);
    };

    const openModal = (type) => {
        setModalType(type);
        setModalIsOpen(true);
    };

    const getModalOptions = () => {
        switch (modalType) {
            case 'createSuccess':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>New kpi created.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => {},
                };
            case 'createError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: <p>There was an error while creating the kpi.</p>,
                    width: '250px',
                    textAlign: 'center',
                };
            case 'updateSuccess':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>Kpi updated.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => {},
                };
            case 'updateError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: <p>There was an error while updating the kpi.</p>,
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    const validate = () => {
        setKpiNameError('');
        let valid = true;
        if (!kpiName || kpiName.trim().length < 3) {
            setKpiNameError('Kpi name must be at least 3 characters.');
            valid = false;
        }
        const dimensionsAreValid = validateDimensions();
        return valid && dimensionsAreValid;
    };

    const validateDimensions = () => {
        let errors = [];
        switch (selectedVisualStyle.id) {
            case visualTypes.BAR:
            case visualTypes.GROUPED_BAR:
            case visualTypes.PIE:
                if (xAxis.aggregateFunction && yAxis.aggregateFunction) {
                    errors.push(
                        'Cannot set both the x axis and y axis aggregate functions for this chart type.'
                    );
                }
                if (
                    !(
                        yAxis.tablePropertyType === 'Number' ||
                        (yAxis.aggregateFunction && yAxis.tablePropertyType !== 'Date')
                    )
                ) {
                    errors.push('Y axis must display numeric values.');
                }
                break;
            case visualTypes.BUBBLE:
            case visualTypes.MATRIX:
                if (xAxis.numberOfPartitions < 2) {
                    errors.push('X axis number of partitions must be 2 or greater');
                }
                if (yAxis.numberOfPartitions < 2) {
                    errors.push('Y axis number of partitions must be 2 or greater');
                }
                if (zAxis.numberOfPartitions < 2) {
                    errors.push('Z axis number of partitions must be 2 or greater');
                }
        }
        setDimensionErrors(errors);
        return errors.length === 0;
    };

    const save = async () => {
        setIsSaving(true);
        if (!validate()) {
            setIsSaving(false);
            return;
        }
        const kpiDimensions = [
            {
                name: 'x_axis',
                ...(kpi_id ? { id: xAxis.id } : {}),
                tableId: xAxis.tableId,
                tablePropertyId: xAxis.tablePropertyName === 'id' ? null : xAxis.tablePropertyId,
                aggregateFunction: xAxis.aggregateFunction ? xAxis.aggregateFunction : null,
                alias: xAxis.title ? xAxis.title : `${xAxis.tableName} ${xAxis.tablePropertyName}`,
                ntile: xAxis.showPartitionsField ? xAxis.numberOfPartitions : null,
                distinct: selectedVisualStyle.id === visualTypes.SINGLE_VALUE,
                joinPath:
                    xAxis.tableId !== primaryTableId &&
                    xAxis.tableGlobalId !== primaryTableId &&
                    xAxis.tableProperty.joinPath
                        ? {
                              path: `${JSON.stringify(xAxis.tableProperty.joinPath)}`,
                              ...(kpi_id ? { id: xAxis.joinPathId } : {}),
                          }
                        : null,
                groupedBy:
                    (selectedVisualStyle.id === visualTypes.BAR ||
                        selectedVisualStyle.id === visualTypes.PIE) &&
                    xAxis.tableId === yAxis.tableId &&
                    yAxis.aggregateFunction,
            },
        ];

        if (
            selectedVisualStyle.id !== visualTypes.SINGLE_VALUE ||
            (selectedVisualStyle.id === visualTypes.SINGLE_VALUE && useDenominator)
        ) {
            kpiDimensions.push({
                name: 'y_axis',
                ...(kpi_id ? { id: yAxis.id } : {}),
                tableId: yAxis.tableId,
                tablePropertyId: yAxis.tablePropertyName === 'id' ? null : yAxis.tablePropertyId,
                aggregateFunction: yAxis.aggregateFunction ? yAxis.aggregateFunction : null,
                alias: yAxis.title ? yAxis.title : `${yAxis.tableName} ${yAxis.tablePropertyName}`,
                ntile: yAxis.showPartitionsField ? yAxis.numberOfPartitions : null,
                distinct: selectedVisualStyle.id === visualTypes.SINGLE_VALUE,
                joinPath:
                    yAxis.tableId !== primaryTableId &&
                    yAxis.tableGlobalId !== primaryTableId &&
                    yAxis.tableProperty.joinPath
                        ? {
                              path: `${JSON.stringify(yAxis.tableProperty.joinPath)}`,
                              ...(kpi_id ? { id: yAxis.joinPathId } : {}),
                          }
                        : null,
                groupedBy:
                    (selectedVisualStyle.id === visualTypes.BAR ||
                        selectedVisualStyle.id === visualTypes.PIE) &&
                    xAxis.tableId === yAxis.tableId &&
                    xAxis.aggregateFunction,
            });
        }
        if (selectedVisualStyle.id === visualTypes.GROUPED_BAR) {
            kpiDimensions.push({
                name: 'grouped_by',
                ...(kpi_id ? { id: groupedById } : {}),
                tableId: groupedByTable.id,
                tablePropertyId:
                    groupedByProperty.propertyName === 'id' ? null : groupedByProperty.propertyId,
                aggregateFunction: null,
                alias: groupedByDisplayName
                    ? groupedByDisplayName
                    : `${groupedByTable.name} ${groupedByProperty.propertyName}`,
                joinPath:
                    groupedByProperty.tableId !== primaryTableId &&
                    groupedByProperty.tableGlobalId !== primaryTableId &&
                    groupedByProperty.joinPath
                        ? {
                              path: `${JSON.stringify(groupedByProperty.joinPath)}`,
                              ...(kpi_id ? { id: groupedByProperty.joinPathId } : {}),
                          }
                        : null,
                groupedBy: true,
            });
        }
        if (
            selectedVisualStyle.id === visualTypes.MATRIX ||
            selectedVisualStyle.id === visualTypes.BUBBLE
        ) {
            kpiDimensions.push({
                name: 'z_axis',
                ...(kpi_id ? { id: zAxis.id } : {}),
                tableId: zAxis.tableId,
                tablePropertyId: zAxis.tablePropertyName === 'id' ? null : zAxis.tablePropertyId,
                aggregateFunction: zAxis.aggregateFunction ? zAxis.aggregateFunction : null,
                alias: zAxis.title ? zAxis.title : `${zAxis.tableName} ${zAxis.tablePropertyName}`,
                ntile: zAxis.showPartitionsField ? zAxis.numberOfPartitions : null,
                joinPath:
                    zAxis.tableId !== primaryTableId &&
                    zAxis.tableGlobalId !== primaryTableId &&
                    zAxis.tableProperty.joinPath
                        ? {
                              path: `${JSON.stringify(zAxis.tableProperty.joinPath)}`,
                              ...(kpi_id ? { id: zAxis.joinPathId } : {}),
                          }
                        : null,
            });
            kpiDimensions.push({
                name: 'grouped_by',
                ...(kpi_id ? { id: groupedById } : {}),
                tableId: groupedByTable.id,
                groupedBy: true,
            });
        }

        // updating
        if (kpi_id) {
            const { data, error } = await updateKpi({
                variables: {
                    kpi: {
                        id: kpi_id,
                        name: kpiName.trim(),
                        primaryTableId,
                        graph: selectedVisualStyle.id,
                        displayAs:
                            selectedVisualStyle.id === visualTypes.SINGLE_VALUE
                                ? displayAs
                                : 'GRAPH',
                        kpiDimensions,
                    },
                },
            });
            setIsSaving(false);
            if (!error && data.updateKpi) {
                openModal('updateSuccess');
            } else {
                openModal('updateError');
            }
        } else {
            const { data, error } = await createKpi({
                variables: {
                    kpi: {
                        name: kpiName.trim(),
                        primaryTableId,
                        graph: selectedVisualStyle.id,
                        displayAs:
                            selectedVisualStyle.id === visualTypes.SINGLE_VALUE
                                ? displayAs
                                : 'GRAPH',
                        kpiDimensions,
                    },
                },
            });
            setIsSaving(false);
            if (!error && data.createKpi) {
                openModal('createSuccess');
                setTimeout(() => {
                    navigate(`/kpis/` + data.createKpi.kpi.id);
                }, 1000);
            } else {
                openModal('createError');
            }
        }
    };

    const getLivePreviewData = async () => {
        if (!validateDimensions()) return;
        setIsLoadingPreview(true);
        const columns = [];
        const xAlias = xAxis.title ? xAxis.title : `${xAxis.tableName} ${xAxis.tablePropertyName}`;
        let groupByXAxis = false;
        let groupByYAxis = false;
        if (
            selectedVisualStyle.id === visualTypes.BAR ||
            selectedVisualStyle.id === visualTypes.PIE
        ) {
            if (xAxis.tableId === yAxis.tableId && yAxis.aggregateFunction) {
                groupByXAxis = true;
            } else if (xAxis.tableId === yAxis.tableId && xAxis.aggregateFunction) {
                groupByYAxis = true;
            }
        }

        columns.push({
            tableId: xAxis.tableId,
            propertyId: xAxis.tablePropertyName === 'id' ? 'id' : xAxis.tablePropertyId,
            aggregateFunction: xAxis.aggregateFunction ? xAxis.aggregateFunction : null,
            groupedBy: groupByXAxis,
            alias: xAlias,
            ntile: xAxis.showPartitionsField ? xAxis.numberOfPartitions : null,
            distinct: selectedVisualStyle.id === visualTypes.SINGLE_VALUE,
            joinPath:
                xAxis.tableId !== primaryTableId &&
                xAxis.tableGlobalId !== primaryTableId &&
                xAxis.tableProperty.joinPath
                    ? `${JSON.stringify(xAxis.tableProperty.joinPath)}`
                    : null,
        });
        updateKpiTableAxis('x', { previewName: xAlias });

        if (
            selectedVisualStyle.id !== visualTypes.SINGLE_VALUE ||
            (selectedVisualStyle.id === visualTypes.SINGLE_VALUE && useDenominator)
        ) {
            const yAlias = yAxis.title
                ? yAxis.title
                : `${yAxis.tableName} ${yAxis.tablePropertyName}`;
            columns.push({
                tableId: yAxis.tableId,
                propertyId: yAxis.tablePropertyName === 'id' ? 'id' : yAxis.tablePropertyId,
                aggregateFunction: yAxis.aggregateFunction ? yAxis.aggregateFunction : null,
                groupedBy: groupByYAxis,
                alias: yAlias,
                ntile: yAxis.showPartitionsField ? yAxis.numberOfPartitions : null,
                distinct: selectedVisualStyle.id === visualTypes.SINGLE_VALUE,
                joinPath:
                    yAxis.tableId !== primaryTableId &&
                    yAxis.tableGlobalId !== primaryTableId &&
                    yAxis.tableProperty.joinPath
                        ? `${JSON.stringify(yAxis.tableProperty.joinPath)}`
                        : null,
            });
            updateKpiTableAxis('y', { previewName: yAlias });
        }

        if (selectedVisualStyle.id === visualTypes.GROUPED_BAR) {
            const groupedByAlias = groupedByDisplayName
                ? groupedByDisplayName
                : `${groupedByTable.name} ${groupedByProperty.propertyName}`;
            columns.push({
                tableId: groupedByTable.id,
                propertyId:
                    groupedByProperty.propertyName === 'id' ? 'id' : groupedByProperty.propertyId,
                groupedBy: true,
                aggregateFunction: null,
                alias: groupedByAlias,
                joinPath:
                    groupedByProperty.tableId !== primaryTableId &&
                    groupedByProperty.tableGlobalId !== primaryTableId &&
                    groupedByProperty.joinPath
                        ? `${JSON.stringify(groupedByProperty.joinPath)}`
                        : null,
            });
            setGroupedByPreviewName(groupedByAlias);
        }
        if (
            selectedVisualStyle.id === visualTypes.MATRIX ||
            selectedVisualStyle.id === visualTypes.BUBBLE
        ) {
            const zAlias = zAxis.title
                ? zAxis.title
                : `${zAxis.tableName} ${zAxis.tablePropertyName}`;
            columns.push(
                {
                    tableId: zAxis.tableId,
                    propertyId: zAxis.tablePropertyName === 'id' ? 'id' : zAxis.tablePropertyId,
                    aggregateFunction: zAxis.aggregateFunction ? zAxis.aggregateFunction : null,
                    alias: zAlias,
                    ntile: zAxis.showPartitionsField ? zAxis.numberOfPartitions : null,
                    joinPath:
                        zAxis.tableId !== primaryTableId &&
                        zAxis.tableGlobalId !== primaryTableId &&
                        zAxis.tableProperty.joinPath
                            ? `${JSON.stringify(zAxis.tableProperty.joinPath)}`
                            : null,
                },
                {
                    tableId: groupedByTable.id,
                    groupedBy: true,
                }
            );
            updateKpiTableAxis('z', { previewName: zAlias });
        }

        const { data, error } = await previewKpi({
            variables: {
                primaryTableId,
                columns,
                limit:
                    selectedVisualStyle.id === visualTypes.BAR ||
                    selectedVisualStyle.id === visualTypes.GROUPED_BAR
                        ? 50
                        : null,
                graph: selectedVisualStyle.id,
            },
        });
        setResults(data.kpiPreview.results);
        setIsLoadingPreview(false);
    };

    const setupChartPreview = async () => {
        setChartData({
            xAxis: {
                title: xAxis.title ? xAxis.title : `${xAxis.tableName} ${xAxis.tablePropertyName}`,
                alias: xAxis.previewName,
                ntile: xAxis.numberOfPartitions,
            },
            yAxis: {
                title: yAxis.title ? yAxis.title : `${yAxis.tableName} ${yAxis.tablePropertyName}`,
                alias: yAxis.previewName,
                ntile: yAxis.numberOfPartitions,
            },
            zAxis: {
                title: zAxis.title ? zAxis.title : `${zAxis.tableName} ${zAxis.tablePropertyName}`,
                alias: zAxis.previewName,
                ntile: zAxis.numberOfPartitions,
            },
            groupBy: {
                alias: groupedByPreviewName,
            },
            displayType: displayAs,
            useDenominator,
        });

        if (!firstSetupComplete) setFirstSetupComplete(true);
    };

    const loadGraphDimensions = (graphType, dimensions) => {
        // setting up X axis properties
        const xAxis = dimensions.find((dimension) => dimension.name === 'x_axis');
        if (xAxis) {
            const xAxisTable = joinTables.find((t) => t.id === xAxis.tableId);
            const xAxisProperty = xAxis.tablePropertyId
                ? xAxisTable.properties.find((p) => p.id === xAxis.tablePropertyId)
                : xAxisTable.properties[0];
            const xAxisModel = new KpiTableAxis(
                xAxisTable,
                xAxisProperty,
                tableTree.segmentationTableTree,
                xAxis.joinPath ? xAxis.joinPath : null,
                showAggregateFieldForTableProperty(xAxisProperty),
                xAxis.aggregateFunction ? xAxis.aggregateFunction : '',
                xAxis.alias,
                graphType === visualTypes.MATRIX || graphType === visualTypes.BUBBLE,
                xAxis.ntile ? xAxis.ntile : 4,
                xAxis.alias,
                null,
                xAxis.id
            );
            setXAxis(xAxisModel);
        }

        // setting up Y axis properties
        const yAxis = dimensions.find((dimension) => dimension.name === 'y_axis');
        if (yAxis) {
            const yAxisTable = joinTables.find((t) => t.id === yAxis.tableId);
            const yAxisProperty = yAxis.tablePropertyId
                ? yAxisTable.properties.find((p) => p.id === yAxis.tablePropertyId)
                : yAxisTable.properties[0];

            setYAxis(
                new KpiTableAxis(
                    yAxisTable,
                    yAxisProperty,
                    tableTree.segmentationTableTree,
                    yAxis.joinPath ? yAxis.joinPath : null,
                    showAggregateFieldForTableProperty(yAxisProperty),
                    yAxis.aggregateFunction ? yAxis.aggregateFunction : '',
                    yAxis.alias,
                    graphType === visualTypes.MATRIX || graphType === visualTypes.BUBBLE,
                    yAxis.ntile ? yAxis.ntile : 4,
                    yAxis.alias,
                    null,
                    yAxis.id
                )
            );

            if (graphType === visualTypes.SINGLE_VALUE) {
                setUseDenominator(true);
            }
        }

        if (graphType === visualTypes.MATRIX || graphType === visualTypes.BUBBLE) {
            // setting up Z axis properties
            const zAxis = dimensions.find((dimension) => dimension.name === 'z_axis');
            const zAxisTable = joinTables.find((t) => t.id === zAxis.tableId);
            const zAxisProperty = zAxis.tablePropertyId
                ? zAxisTable.properties.find((p) => p.id === zAxis.tablePropertyId)
                : zAxisTable.properties[0];

            setZAxis(
                new KpiTableAxis(
                    zAxisTable,
                    zAxisProperty,
                    tableTree.segmentationTableTree,
                    zAxis.joinPath ? zAxis.joinPath : null,
                    showAggregateFieldForTableProperty(zAxisProperty),
                    zAxis.aggregateFunction ? zAxis.aggregateFunction : '',
                    zAxis.alias,
                    graphType === visualTypes.MATRIX || graphType === visualTypes.BUBBLE,
                    zAxis.ntile ? zAxis.ntile : 4,
                    zAxis.alias,
                    null,
                    zAxis.id
                )
            );

            // setting up grouped by table
            const groupedBy = dimensions.find((dimension) => dimension.name === 'grouped_by');
            const groupedByTable = joinTables.find((t) => t.id === groupedBy.tableId);
            setGroupedById(groupedBy.id);
            setGroupedByTable(groupedByTable);
        }

        if (graphType === visualTypes.GROUPED_BAR) {
            // setting up grouped by properties
            const groupedBy = dimensions.find((dimension) => dimension.name === 'grouped_by');
            const groupedByTable = joinTables.find((t) => t.id === groupedBy.tableId);
            const groupedByProperty = groupedBy.tablePropertyId
                ? groupedByTable.properties.find((p) => p.id === groupedBy.tablePropertyId)
                : groupedByTable.properties[0];
            setGroupedById(groupedBy.id);
            setGroupedByTable(groupedByTable);
            setGroupedByProperty(
                new TableProperty(
                    groupedByProperty,
                    tableTree.segmentationTableTree,
                    groupedBy.joinPath ? groupedBy.joinPath.path : null,
                    groupedBy.joinPath ? groupedBy.joinPath.id : null
                )
            );
            setGroupedByDisplayName(groupedBy.alias);
        }
    };

    // axis: x, y or z
    const updateKpiTableAxis = (
        axis,
        {
            table = null,
            tableProperty = null,
            primaryTableTree = null,
            joinPath,
            showAggregateField = null,
            aggregateFunction = null,
            displayName = null,
            showPartitionsField = null,
            numberOfPartitions = null,
            title = null,
            previewName = null,
        } = {}
    ) => {
        if (axis === 'x') {
            setXAxis((prev) => {
                if (!prev) return prev;
                return new KpiTableAxis(
                    table || prev.table,
                    tableProperty || prev.tableProperty.tableProperty,
                    primaryTableTree || prev.primaryTableTree,
                    joinPath !== undefined
                        ? joinPath
                        : { path: prev.joinPath, id: prev.joinPathId },
                    showAggregateField != null ? showAggregateField : prev.showAggregateField,
                    aggregateFunction != null ? aggregateFunction : prev.aggregateFunction,
                    displayName != null ? displayName : prev.displayName,
                    showPartitionsField != null ? showPartitionsField : prev.showPartitionsField,
                    numberOfPartitions != null ? numberOfPartitions : prev.numberOfPartitions,
                    title || prev.title,
                    previewName || prev.previewName,
                    prev.id
                );
            });
        } else if (axis === 'y') {
            setYAxis((prev) => {
                if (!prev) return prev;
                return new KpiTableAxis(
                    table || prev.table,
                    tableProperty || prev.tableProperty.tableProperty,
                    primaryTableTree || prev.primaryTableTree,
                    joinPath !== undefined
                        ? joinPath
                        : { path: prev.joinPath, id: prev.joinPathId },
                    showAggregateField != null ? showAggregateField : prev.showAggregateField,
                    aggregateFunction != null ? aggregateFunction : prev.aggregateFunction,
                    displayName != null ? displayName : prev.displayName,
                    showPartitionsField != null ? showPartitionsField : prev.showPartitionsField,
                    numberOfPartitions != null ? numberOfPartitions : prev.numberOfPartitions,
                    title || prev.title,
                    previewName || prev.previewName,
                    prev.id
                );
            });
        } else if (axis === 'z') {
            setZAxis((prev) => {
                if (!prev) return prev;
                return new KpiTableAxis(
                    table || prev.table,
                    tableProperty || prev.tableProperty.tableProperty,
                    primaryTableTree || prev.primaryTableTree,
                    joinPath !== undefined
                        ? joinPath
                        : { path: prev.joinPath, id: prev.joinPathId },
                    showAggregateField != null ? showAggregateField : prev.showAggregateField,
                    aggregateFunction != null ? aggregateFunction : prev.aggregateFunction,
                    displayName != null ? displayName : prev.displayName,
                    showPartitionsField != null ? showPartitionsField : prev.showPartitionsField,
                    numberOfPartitions != null ? numberOfPartitions : prev.numberOfPartitions,
                    title || prev.title,
                    previewName || prev.previewName,
                    prev.id
                );
            });
        }
    };

    const getTableProperties = (properties) => {
        let _properties = properties;
        // display only numeric types
        if (selectedVisualStyle.id === visualTypes.SINGLE_VALUE) {
            _properties = _properties.filter(
                (tp) => tp.type.type === 'Number' || tp.property === 'id'
            );
        }
        return _properties.map((property) => ({
            value: property.id,
            text: property.property,
        }));
    };

    const getAxisDimensionComponent = (axis = 'x', showDisplayName = true) => {
        let kpiTableAxis = xAxis;
        if (axis === 'y') {
            kpiTableAxis = yAxis;
        } else if (axis === 'z') {
            kpiTableAxis = zAxis;
        }

        return (
            <>
                <div className="input-label">
                    Select Table
                    {kpiTableAxis.tableId !== primaryTableId &&
                        kpiTableAxis.tableGlobalId !== primaryTableId &&
                        kpiTableAxis.numberOfJoinPaths > 1 && (
                            // displaying btn if there is more than one join path
                            <IconButton
                                padding="5px"
                                tooltip="Select Join Path"
                                className="select-path-icon-btn"
                                onClick={() => {
                                    setJoinPathTableProperty(kpiTableAxis.tableProperty);
                                    setIsTableJoinPathModalOpen(true);
                                }}
                            >
                                <RouteIcon height={20} width={20} fill="#fff" />
                            </IconButton>
                        )}
                </div>
                <Dropdown
                    value={kpiTableAxis.tableId}
                    values={joinTables.map((table) => ({
                        value: table.id,
                        text: table.name,
                    }))}
                    setValue={(e) => {
                        const table = joinTables.find((t) => t.id === e.target.value);
                        if (
                            axis === 'x' &&
                            selectedVisualStyle.id !== visualTypes.MATRIX &&
                            selectedVisualStyle.id !== visualTypes.BUBBLE
                        ) {
                            setPrimaryTableId(e.target.value);
                        }
                        updateKpiTableAxis(axis, {
                            table: table,
                            tableProperty: table.properties[0],
                            showAggregateField: true,
                            aggregateFunction:
                                selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? 'count' : '',
                            joinPath: null,
                        });
                    }}
                />
                <div className="input-label">Select Table Property</div>
                <Dropdown
                    value={kpiTableAxis.tablePropertyId}
                    values={getTableProperties(kpiTableAxis.table.properties)}
                    setValue={(e) => {
                        const selectedTableProperty = kpiTableAxis.table.properties.find(
                            (p) => p.id === e.target.value
                        );
                        updateKpiTableAxis(axis, {
                            tableProperty: selectedTableProperty,
                            showAggregateField:
                                showAggregateFieldForTableProperty(selectedTableProperty),
                            aggregateFunction:
                                selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? 'count' : '',
                        });
                    }}
                />
                {kpiTableAxis.showAggregateField && (
                    <>
                        <div className="input-label">Select Aggregate Function</div>
                        <Dropdown
                            value={kpiTableAxis.aggregateFunction}
                            values={getAggregateFunctions(kpiTableAxis)}
                            setValue={(e) => {
                                updateKpiTableAxis(axis, {
                                    aggregateFunction: e.target.value,
                                });
                            }}
                        />
                    </>
                )}
                {kpiTableAxis.showPartitionsField && (
                    <>
                        <div className="input-label">Number of Partitions (ntile)</div>
                        <Input
                            value={kpiTableAxis.numberOfPartitions}
                            setValue={(e) => {
                                updateKpiTableAxis(axis, {
                                    numberOfPartitions: e.target.value,
                                });
                            }}
                            type="number"
                            placeholder="Number of Partitions"
                            min={2}
                        />
                    </>
                )}
                {showDisplayName && (
                    <>
                        <div className="input-label">Display Name</div>
                        <Input
                            value={kpiTableAxis.displayName}
                            setValue={(e) => {
                                updateKpiTableAxis(axis, {
                                    displayName: e.target.value,
                                });
                            }}
                            type="text"
                            placeholder="Display Name"
                        />
                    </>
                )}
            </>
        );
    };

    const getGroupedByDimensionsComponent = (displayTableOnly = false) => {
        return (
            <>
                <div className="input-label">
                    Select Table
                    {groupedByTable.id !== primaryTableId &&
                        groupedByTable.global_id !== primaryTableId &&
                        groupedByProperty.numberOfJoinPaths > 1 && (
                            <IconButton
                                padding="5px"
                                tooltip="Select Path"
                                className="select-path-icon-btn"
                                onClick={() => {
                                    setJoinPathTableProperty(groupedByProperty);
                                    setIsTableJoinPathModalOpen(true);
                                }}
                            >
                                <RouteIcon height={20} width={20} fill="#fff" />
                            </IconButton>
                        )}
                </div>
                <Dropdown
                    value={groupedByTable.id}
                    values={joinTables.map((table) => ({
                        value: table.id,
                        text: table.name,
                    }))}
                    setValue={(e) => {
                        if (
                            selectedVisualStyle.id === visualTypes.MATRIX ||
                            selectedVisualStyle.id === visualTypes.BUBBLE
                        ) {
                            setPrimaryTableId(e.target.value);
                        }
                        setGroupedByTable(joinTables.find((t) => t.id === e.target.value));
                    }}
                />
                {!displayTableOnly && (
                    <>
                        <div className="input-label">Select Table Property</div>
                        <Dropdown
                            value={groupedByProperty.propertyId}
                            values={getTableProperties(groupedByTable.properties)}
                            setValue={(e) =>
                                setGroupedByProperty((prev) => {
                                    return new TableProperty(
                                        groupedByTable.properties.find(
                                            (p) => p.id === e.target.value
                                        ),
                                        prev.primaryTableTree,
                                        prev.joinPath,
                                        prev.joinPathId
                                    );
                                })
                            }
                        />
                        <div className="input-label">Display Name</div>
                        <Input
                            value={groupedByDisplayName}
                            setValue={(e) => setGroupedByDisplayName(e.target.value)}
                            type="text"
                            placeholder="Display Name"
                            width="100%"
                        />
                    </>
                )}
            </>
        );
    };

    const getSingleValueTypeDimensionsComponent = () => {
        return (
            <>
                <div className="input-label">Display As</div>
                <Dropdown
                    value={displayAs}
                    values={[
                        {
                            value: singleValueDisplayTypes.INT,
                            text: 'Integer',
                        },
                        {
                            value: singleValueDisplayTypes.DOLLAR,
                            text: 'Dollar Amount',
                        },
                        {
                            value: singleValueDisplayTypes.FRACTION,
                            text: 'Fraction',
                        },
                        {
                            value: singleValueDisplayTypes.PERCENT,
                            text: 'Percentage',
                        },
                    ]}
                    setValue={(e) => setDisplayAs(e.target.value)}
                />
            </>
        );
    };

    const getSingleValueDenominatorDimensionsComponent = () => {
        return (
            <>
                <div className="use-denominator">
                    Use Denominator{' '}
                    <Input
                        type="checkbox"
                        width="45px"
                        minWidth="45px"
                        value={useDenominator}
                        setValue={(e) => {
                            setUseDenominator(e.target.checked);
                        }}
                    />
                </div>
                {useDenominator && getAxisDimensionComponent('y', false)}
            </>
        );
    };

    const setupGraphDimensions = () => {
        let _graphDimensions = [];
        switch (selectedVisualStyle.id) {
            case visualTypes.BAR:
            case visualTypes.PIE:
                _graphDimensions = [
                    {
                        isExpanded: true,
                        header: 'X Axis',
                        body: getAxisDimensionComponent('x'),
                    },
                    {
                        isExpanded: true,
                        header: 'Y Axis',
                        body: getAxisDimensionComponent('y'),
                    },
                ];
                break;
            case visualTypes.GROUPED_BAR:
                _graphDimensions = [
                    {
                        isExpanded: true,
                        header: 'X Axis',
                        body: getAxisDimensionComponent('x'),
                    },
                    {
                        isExpanded: true,
                        header: 'X Axis - Grouped By',
                        body: getGroupedByDimensionsComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Y Axis',
                        body: getAxisDimensionComponent('y'),
                    },
                ];
                break;
            case visualTypes.MATRIX:
                _graphDimensions = [
                    {
                        isExpanded: true,
                        header: 'Grouped By',
                        body: getGroupedByDimensionsComponent(true),
                    },
                    {
                        isExpanded: true,
                        header: 'X Axis',
                        body: getAxisDimensionComponent('x'),
                    },
                    {
                        isExpanded: true,
                        header: 'Y Axis',
                        body: getAxisDimensionComponent('y'),
                    },
                    {
                        isExpanded: true,
                        header: 'Z Axis',
                        body: getAxisDimensionComponent('z'),
                    },
                ];
                break;
            case visualTypes.BUBBLE:
                _graphDimensions = [
                    {
                        isExpanded: true,
                        header: 'Grouped By',
                        body: getGroupedByDimensionsComponent(true),
                    },
                    {
                        isExpanded: true,
                        header: 'X Axis',
                        body: getAxisDimensionComponent('x'),
                    },
                    {
                        isExpanded: true,
                        header: 'Y Axis',
                        body: getAxisDimensionComponent('y'),
                    },
                    {
                        isExpanded: true,
                        header: 'Radius',
                        body: getAxisDimensionComponent('z'),
                    },
                ];
                break;
            case visualTypes.SINGLE_VALUE:
                _graphDimensions = [
                    {
                        isExpanded: true,
                        header: 'Display Type',
                        body: getSingleValueTypeDimensionsComponent(),
                    },
                    {
                        isExpanded: true,
                        header: 'Numerator Value',
                        body: getAxisDimensionComponent('x', false),
                    },
                    {
                        isExpanded: true,
                        header: 'Denominator Value',
                        body: getSingleValueDenominatorDimensionsComponent(),
                    },
                ];
        }
        setGraphDimensions(_graphDimensions);
    };

    const getAggregateFunctions = (kpiTableAxis) => {
        if (kpiTableAxis.tableProperty.propertyName === 'id') {
            if (selectedVisualStyle.id === visualTypes.SINGLE_VALUE) {
                return [{ text: 'COUNT', value: 'count' }];
            }
            return [
                { text: 'None', value: '' },
                { text: 'COUNT', value: 'count' },
            ];
        }
        const dataType = kpiTableAxis.tablePropertyType;
        let filterFunctions = FilterFunctions;
        if (selectedVisualStyle.id === visualTypes.SINGLE_VALUE) {
            filterFunctions.filter((filterFunc) => filterFunc.text !== 'None');
        }
        if (dataType === 'Number') {
            return filterFunctions;
        } else if (dataType === 'Date') {
            return filterFunctions.filter(
                (filterFunc) => filterFunc.text !== 'SUM' && filterFunc.text !== 'AVG'
            );
        }
        return [];
    };

    const showAggregateFieldForTableProperty = (tableProperty) => {
        return (
            tableProperty.property === 'id' ||
            tableProperty.type.type === 'Number' ||
            tableProperty.type.type === 'Date'
        );
    };

    const updatePreviewWidthAndPosition = () => {
        if (!previewRef.current) return;
        setChartPreviewWidth(previewRef.current.getBoundingClientRect().width);
        setChartPreviewLeftPos(previewRef.current.getBoundingClientRect().left);
    };

    useEffect(() => {
        if (!xAxis) return;
        const timeOutId = setTimeout(
            () => updateKpiTableAxis('x', { title: xAxis.displayName }),
            500
        );
        return () => clearTimeout(timeOutId);
    }, [xAxis?.displayName]);

    useEffect(() => {
        if (!groupedByTable || (kpi_id && !finishedLoadingKpi)) return;
        setGroupedByProperty((prev) => {
            return new TableProperty(groupedByTable.properties[0], prev.primaryTableTree, null);
        });
    }, [groupedByTable]);

    useEffect(() => {
        if (!yAxis) return;
        const timeOutId = setTimeout(
            () => updateKpiTableAxis('y', { title: yAxis.displayName }),
            500
        );
        return () => clearTimeout(timeOutId);
    }, [yAxis?.displayName]);

    useEffect(() => {
        if (!zAxis) return;
        const timeOutId = setTimeout(
            () => updateKpiTableAxis('z', { title: zAxis.displayName }),
            500
        );
        return () => clearTimeout(timeOutId);
    }, [zAxis?.displayName]);

    useEffect(() => {
        if (!joinTables || joinTables.length === 0 || !primaryTableTree || !selectedVisualStyle)
            return;
        const showPartitionFields =
            selectedVisualStyle.id === visualTypes.MATRIX ||
            selectedVisualStyle.id === visualTypes.BUBBLE;
        if (!xAxis) {
            setXAxis(
                new KpiTableAxis(
                    joinTables[0],
                    joinTables[0].properties[0],
                    primaryTableTree,
                    null,
                    true,
                    selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? 'count' : '',
                    '',
                    showPartitionFields
                )
            );
        } else {
            updateKpiTableAxis('x', {
                table: joinTables[0],
                tableProperty: joinTables[0].properties[0],
                showAggregateField: true,
                aggregateFunction:
                    selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? 'count' : '',
                primaryTableTree: tableTree.segmentationTableTree,
                joinPath: null,
            });
        }
        if (!yAxis) {
            setYAxis(
                new KpiTableAxis(
                    joinTables[0],
                    joinTables[0].properties[0],
                    primaryTableTree,
                    null,
                    true,
                    selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? 'count' : '',
                    '',
                    showPartitionFields
                )
            );
        } else {
            updateKpiTableAxis('y', {
                table: joinTables[0],
                tableProperty: joinTables[0].properties[0],
                showAggregateField: true,
                aggregateFunction:
                    selectedVisualStyle.id === visualTypes.SINGLE_VALUE ? 'count' : '',
                primaryTableTree: primaryTableTree,
                joinPath: null,
            });
        }
        if (!zAxis) {
            setZAxis(
                new KpiTableAxis(
                    joinTables[0],
                    joinTables[0].properties[0],
                    primaryTableTree,
                    null,
                    true,
                    '',
                    '',
                    showPartitionFields
                )
            );
        } else {
            updateKpiTableAxis('z', {
                table: joinTables[0],
                tableProperty: joinTables[0].properties[0],
                showAggregateField: true,
                aggregateFunction: '',
                primaryTableTree: primaryTableTree,
                joinPath: null,
            });
        }
        setGroupedByTable(joinTables[0]);
        setGroupedByProperty(new TableProperty(joinTables[0].properties[0], primaryTableTree));
    }, [primaryTableTree, joinTables, selectedVisualStyle]);

    useEffect(() => {
        if (
            !joinTables ||
            joinTables.length === 0 ||
            !primaryTableTree ||
            !kpi_id ||
            finishedLoadingDimensions ||
            !kpiData ||
            !kpiData.kpi
        )
            return;
        const dimensions = kpiData.kpi.kpiDimensions.edges.map((edge) => edge.node);
        loadGraphDimensions(kpiData.kpi.graph, dimensions);
        if (kpiData.kpi.graph === visualTypes.SINGLE_VALUE) {
            setDisplayAs(kpiData.kpi.displayAs);
        }
        setFinishedLoadingDimensions(true);
        setLoading(false);
    }, [joinTables, primaryTableTree]);

    useEffect(() => {
        if (!tableTree) return;
        setPrimaryTableTree(tableTree.segmentationTableTree);
        const tables = getTableArrayFromTableTree(tableTree.segmentationTableTree);
        setJoinTables(
            tables.map((table) => {
                const joinTable = { ...table };
                joinTable.properties = [
                    {
                        id: uuidv4(),
                        property: 'id',
                        table: { ...table },
                        type: { type: 'UUID' },
                    },
                    ...joinTable.properties,
                ];
                return joinTable;
            })
        );
    }, [tableTree]);

    useEffect(() => {
        const timeOutId = setTimeout(() => setChartTitle(kpiName), 500);
        return () => clearTimeout(timeOutId);
    }, [kpiName]);

    useEffect(() => {
        if (!selectedVisualStyle) return;
        const showPartitionFields =
            selectedVisualStyle.id === visualTypes.MATRIX ||
            selectedVisualStyle.id === visualTypes.BUBBLE;
        const isSingleValue = selectedVisualStyle.id === visualTypes.SINGLE_VALUE;
        updateKpiTableAxis('x', {
            showPartitionsField: showPartitionFields,
            ...(isSingleValue
                ? {
                      tableProperty: xAxis?.table.properties[0],
                      showAggregateField: true,
                      aggregateFunction: 'count',
                  }
                : {}),
        });
        updateKpiTableAxis('y', {
            showPartitionsField: showPartitionFields,
            ...(isSingleValue
                ? {
                      tableProperty: yAxis?.table.properties[0],
                      showAggregateField: true,
                      aggregateFunction: 'count',
                  }
                : {}),
        });
        updateKpiTableAxis('z', { showPartitionsField: showPartitionFields });
        setResults(null);
    }, [selectedVisualStyle]);

    useEffect(() => {
        if (!selectedVisualStyle || !xAxis) return;
        setupGraphDimensions();
    }, [
        selectedVisualStyle,
        primaryTableId,
        joinTables,
        xAxis,
        yAxis,
        zAxis,
        groupedByTable,
        groupedByProperty,
        groupedByDisplayName,
        displayAs,
        useDenominator,
    ]);

    useEffect(() => {
        if (!selectedVisualStyle || !xAxis || !yAxis) return;
        setupChartPreview();
    }, [
        selectedVisualStyle,
        xAxis?.title,
        yAxis?.title,
        zAxis?.title,
        xAxis?.table,
        yAxis?.table,
        zAxis?.table,
        xAxis?.numberOfPartitions,
        yAxis?.numberOfPartitions,
        zAxis?.numberOfPartitions,
        results,
        chartTitle,
        displayAs,
    ]);

    useEffect(() => {
        if (!finishedLoadingDimensions) return;
        const loadPreviewData = async () => {
            await getLivePreviewData();
            setFinishedLoadingKpi(true);
        };
        loadPreviewData();
    }, [finishedLoadingDimensions]);

    // loading existing kpi
    useEffect(() => {
        if (!kpiData?.kpi || !tables || tables.length === 0) return;
        const kpi = kpiData.kpi;
        const style = visualizationStyles.find((style) => style.id === kpi.graph);
        setSelectedVisualStyle(style);
        setPrimaryTableId(kpi.primaryTableId);
        setKpiName(kpi.name);
        setChartTitle(kpi.name);
    }, [kpiData, tables]);

    useEffect(() => {
        if (!onSecondStep || !previewRef.current) return;
        window.addEventListener('scroll', handleScrollAndResize, { passive: true });
        // clean up the scroll event listener
        return () => {
            window.removeEventListener('scroll', handleScrollAndResize);
        };
    }, [onSecondStep, previewRef.current]);

    useEffect(() => {
        if (!onSecondStep || !previewRef.current) return;
        setIsNavMenuResizing(true);
        const timeOutId = setTimeout(() => {
            updatePreviewWidthAndPosition();
            setIsNavMenuResizing(false);
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [navCollapsed]);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.resize();
        }
    }, [chartPreviewWidth]);

    useEffect(() => {
        if (kpi_id) {
            setOnSecondStep(true);
        } else {
            setLoading(false);
        }
    }, [kpi_id]);

    return (
        <div className="kpi-builder" ref={kpiBuilderRef}>
            <ContentPanel
                title={
                    <div className="kpi-builder-header">
                        <div className="header-col">
                            KPI Builder
                            <div className="sub-header">
                                {!onSecondStep
                                    ? 'Choose a Visualization Style'
                                    : 'Customize Visualization'}
                            </div>
                        </div>
                    </div>
                }
            >
                <div className="kpi-builder-body">
                    {error && (
                        <Alert variant="error" width="100%">
                            {error}
                        </Alert>
                    )}
                    {loading ? (
                        <Loader />
                    ) : !onSecondStep ? (
                        <div className="visualization-style">
                            <div className="chart-cards">
                                {visualizationStyles.map((style, i) => (
                                    <div
                                        key={i}
                                        onClick={() => setSelectedVisualStyle(style)}
                                        className={`chart-card ${
                                            selectedVisualStyle?.name === style.name
                                                ? 'selected'
                                                : ''
                                        }`}
                                    >
                                        <style.icon className="icon" />
                                        {style.name}
                                    </div>
                                ))}
                            </div>
                            <Button className="next-btn" onClick={nextStep}>
                                Next
                            </Button>
                        </div>
                    ) : (
                        <>
                            <div className="kpi-customization">
                                {graphDimensions && graphDimensions.length > 0 && (
                                    <div className="dimensions-sidemenu">
                                        <SideMenu header="Dimensions" width="100%">
                                            <Accordion
                                                padding="10px 20px"
                                                items={graphDimensions}
                                            />
                                        </SideMenu>
                                    </div>
                                )}
                                <div className="chart-preview-container" ref={previewRef}>
                                    {isNavMenuResizing ||
                                    loadingTables ||
                                    loadingTableTree ||
                                    (kpi_id && !finishedLoadingKpi) ? (
                                        <div className="chart-preview-loader">
                                            <Loader />
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                width: chartPreviewWidth,
                                                top: chartPreviewTop,
                                                left: chartPreviewLeftPos,
                                            }}
                                            className="chart-preview"
                                        >
                                            <div className="chart-type-row">
                                                <div className="kpi-name-input">
                                                    <EditableHighlightField
                                                        defaultDisplayName="Kpi Name"
                                                        placeholder="Kpi Name"
                                                        value={kpiName}
                                                        setValue={(value) => setKpiName(value)}
                                                    />
                                                    <InputError error={kpiNameError} />
                                                </div>
                                                <div className="chart-type-btn">
                                                    <Button
                                                        width="150px"
                                                        variant="secondary"
                                                        onClick={() => setChartTypeMenuOpen(true)}
                                                    >
                                                        Chart Type
                                                        <selectedVisualStyle.icon className="chart-type-icon" />
                                                    </Button>
                                                    <CardList
                                                        items={visualizationStyles}
                                                        onClickOutside={() =>
                                                            setChartTypeMenuOpen(false)
                                                        }
                                                        onClickItemCallback={(item) => {
                                                            if (
                                                                item.id !== selectedVisualStyle.id
                                                            ) {
                                                                setSelectedVisualStyle(item);
                                                            }
                                                            setChartTypeMenuOpen(false);
                                                        }}
                                                        getCardItemContent={(item) => item.name}
                                                        show={chartTypeMenuOpen}
                                                        width="100%"
                                                        top="50px"
                                                        left="0"
                                                        right="0"
                                                        showDivider="all"
                                                    />
                                                </div>
                                            </div>
                                            {dimensionErrors.length > 0 && (
                                                <div className="dimension-errors">
                                                    {dimensionErrors.map((error, i) => (
                                                        <Alert variant="error" key={i} width="100%">
                                                            {error}
                                                        </Alert>
                                                    ))}
                                                </div>
                                            )}
                                            <Visualization
                                                title={chartTitle}
                                                chartData={chartData}
                                                visualStyle={selectedVisualStyle.id}
                                                chartRef={chartRef}
                                                results={results}
                                            />
                                            <div className="action-btns">
                                                <Button
                                                    className="preview-btn"
                                                    variant="secondary"
                                                    width="150px"
                                                    onClick={getLivePreviewData}
                                                    isLoading={isLoadingPreview}
                                                >
                                                    Preview
                                                </Button>
                                                <Button
                                                    width="150px"
                                                    onClick={save}
                                                    isLoading={isSaving}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </ContentPanel>
            <Modal options={getModalOptions()} isOpen={modalIsOpen} setOpen={setModalIsOpen} />
            <SelectTableJoinPathModal
                isOpen={isTableJoinPathModalOpen}
                setOpen={setIsTableJoinPathModalOpen}
                tableProperty={joinPathTableProperty}
            />
        </div>
    );
};

export default KpiBuilder;

import { v4 as uuidv4 } from 'uuid';
import Filter from './filter';

class FilterGroup {
    static minimumHeight = 150;
    constructor(
        isRoot = false,
        operator = 'AND',
        filters = [],
        isNew = true,
        isPlaceholder = false,
        isCombining = false,
        isValid = true,
        id = uuidv4()
    ) {
        this.id = id;
        this.operator = operator;
        this.isRoot = isRoot;
        this.isNew = isNew;
        this.filters = filters; // holds a list of Filter and FilterGroup objects
        this.isValid = isValid;
        this.isPlaceholder = isPlaceholder;
        this.isCombining = isCombining;
        this.isBusyWithNewFilter = false;
    }

    get displayOperator() {
        const filterGroups = this.filters.filter((f) => f instanceof FilterGroup);
        if (filterGroups.length > 0) {
            return true;
        } else if (this.isRoot && this.filterCount < 1) {
            return false;
        }
        return true;
    }

    get isFilter() {
        return false;
    }

    get maxDepth() {
        let maxDepth = 1;
        const filterGroups = this.filters.filter((f) => f instanceof FilterGroup);
        for (const fg of filterGroups) {
            const depth = fg.maxDepth + 1;
            if (depth > maxDepth) {
                maxDepth = depth;
            }
        }
        return maxDepth;
    }

    get filterCount() {
        if (!this.filters) {
            return 0;
        }
        return this.filters.length;
    }

    get filterGroupHeight() {
        if (this.filterCount === 0 || (this.filterCount === 1 && this.filters[0].isFilter)) {
            return FilterGroup.minimumHeight;
        }
        let height = 0;
        for (let filter of this.filters) {
            if (filter.isFilter) {
                height += Filter.height;
            } else {
                height += filter.filterGroupHeight;
            }
        }
        height += (this.filters.length - 1) * Filter.bottomMargin;
        return height;
    }

    get hasBusyChild() {
        for (const filter of this.filters) {
            if (!filter.isFilter) {
                // only interested in FilterGroup objects
                if (filter.isBusyWithNewFilter) {
                    return true;
                } else if (filter.hasBusyChild) {
                    return true;
                }
            }
        }
        return false;
    }

    get childFilterTableIds() {
        let tables = [];
        for (const filter of this.filters) {
            if (filter.isFilter && !tables.find((t) => t.id === filter.tableProperty.tableId)) {
                tables.push(filter.tableProperty.tableId);
            } else {
                tables = tables.concat(filter.childFilterTableIds);
            }
        }
        return tables;
    }

    addChildFilter(filter, index = null) {
        if (index !== null) {
            this.filters.splice(index, 0, filter);
        } else {
            this.filters.push(filter);
        }
    }

    deleteFilter(index) {
        return this.filters.splice(index, 1);
    }

    reset() {
        this.operator = 'AND';
        this.filters = [];
    }

    validate() {
        this.isValid = true;
        // root filter group cannot just have 1 child filter group
        if (this.isRoot && this.filters.length === 1 && !this.filters[0].isFilter) {
            this.isValid = false;
        } else if (!this.isRoot && (!this.filters || this.filters.length < 2)) {
            this.isValid = false;
        }
        let valid = true;
        for (const filter of this.filters) {
            valid = filter.validate() && valid;
        }
        return this.isValid && valid;
    }
}

export default FilterGroup;

import { useEffect, useRef, useState } from 'react';

const useScreenResize = (callbackFunction) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!ref.current) return;

        const resizeObserver = new ResizeObserver(callbackFunction);
        resizeObserver.observe(ref.current);

        // clean up the resize observer
        return () => {
            resizeObserver.disconnect();
        };
    }, [ref]);

    return ref;
};

export default useScreenResize;

export const accentColorRgb = { r: 0, g: 117, b: 235 };

export const convertToRgbaString = (color, alpha = 1) => {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

export const generateSimilarColors = (numberOfColors = 1, baseColor = accentColorRgb) => {
    const colors = [];
    let step = 20;
    if (numberOfColors > 10 && numberOfColors <= 25) {
        step = 10;
    } else if (numberOfColors > 25 && numberOfColors <= 100) {
        step = 5;
    } else if (numberOfColors > 100 && numberOfColors <= 200) {
        step = 2;
    } else if (numberOfColors > 200) {
        step = 1;
    }

    for (let i = 0; i < numberOfColors; i++) {
        const newColor = {
            r: Math.max(0, Math.min(255, baseColor.r + step * i)),
            g: Math.max(0, Math.min(255, baseColor.g + step * i)),
            b: Math.max(0, Math.min(255, baseColor.b + step * i)),
        };
        colors.push(convertToRgbaString(newColor));
    }

    return colors;
};

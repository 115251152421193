import './EditableHighlightField.css';

// Libraries
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// Assets
import { ReactComponent as EditIcon } from 'assets/icons/edit_icon.svg';

const EditableHighlightField = ({
    defaultDisplayName,
    placeholder = '',
    value,
    setValue,
    width = 'auto',
    defaultIsEditing = false,
}) => {
    const [isEditing, setIsEditing] = useState(defaultIsEditing);

    const ref = useRef(null);

    const handleClick = (event) => {
        // handle click outside component
        if (ref.current && !ref.current.contains(event.target)) {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [isEditing]);

    return (
        <div
            ref={ref}
            className="editable-highlight-field"
            style={{ width }}
            onClick={() => {
                setIsEditing(true);
            }}
        >
            <div className="editable-field-wrapper">
                <div className="editable-field">
                    {isEditing ? (
                        <input
                            autoFocus
                            placeholder={placeholder}
                            value={value}
                            onChange={(e) => {
                                setValue(e.target.value);
                            }}
                        />
                    ) : (
                        <>
                            <p>{value || defaultDisplayName}</p>
                        </>
                    )}
                </div>
                <div className="highlight-wrapper">
                    <div className="highlight"></div>
                </div>
            </div>
            {!isEditing && (
                <div className="edit-icon">
                    <EditIcon fill="black" width={24} />
                </div>
            )}
        </div>
    );
};

EditableHighlightField.propTypes = {
    defaultDisplayName: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
    defaultIsEditing: PropTypes.bool,
    width: PropTypes.string,
};

export default EditableHighlightField;

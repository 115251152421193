import { useEffect, useRef } from 'react';
import './CardList.css';
import PropTypes from 'prop-types';

const CardList = ({
    items,
    show,
    className = '',
    showDivider = 'none',
    getCardItemContent = (item) => {
        return item;
    },
    onClickOutside = null,
    onClickItemCallback = (item) => {},
    width = '100%',
    top = 'auto',
    left = 'auto',
    right = 'auto',
    bottom = 'auto',
}) => {
    const cssProperties = {
        width,
        top,
        left,
        right,
        bottom,
    };

    const ref = useRef(null);

    const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            onClickOutside && onClickOutside();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [show]);

    if (!show) return null;
    return (
        <div ref={ref} className={`card-list ${className}`} style={cssProperties}>
            <ul>
                {items.map((item, i) => (
                    <li
                        className={
                            (i > 0 && showDivider === 'all') ||
                            (i === items.length - 1 && showDivider === 'last')
                                ? 'show-divider'
                                : undefined
                        }
                        key={i}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            if (item.clickCallBack) {
                                item.clickCallBack();
                            } else {
                                onClickItemCallback(item);
                            }
                        }}
                    >
                        {getCardItemContent(item)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

CardList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
    show: PropTypes.bool.isRequired,
    className: PropTypes.string,
    showDivider: PropTypes.oneOf(['none', 'all', 'last']),
    getCardItemContent: PropTypes.func,
    onClickOutside: PropTypes.func,
    onClickItemCallback: PropTypes.func,
    width: PropTypes.string,
    top: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    bottom: PropTypes.string,
};

export default CardList;

import './Unify.css';

// Libraries
import React, { useCallback, useContext, useEffect, useState } from 'react';

// Assets
import unifyImage from 'assets/images/okzou_unify_illustration.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add_icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_icon.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/exclamation-triangle.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit_icon.svg';

// Utils & hooks
import { ucWords } from 'utils/text.util';
import User from 'utils/user.util';
import { useNavigate } from 'react-router-dom';
import { canCreate, canDelete } from 'utils/permission.util';

// Components
import Button from 'components/Buttons/Button/Button';
import ContentPanel from 'components/ContentPanel/ContentPanel';
import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader/Loader';
import IconButton from 'components/Buttons/IconButton/IconButton';
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';
import DataTable from 'components/DataTable/DataTable';
import Search from 'components/Search/Search';

function Unify() {
    const { permissionSet } = useContext(CurrentPermissionContext);
    const navigate = useNavigate();
    const [modalType, setModalType] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [routines, setRoutines] = useState();
    const [columns, setColumns] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [searchFilter, setSearchFilter] = useState();
    const [filteredRoutines, setFilteredRoutines] = useState([]);
    const [routineToDelete, setRoutineToDelete] = useState();

    const getTenantConnection = async () => {
        return await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/unify/tenant/connections`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + User.getToken(),
            },
        })
            .then((res) => res.json())
            .then((res) => res.connection)
            .catch((err) => {
                console.log(err);
            });
    };

    const getTenantUnifyRoutines = async () => {
        setIsLoading(true);
        const routines = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/unify/tenant/routines`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + User.getToken(),
                },
            }
        )
            .then((res) => res.json())
            .then((res) => {
                return res.routines;
            })
            .catch((err) => {
                console.log(err);
            });
        setRoutines(routines);
        setIsLoading(false);
    };

    const editUnifyRoutine = (row) => {
        navigate(`/unify/${row.id}`, { state: row });
    };

    const viewUnifyResults = (row) => {
        navigate(`/unify_results/${row.table.global_id}`);
    };

    const createTenantConnection = async () => {
        return await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/unify/tenant/connections`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + User.getToken(),
            },
        })
            .then((res) => res.json())
            .then((json) => json.success)
            .catch((err) => {
                console.log(err);
            });
    };

    const createNewConnection = async () => {
        const connection = await getTenantConnection();
        if (!connection) {
            const success = await createTenantConnection();
            if (!success) {
                openModal('creationError');
                return;
            }
        }

        navigate('/unify/new');
    };

    const deleteUnifyRoutine = async () => {
        return await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/unify/tenant/routines/${routineToDelete.id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + User.getToken(),
                },
            }
        )
            .then((res) => res.json())
            .then((res) => {
                if (res?.success) {
                    openModal('deleteSuccess');
                    getTenantUnifyRoutines();
                } else {
                    openModal('deleteError');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getModalOptions = () => {
        switch (modalType) {
            case 'creationError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: (
                        <p>There was an error initializing the Unify tool. Try again later.</p>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'delete':
                return {
                    type: 'confirmation',
                    title: 'Warning',
                    content: (
                        <div>
                            <p>
                                Are you sure you want to delete this unify routine for table{' '}
                                <b>{routineToDelete?.table.name}</b>? All unified results for this
                                table will be deleted as well.
                            </p>
                        </div>
                    ),
                    confirmBtnText: 'Yes',
                    cancelBtnText: 'No',
                    width: '350px',
                    textAlign: 'center',
                    confirmationBtnAction: async () => deleteUnifyRoutine(),
                };
            case 'deleteSuccess':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>Unify routine has been deleted.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'deleteError':
                return {
                    title: <WarningIcon fill="var(--error-color)" width="40" height="40" />,
                    content: (
                        <div>
                            <p>Unify routine could not be deleted.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    const openModal = (type) => {
        setModalType(type);
        setModalIsOpen(true);
    };

    const getColumns = useCallback(
        () => [
            {
                name: 'Table',
                sortable: false,
                selector: (row) => row.table.name,
                format: (row) => ucWords(row.table.name),
            },
            {
                name: 'Min. Threshold',
                sortable: false,
                selector: (row) => row.threshold,
            },
            {
                name: 'Auto Merge',
                sortable: false,
                selector: (row) => (row.auto_merge ? 'On' : 'Off'),
            },
            {
                name: canCreate(permissionSet) && (
                    <IconButton onClick={() => navigate('/unify/new')} padding="8px">
                        <AddIcon fill="var(--accent-color)" height="24" width="24" />
                    </IconButton>
                ),
                sortable: false,
                right: true,
                selector: (row) => {
                    return (
                        <div className="action-btns">
                            <IconButton onClick={() => editUnifyRoutine(row)} padding="8px">
                                <EditIcon height="24" width="24" fill="var(--font-color)" />
                            </IconButton>
                            {canDelete(permissionSet) && (
                                <IconButton
                                    onClick={() => {
                                        setRoutineToDelete(row);
                                        openModal('delete');
                                    }}
                                    padding="8px"
                                >
                                    <DeleteIcon fill="var(--error-color)" height="24" width="24" />
                                </IconButton>
                            )}
                        </div>
                    );
                },
            },
        ],
        [permissionSet, navigate]
    );

    useEffect(() => {
        if (!routines) {
            setColumns(getColumns());
            getTenantUnifyRoutines();
        }
    }, [routines, getColumns]);

    useEffect(() => {
        if (!routines) return;
        if (searchFilter === undefined) {
            setFilteredRoutines(routines);
            return;
        }
        const filteredRoutines = routines.filter((routine) =>
            routine.table.name.toLowerCase().includes(searchFilter.toLowerCase())
        );
        setFilteredRoutines(filteredRoutines);
    }, [searchFilter, routines]);

    return (
        <div className="unify">
            {isLoading ? (
                <div className="unify-loader">
                    <Loader />
                </div>
            ) : routines?.length > 0 ? (
                <>
                    <div className="unify-header">
                        <Search handler={(query) => setSearchFilter(query)} />
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredRoutines}
                        pagination={false}
                        highlightOnHover={true}
                        onRowClicked={viewUnifyResults}
                        persistTableHead={true}
                        selectableRows={false}
                    />
                </>
            ) : (
                <ContentPanel title="Unify">
                    <div className="unify-welcome">
                        <div className="content-col">
                            <h1>Unify your duplicated data into 1 single source of truth</h1>
                            <p>
                                Welcome to Unify! It looks like you haven’t set up any unification
                                rules yet. Get started now to streamline your data and ensure
                                consistency across your records. Begin your journey towards a
                                cleaner, more reliable dataset.
                            </p>
                            <div className="btn-row">
                                <Button onClick={createNewConnection}>Start Now</Button>
                            </div>
                        </div>
                        <div className="image-col">
                            <img src={unifyImage} alt="Unify Your Data" />
                        </div>
                    </div>
                </ContentPanel>
            )}
            <Modal options={getModalOptions()} isOpen={modalIsOpen} setOpen={setModalIsOpen} />
        </div>
    );
}

export default Unify;

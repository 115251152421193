export default class User {
    static USER_NAMESPACES = {
        fname: 'okzou-cdp-fname',
        lname: 'okzou-cdp-lname',
        username: 'okzou-cdp-username',
        email: 'okzou-cdp-email',
        role: 'okzou-cdp-role',
        token: 'okzou-cdp-token',
        exp: 'okzou-cdp-exp',
        authcheck: 'okzou-cdp-auth-check',
        init: 'okzou-cdp-user-init',
    };

    static async init(token) {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/fetch`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                const user = data?.user;
                if (user) {
                    if (!user.active) {
                        throw new Error('User inactive.');
                    } else if (user.role === 'API') {
                        throw new Error('User does not have permission to login.');
                    }
                    this.setFirstName(user.first_name);
                    this.setLastName(user.last_name);
                    this.setUserName(user.username);
                    this.setEmail(user.email);
                    this.setRole(user.role);
                    localStorage.setItem(this.USER_NAMESPACES['init'], 1);
                } else {
                    throw new Error('Unable to fetch user info.');
                }
            });
    }

    static isInit() {
        return localStorage.getItem(this.USER_NAMESPACES['init']) == 1;
    }

    static clear() {
        for (const value of Object.values(this.USER_NAMESPACES)) {
            localStorage.removeItem(value);
        }
    }

    static setFirstName(fname) {
        localStorage.setItem(this.USER_NAMESPACES['fname'], fname);
    }

    static setLastName(lname) {
        localStorage.setItem(this.USER_NAMESPACES['lname'], lname);
    }

    static setUserName(username) {
        localStorage.setItem(this.USER_NAMESPACES['username'], username);
    }

    static setEmail(email) {
        localStorage.setItem(this.USER_NAMESPACES['email'], email);
    }

    static setRole(role) {
        localStorage.setItem(this.USER_NAMESPACES['role'], role);
    }

    static getFirstName() {
        return localStorage.getItem(this.USER_NAMESPACES['fname']);
    }

    static getLastName() {
        return localStorage.getItem(this.USER_NAMESPACES['lname']);
    }

    static getUserName() {
        return localStorage.getItem(this.USER_NAMESPACES['username']) || 'user';
    }

    static getEmail() {
        return localStorage.getItem(this.USER_NAMESPACES['email']);
    }

    static getRole() {
        return localStorage.getItem(this.USER_NAMESPACES['role']) || '';
    }

    static getToken() {
        return localStorage.getItem(this.USER_NAMESPACES['token']);
    }

    // static async hasPermission({ object, action }) {
    //     const token = this.getToken();
    //     return new Promise((resolve, reject) => {
    //         fetch(`${process.env.REACT_APP_BACKEND_URL}/api/permission/verify`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             body: JSON.stringify({ object, action }),
    //         })
    //             .then((response) => response.json())
    //             .then((data) => {
    //                 resolve(data?.verified || false);
    //             })
    //             .catch((err) => reject(err));
    //     });
    // }
}

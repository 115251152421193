import './Import.css';
import { useEffect, useState } from 'react';

import { ReactComponent as SaveIcon } from 'assets/icons/save_icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check_icon_24.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon_24.svg';

import ContentPanel from 'components/ContentPanel/ContentPanel';
import Input from 'components/Form/Input/Input';
import FileInput from 'components/Form/FileInput/FileInput';
import Tooltip from 'components/Tooltip/Tooltip';
import { useNavigate } from 'react-router';
import User from 'utils/user.util';
import LoadingIconButton from 'components/Buttons/LoadingIconButton/LoadingIconButton';

const Import = () => {
    let navigate = useNavigate();
    const [name, setName] = useState('');
    const [nameEditable, setNameEditable] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [errors, setErrors] = useState({ name: '', file: '' });
    const [importError, setImportError] = useState(null);
    const [importSuccess, setImportSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleImport = async () => {
        setIsLoading(true);
        setImportError(null);
        if (await validate()) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('appName', name);
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/apps/import_user_app`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: 'Bearer ' + User.getToken(),
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    setImportSuccess(response.success);
                    setIsLoading(false);
                    if (response.success) {
                        setTimeout(() => navigate('/apps'), 2000);
                    } else {
                        setImportError('Could not import compressed app.');
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    setImportError('Could not import compressed app.');
                });
        } else {
            setIsLoading(false);
        }
    };

    const validate = async () => {
        const _errors = { name: '', file: '' };
        let isValid = true;

        if (!name || name.length < 3) {
            _errors.name = 'App name must be at least 3 characters long.';
            isValid = false;
        }

        if (!selectedFile) {
            _errors.file = 'Zipped file is required.';
            isValid = false;
        }

        setErrors(_errors);
        if (!isValid) {
            return false;
        }
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/apps/validate_name`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + User.getToken(),
                },
                body: JSON.stringify({
                    name,
                }),
            }
        );
        const jsonResponse = await response.json();
        if (jsonResponse && !jsonResponse.success) {
            setErrors({ ..._errors, name: 'The app name must be unique.' });
            return false;
        }

        return true;
    };

    const onFileChangeHandler = (file) => {
        setSelectedFile(file);
    };

    useEffect(() => {
        const titleFocus = (event) => {
            try {
                const isTitleFocus =
                    event.target.className?.includes('title-edit') ||
                    event.target.parentNode?.className?.includes('title-edit');
                if (!isTitleFocus && nameEditable) {
                    setNameEditable(false);
                }
            } catch (error) {}
        };

        document.addEventListener('click', titleFocus);

        return function cleanup() {
            document.removeEventListener('click', titleFocus);
        };
    }, [nameEditable]);

    return (
        <div className="app-import">
            <ContentPanel
                title={
                    <div className="header">
                        <div>Import App</div>

                        <span className="header-buttons">
                            {importError && (
                                <Tooltip tip={importError}>
                                    <ErrorIcon fill="var(--error-color)" />
                                </Tooltip>
                            )}
                            {importSuccess && <CheckIcon fill="var(--success-color)" />}
                            <LoadingIconButton
                                isLoading={isLoading}
                                onClick={handleImport}
                                tooltip="Import App"
                                className="import-btn"
                                loaderColor="dark"
                            >
                                <SaveIcon fill="var(--accent-color)" height="24" width="24" />
                            </LoadingIconButton>
                        </span>
                    </div>
                }
            >
                <div className="body">
                    <div className="input-group">
                        <Input
                            label="App Name"
                            value={name}
                            setValue={(e) => {
                                setName(e.target.value);
                            }}
                            type="text"
                            placeholder="App Name"
                            marginBottom="5px"
                            width="350px"
                            error={errors.name}
                            displayError
                        />
                    </div>
                    <FileInput
                        width="350px"
                        file={selectedFile}
                        setFile={onFileChangeHandler}
                        label="Zipped File"
                        accept=".zip"
                        error={errors.file}
                    />
                </div>
            </ContentPanel>
        </div>
    );
};

export default Import;

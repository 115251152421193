import './Segment.css';

// Libraries
import React, { useRef, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';

// Components
import Alert from 'components/Alert/Alert';
import FilterGroup from './FilterGroup/FilterGroup';
import Modal from 'components/Modal/Modal';
import ContentPanel from 'components/ContentPanel/ContentPanel';
import Dropdown from 'components/Form/Dropdown/Dropdown';
import DraggablePill from 'components/DraggablePill/DraggablePill';
import SegmentPreview from '../SegmentPreview/SegmentPreview';
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';
import ContentHeader from 'components/ContentHeader/ContentHeader';
import EditableHighlightField from 'components/EditableHighlightField/EditableHighlightField';
import SideMenu from 'components/SideMenu/SideMenu';
import Accordion from 'components/Accordion/Accordion';
import CardList from 'components/CardList/CardList';
import Button from 'components/Buttons/Button/Button';
import Loader from 'components/Loader/Loader';
import SegmentDragLayer from 'views/Segmentation/Segment/SegmentDragLayer/SegmentDragLayer';
import Tooltip from 'components/Tooltip/Tooltip';
import IconButton from 'components/Buttons/IconButton/IconButton';
import SelectTableJoinPathModal from 'components/Segment/SelectTableJoinPathModal/SelectTableJoinPathModal';

// Variables & Models
import { default as FilterGroupModel } from 'views/Segmentation/models/filterGroup';
import { DraggableItemTypes } from '../models/definitions';
import TableProperty from '../models/tableProperty';

// Utils & hooks
import { capFirst } from 'utils/text.util';
import {
    useQuery,
    useLazyQuery,
    GET_SEGMENT,
    IS_SEGMENT_NAME_VALID,
    DELETE_SEGMENT,
    updateSegment,
    createSegment,
    GET_CONSENTS_FOR_TABLE,
    GET_TABLES,
    GET_SEGMENT_TABLE_TREE,
    useMutation,
    getSegmentResultsCount,
} from 'utils/graphql';
import { canCreate, canDelete } from 'utils/permission.util';
import { useNavCollapse } from 'contexts/NavCollapseContext';
import { useFetch } from 'utils/rest/request';
import { loadQueryFilterGroup, getTableArrayFromTableTree } from 'utils/segment.util';

// Assets
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';
import { ReactComponent as ExpandMoreIcon } from 'assets/icons/expand_more_24.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star_icon_24.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_icon.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play_icon_24.svg';
import { ReactComponent as BackIcon } from 'assets/icons/back_icon_24.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download_icon_24.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/exclamation-triangle.svg';
import { ReactComponent as RouteIcon } from 'assets/icons/route_icon_24.svg';

const Segment = () => {
    let navigate = useNavigate();
    const location = useLocation();
    let { segment_id } = useParams();
    const navCollapsed = useNavCollapse();
    const segmentContainerRef = useRef(null);
    const segmentBuilderRef = useRef(null);
    const { permissionSet } = useContext(CurrentPermissionContext);
    const [segmentType, setSegmentType] = useState('segment');
    const [name, setName] = useState('');
    const [consents, setConsents] = useState();
    const [consent, setConsent] = useState();
    const [updatedConsent, setUpdatedConsent] = useState(false);
    const [consentTableProperty, setConsentTableProperty] = useState();
    const [isConsentJoinPathModalOpen, setIsConsentJoinPathModalOpen] = useState(false);
    const [primaryTableId, setPrimaryTableId] = useState('');
    const [primaryTableTree, setPrimaryTableTree] = useState();
    const [includeFilterGroup, setIncludeFilterGroup] = useState(new FilterGroupModel(true));
    const [excludeFilterGroup, setExcludeFilterGroup] = useState(new FilterGroupModel(true));
    const [includeCollapsed, setIncludeCollapsed] = useState(false);
    const [excludeCollapsed, setExcludeCollapsed] = useState(true);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [errorMsgs, setErrorMsgs] = useState();
    const [isPreview, setIsPreview] = useState(false);
    const [tablePropertyItemsForAccordion, setTablePropertyItemsForAccordion] = useState([]);
    const [loadingTableProperties, setLoadingTableProperties] = useState(true);
    const [showSaveMenu, setShowSaveMenu] = useState(false);
    const [segmentToDelete, setSegmentToDelete] = useState();
    const [tables, setTables] = useState([]);
    const [joinTables, setJoinTables] = useState([]);
    const [primaryTableSelected, setPrimaryTableSelected] = useState(false);
    const [callUpdateFilterGroupOnHover, setCallUpdateFilterGroupOnHover] = useState(false);
    const [triggerClearAllPlaceholders, setTriggerClearAllPlaceholders] = useState(false);
    const [isCombinePreviewActive, setIsCombinePreviewActive] = useState(false);
    const [isSegmentBuilderFixed, setIsSegmentBuilderFixed] = useState(false);
    const [isLoadingSegmentBuilder, setIsLoadingSegmentBuilder] = useState(false);
    const [segmentBuilderWidth, setSegmentBuilderWidth] = useState('0');
    const [segmentBuilderLeftPos, setSegmentBuilderLeftPos] = useState();
    const [resultsCount, setResultsCount] = useState();
    const [doneLoading, setDoneLoading] = useState(false);

    if (segment_id === 'new') {
        segment_id = null;
    }

    const [deleteSegment] = useMutation(DELETE_SEGMENT);

    const { data: segment, loading: isLoadingSegment } = useQuery(GET_SEGMENT, {
        skip: !segment_id,
        variables: { id: segment_id },
        fetchPolicy: 'no-cache',
    });

    const { data: consentsData } = useQuery(GET_CONSENTS_FOR_TABLE, {
        fetchPolicy: 'no-cache',
        skip: !primaryTableId,
        variables: { id: primaryTableId },
    });

    // Loading table tree associated with primary table
    const { data: tableTree, loading: loadingTableTree } = useQuery(GET_SEGMENT_TABLE_TREE, {
        skip: !primaryTableId,
        variables: { tableId: primaryTableId },
    });

    const { data: tablesData } = useQuery(GET_TABLES, {
        variables: {
            offset: 0,
            limit: 99,
        },
        fetchPolicy: 'cache-and-network',
    });

    const {
        data: apps,
        error: connected_apps_error,
        loading: apps_loading,
    } = useFetch('/api/connected_apps', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 3000,
    });

    const [isSegmentationNameValid] = useLazyQuery(IS_SEGMENT_NAME_VALID);

    const openModal = (type) => {
        setModalType(type);
        setIsOpen(true);
    };

    const getModalOptions = (type) => {
        switch (type) {
            case 'delete':
                return {
                    type: 'confirmation',
                    title: 'Warning',
                    content: (
                        <div>
                            <p>Are you sure you want to delete this segment? </p>
                            <b>{segmentToDelete?.name}</b>
                        </div>
                    ),
                    confirmBtnText: 'Yes',
                    cancelBtnText: 'No',
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: async () => {
                        deleteSegment({
                            variables: { id: segmentToDelete.id },
                            onCompleted: (data) => {
                                if (data.deleteSegmentation) {
                                    navigate(`/segmentation`);
                                } else {
                                    openModal('deleteError');
                                }
                            },
                        });
                    },
                };
            case 'deleteError':
                return {
                    title: <WarningIcon fill="var(--error-color)" width="40" height="40" />,
                    content: (
                        <div>
                            <p>Segment could not be deleted.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'cancel':
                return {
                    type: 'confirmation',
                    title: 'Are you sure?',
                    content: (
                        <div>
                            <p>All unsaved changes will be lost.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => {
                        const returnRoute = segmentType === 'segment' ? 'segmentation' : 'consent';
                        navigate(`/${returnRoute}`);
                    },
                };
            case 'save':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>{capFirst(segmentType)} saved.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => {},
                };
            case 'saveAs':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>New {capFirst(segmentType)} created.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => {},
                };
            case 'creationError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: <p>There was an error while creating the segment.</p>,
                    width: '250px',
                    textAlign: 'center',
                };
            case 'updateError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: <p>There was an error while updating the segment.</p>,
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    const getResultsCount = (
        loadedIncludeFilterGroup = null,
        loadedExcludeFilterGroup = null,
        loadedConsentTableProperty = null
    ) => {
        const segmentation = {
            name: name,
            isConsent: segmentType === 'consent' ? true : false,
            primaryTableId: primaryTableId,
            includeFilterGroup: loadedIncludeFilterGroup
                ? loadedIncludeFilterGroup
                : includeFilterGroup,
            excludeFilterGroup: loadedExcludeFilterGroup
                ? loadedExcludeFilterGroup
                : excludeFilterGroup,
            consentId: consent?.global_id,
            consentJoinPath: loadedConsentTableProperty
                ? loadedConsentTableProperty.joinPath
                : consentTableProperty?.joinPath,
        };
        getSegmentResultsCount(segmentation, {
            fetchPolicy: 'network-only',
        }).subscribe((response) => {
            if (response.data) {
                setResultsCount(response.data.segmentationResultsCount);
            }
        });
    };

    const handleDownload = (id) => {
        navigate(`/segmentation/download/${id}`);
    };

    const handleNewFlow = (id) => {
        navigate(`/segmentation/flow/new/${id}`);
    };

    const showConsentDropdown = () => {
        return !isPreview && segmentType === 'segment' && consents?.length > 0;
    };

    const validate = async (isSaveAs = false) => {
        let _errorMsgs = [];
        if (includeFilterGroup.filterCount === 0 && excludeFilterGroup.filterCount === 0) {
            _errorMsgs = ['Filters cannot be empty.'];
        } else {
            const isIncludeFilterGroupValide = includeFilterGroup.validate();
            const isExcludeFilterGroupValide = excludeFilterGroup.validate();
            if (!isIncludeFilterGroupValide || !isExcludeFilterGroupValide) {
                _errorMsgs = ['Highlighted filters and filter groups are not valid.'];
            }
            // updating filter groups state
            updateFilterGroup(includeFilterGroup);
            updateFilterGroup(excludeFilterGroup);
        }
        if (includeFilterGroup.maxDepth > 5) {
            _errorMsgs.push('The maximum depth of 5 has been exceeded for the include filters.');
        }
        if (excludeFilterGroup.maxDepth > 5) {
            _errorMsgs.push('The maximum depth of 5 has been exceeded for the exclude filters.');
        }

        if (!name.trim()) {
            _errorMsgs.push(`${capFirst(segmentType)} name cannot be empty.`);
        } else if (isSaveAs && segment?.segmentation && segment.segmentation?.name === name) {
            _errorMsgs.push([`${capFirst(segmentType)} name already exists.`]);
        } else if (!(segment?.segmentation && segment.segmentation.name === name)) {
            const { data } = await isSegmentationNameValid({
                variables: { name },
            });
            if (!data.isSegmentationNameValid) {
                _errorMsgs.push(`${capFirst(segmentType)} name already exists.`);
            }
        }

        setErrorMsgs(_errorMsgs);
        return _errorMsgs.length === 0;
    };

    const togglePreview = async () => {
        if (await validate()) {
            if (!isPreview) {
                getResultsCount();
            }
            setIsPreview((isPreview) => !isPreview);
        }
    };

    const onSaveClickHandler = async () => {
        if (await validate()) {
            if (segment_id) {
                // UPDATE
                const segmentation = {
                    id: segment_id,
                    name: name,
                    primaryTableId: primaryTableId,
                    includeFilterGroup: includeFilterGroup,
                    excludeFilterGroup: excludeFilterGroup,
                    consentId: consent ? consent.global_id : null,
                    consentJoinPath:
                        consent && consentTableProperty?.joinPath
                            ? {
                                  path: consentTableProperty.joinPath,
                                  id: consentTableProperty.joinPathId,
                              }
                            : null,
                };
                updateSegment(segmentation).then((result) => {
                    if (result.errors) {
                        openModal('updateError');
                    } else {
                        openModal('save');
                    }
                });
            } else {
                createSegmentation();
            }
        }
    };

    const onSaveAsClickHandler = async () => {
        if (await validate(true)) {
            createSegmentation();
        }
    };

    const createSegmentation = () => {
        const segmentation = {
            name: name,
            isConsent: segmentType === 'consent' ? true : false,
            primaryTableId: primaryTableId,
            includeFilterGroup: includeFilterGroup,
            excludeFilterGroup: excludeFilterGroup,
            consentId: consent ? consent.global_id : null,
            consentJoinPath:
                consent && consentTableProperty?.joinPath ? consentTableProperty.joinPath : null,
        };
        createSegment(segmentation).then((result) => {
            if (result.errors) {
                openModal('creationError');
            } else {
                openModal('saveAs');
                setTimeout(() => {
                    const returnRoute = segmentType === 'segment' ? 'segmentation' : 'consent';
                    navigate(`/${returnRoute}/` + result.data.createSegmentation.segmentation.id);
                }, 1000);
            }
        });
    };

    const updateFilterGroup = (
        type,
        { id, filters, operator, isPlaceholder, isCombining } = {}
    ) => {
        const filterGroup = type === 'include' ? includeFilterGroup : excludeFilterGroup;
        const otherFilterGroup = type === 'include' ? excludeFilterGroup : includeFilterGroup;
        id = id || filterGroup.id;
        filters = filters || filterGroup.filters;
        operator = operator || filterGroup.operator;
        isPlaceholder = isPlaceholder != null ? isPlaceholder : filterGroup.isPlaceholder;
        isCombining = isCombining != null ? isCombining : filterGroup.isCombining;

        const updatedFilterGroup = new FilterGroupModel(
            filterGroup.isRoot,
            operator,
            filters,
            filterGroup.isNew,
            isPlaceholder,
            isCombining,
            filterGroup.isValid,
            id
        );
        const otherUpdatedFilterGroup = new FilterGroupModel(
            otherFilterGroup.isRoot,
            otherFilterGroup.operator,
            filterOutAllChildPlaceholders(otherFilterGroup.filters),
            otherFilterGroup.isNew,
            otherFilterGroup.isPlaceholder,
            otherFilterGroup.isCombining,
            otherFilterGroup.isValid,
            otherFilterGroup.id
        );
        if (type === 'include') {
            setIncludeFilterGroup(updatedFilterGroup);
            setExcludeFilterGroup(otherUpdatedFilterGroup);
        } else {
            setExcludeFilterGroup(updatedFilterGroup);
            setIncludeFilterGroup(otherUpdatedFilterGroup);
        }
    };

    const filterOutAllChildPlaceholders = (filters) => {
        return update(filters, {
            $apply: (filters) =>
                filters
                    .filter((filter) => !filter.isPlaceholder)
                    .map((filter) => {
                        // if child filterGroup, recursively filter out child placeholders
                        if (!filter.isFilter) {
                            return update(filter, {
                                filters: { $set: filterOutAllChildPlaceholders(filter.filters) },
                            });
                        }
                        return filter;
                    }),
        });
    };

    const propertyDragHandler = (item, monitor) => {
        // checking if the property was dropped outside the drop area
        if (!monitor.didDrop()) {
            setIsCombinePreviewActive(false);
            setTriggerClearAllPlaceholders((value) => !value);
        }
        if (!callUpdateFilterGroupOnHover) {
            setCallUpdateFilterGroupOnHover(true);
        }
    };

    const handleScrollAndResize = () => {
        if (!segmentBuilderRef.current) return;
        const fixedBreakPoint =
            window.scrollY + segmentBuilderRef.current.getBoundingClientRect().top;
        setIsSegmentBuilderFixed(window.scrollY >= fixedBreakPoint);
        updateSegmentBuilderWidthAndPosition();
    };

    const updateSegmentBuilderWidthAndPosition = () => {
        if (!segmentBuilderRef.current) return;
        setSegmentBuilderWidth(segmentBuilderRef.current.getBoundingClientRect().width);
        setSegmentBuilderLeftPos(segmentBuilderRef.current.getBoundingClientRect().left);
    };

    /**
     * Load existing segmentation
     */
    useEffect(() => {
        if (!segment?.segmentation) return;
        setName(segment?.segmentation.name);
        setPrimaryTableId(segment?.segmentation.primaryTable.id);
        setPrimaryTableSelected(true);
    }, [segment]);

    // load existing segmentation include and exclude filter groups
    useEffect(() => {
        if (!segment?.segmentation || !primaryTableTree) return;
        let _includeFilterGroup, _excludeFilterGroup;
        if (segment?.segmentation.includeFilterGroup) {
            _includeFilterGroup = loadQueryFilterGroup(
                segment?.segmentation.includeFilterGroup,
                primaryTableTree,
                true
            );
            setIncludeFilterGroup(_includeFilterGroup);
        } else {
            setIncludeCollapsed(true);
        }
        if (segment?.segmentation.excludeFilterGroup) {
            _excludeFilterGroup = loadQueryFilterGroup(
                segment?.segmentation.excludeFilterGroup,
                primaryTableTree,
                true
            );
            setExcludeFilterGroup(_excludeFilterGroup);
            setExcludeCollapsed(false);
        }
    }, [segment, primaryTableTree]);

    // load consent and consent join path
    useEffect(() => {
        if (!segment?.segmentation || !primaryTableTree || !consents) return;
        let _consentTableProperty = null;
        if (segment?.segmentation.consent) {
            const consent = consents.find(
                (consent) => consent.global_id === segment.segmentation.consent.id
            );
            setConsent(consent);
            _consentTableProperty = new TableProperty(
                {
                    id: uuidv4(),
                    property: 'id',
                    table: {
                        id: consent.primary_table,
                        global_id: consent.primary_table_global_id,
                    },
                    type: { type: 'UUID' },
                },
                primaryTableTree,
                segment?.segmentation.consentJoinPath
                    ? segment?.segmentation.consentJoinPath.path
                    : null,
                segment?.segmentation.consentJoinPath
                    ? segment?.segmentation.consentJoinPath.id
                    : null
            );
            setConsentTableProperty(_consentTableProperty);
        }
        setDoneLoading(true);
    }, [segment, primaryTableTree, consents]);

    useEffect(() => {
        if (segment_id && doneLoading) {
            getResultsCount(includeFilterGroup, excludeFilterGroup, consentTableProperty);
        }
    }, [doneLoading]);

    useEffect(() => {
        const returnPath = location.pathname.split('/')[1];
        setSegmentType(returnPath === 'segmentation' ? 'segment' : 'consent');
    }, []);

    useEffect(() => {
        if (!tablesData || !tablesData.tableSchemaList) return;

        const _tables = tablesData.tableSchemaList.edges
            .filter((edge) => !edge.node.isJunction)
            .map((edge) => edge.node);
        setTables(_tables);
        if (!segment_id) {
            setPrimaryTableId(_tables[0]?.id);
        }
    }, [tablesData]);

    useEffect(() => {
        if (!tableTree) return;
        const accordianItems = [];
        const _tables = getTableArrayFromTableTree(tableTree.segmentationTableTree);
        setJoinTables(_tables);
        for (let table of _tables) {
            // using the first property id and table so that the existing logic works
            const firstProperty = table.properties[0];
            const _properties = [...table.properties];
            if (primaryTableId !== table.global_id) {
                _properties.push({
                    property: 'count',
                    id: `${firstProperty.id}_count`,
                    global_id: firstProperty.global_id,
                    type: {
                        type: 'Function',
                    },
                    table: { ...firstProperty.table },
                });
            }

            accordianItems.push({
                header: (
                    <div className="segment-table-header">
                        {table.name}{' '}
                        {table.global_id === primaryTableId && (
                            <Tooltip
                                tip="Primary table. Segment results will be from this object."
                                width="200px"
                                position="right-center"
                            >
                                <StarIcon fill="var(--accent-color)" />
                            </Tooltip>
                        )}
                    </div>
                ),
                body: (
                    <>
                        {_properties.map((property, i) => (
                            <DraggablePill
                                type={DraggableItemTypes.PROPERTY}
                                key={i}
                                index={i}
                                text={property.property}
                                data={property}
                                dragHandler={propertyDragHandler}
                                dependencies={[property]}
                            />
                        ))}
                    </>
                ),
                classNameHeader: table.global_id === primaryTableId ? 'primary-table-header' : '',
            });
        }
        setTablePropertyItemsForAccordion(accordianItems);
        setLoadingTableProperties(false);
        setPrimaryTableTree(tableTree.segmentationTableTree);
    }, [tableTree]);

    useEffect(() => {
        if (!consentsData) return;
        setConsents(consentsData.consentList);
    }, [consentsData]);

    useEffect(() => {
        if (!consent) {
            setConsentTableProperty(null);
        } else if (primaryTableTree) {
            const tableProperty = new TableProperty(
                {
                    id: uuidv4(),
                    property: 'id',
                    table: {
                        id: consent.primary_table,
                        global_id: consent.primary_table_global_id,
                    },
                    type: { type: 'UUID' },
                },
                primaryTableTree,
                null,
                segment?.segmentation.consentJoinPath
                    ? segment?.segmentation.consentJoinPath.id
                    : null
            );
            setConsentTableProperty(tableProperty);
        }
    }, [updatedConsent]);

    useEffect(() => {
        if (!primaryTableSelected || !segmentBuilderRef.current) return;
        updateSegmentBuilderWidthAndPosition();
        window.addEventListener('scroll', handleScrollAndResize, { passive: true });
        window.addEventListener('resize', updateSegmentBuilderWidthAndPosition);

        const resizeObserver = new ResizeObserver(handleScrollAndResize);
        if (segmentContainerRef.current) {
            resizeObserver.observe(segmentContainerRef.current);
        }

        // clean up event listeners and the resize observer
        return () => {
            window.removeEventListener('scroll', handleScrollAndResize);
            window.removeEventListener('resize', updateSegmentBuilderWidthAndPosition);
            resizeObserver.disconnect();
        };
    }, [primaryTableSelected]);

    useEffect(() => {
        if (!primaryTableSelected || !segmentBuilderRef.current) return;
        setIsLoadingSegmentBuilder(true);
        const timeOutId = setTimeout(() => {
            updateSegmentBuilderWidthAndPosition();
            setIsLoadingSegmentBuilder(false);
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [navCollapsed]);

    return (
        <div className="segment">
            {!primaryTableSelected && !segment_id ? (
                <ContentPanel title={'Select Primary Table'}>
                    <div className="segment-primary-table">
                        <Dropdown
                            value={primaryTableId}
                            values={tables.map((table) => ({ text: table.name, value: table.id }))}
                            width="100%"
                            setValue={(e) => {
                                setPrimaryTableId(e.target.value);
                            }}
                        />
                        <button
                            className="btn segment-primary-table-btn"
                            onClick={() => setPrimaryTableSelected(true)}
                        >
                            Continue
                        </button>
                    </div>
                </ContentPanel>
            ) : (
                <div ref={segmentContainerRef}>
                    <ContentHeader>
                        <div className="segment-header">
                            <div className="segment-name">
                                <EditableHighlightField
                                    defaultIsEditing={!segment_id}
                                    defaultDisplayName={`${capFirst(segmentType)} Name`}
                                    placeholder={`${capFirst(segmentType)} Name`}
                                    value={name}
                                    setValue={(value) => setName(value)}
                                />
                            </div>
                            <div className="segment-action-btns">
                                <div className="action-btn-group">
                                    {!isPreview && canCreate(permissionSet) && (
                                        <div style={{ position: 'relative' }}>
                                            <Button
                                                height="35px"
                                                onClick={() =>
                                                    segment_id
                                                        ? setShowSaveMenu(true)
                                                        : onSaveClickHandler()
                                                }
                                                isDisabled={
                                                    loadingTableProperties || isLoadingSegment
                                                }
                                            >
                                                Save
                                                {segment_id && <ExpandMoreIcon fill="#fff" />}
                                            </Button>
                                            <CardList
                                                items={[
                                                    {
                                                        content: 'Save',
                                                        clickCallBack: () => {
                                                            onSaveClickHandler();
                                                            setShowSaveMenu(false);
                                                        },
                                                    },
                                                    {
                                                        content: 'Save As',
                                                        clickCallBack: () => {
                                                            onSaveAsClickHandler();
                                                            setShowSaveMenu(false);
                                                        },
                                                    },
                                                ]}
                                                onClickOutside={() => setShowSaveMenu(false)}
                                                getCardItemContent={(item) => item.content}
                                                show={showSaveMenu}
                                                width="auto"
                                                top="40px"
                                                right="0"
                                                left="10px"
                                                showDivider="all"
                                            />
                                        </div>
                                    )}
                                    <Button
                                        height="35px"
                                        width={isPreview ? '120px' : '100px'}
                                        onClick={togglePreview}
                                        isDisabled={loadingTableProperties || isLoadingSegment}
                                    >
                                        {isPreview ? 'Back to Builder' : 'Preview'}
                                    </Button>

                                    {showConsentDropdown() && (
                                        <>
                                            <Dropdown
                                                width="150px"
                                                height="35px"
                                                value={consent ? consent.global_id : ''}
                                                values={[
                                                    {
                                                        text: !consent ? 'Select Consent' : 'None',
                                                        value: '',
                                                        disabled: false,
                                                    },
                                                    ...consents.map((consent) => ({
                                                        text: consent.name,
                                                        value: consent.global_id,
                                                    })),
                                                ]}
                                                setValue={(e) => {
                                                    setConsent(
                                                        consents.find(
                                                            (consent) =>
                                                                consent.global_id === e.target.value
                                                        )
                                                    );
                                                    setUpdatedConsent((prev) => !prev);
                                                }}
                                            />
                                            {consent &&
                                                consent.primary_table_global_id !==
                                                    primaryTableId && (
                                                    <>
                                                        <IconButton
                                                            padding="5px"
                                                            tooltip="Select Path for Consent"
                                                            className="consent-join-btn"
                                                            onClick={() =>
                                                                setIsConsentJoinPathModalOpen(true)
                                                            }
                                                        >
                                                            <RouteIcon
                                                                height={20}
                                                                width={20}
                                                                fill="#fff"
                                                            />
                                                        </IconButton>
                                                        <SelectTableJoinPathModal
                                                            isOpen={isConsentJoinPathModalOpen}
                                                            setOpen={setIsConsentJoinPathModalOpen}
                                                            tableProperty={consentTableProperty}
                                                        />
                                                    </>
                                                )}
                                        </>
                                    )}
                                </div>
                                <div className="action-btn-group action-settings">
                                    {resultsCount !== undefined && (
                                        <div className="results-count">
                                            Results Count: {resultsCount}
                                        </div>
                                    )}
                                    {segment_id && (
                                        <>
                                            <IconButton
                                                onClick={() => {
                                                    if (apps_loading) {
                                                        return openModal('flowsLoading');
                                                    } else if (connected_apps_error) {
                                                        return openModal('flowsLoadingError');
                                                    }
                                                    handleNewFlow(segment_id);
                                                }}
                                                tooltip="Create Flow"
                                            >
                                                <PlayIcon
                                                    fill={
                                                        apps_loading || connected_apps_error
                                                            ? 'var(--greyscale-highlight-color)'
                                                            : 'var(--font-color)'
                                                    }
                                                    height="24"
                                                    width="24"
                                                />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => handleDownload(segment_id)}
                                                tooltip="Export CSV"
                                            >
                                                <DownloadIcon
                                                    fill="var(--font-color)"
                                                    height="24"
                                                    width="24"
                                                />
                                            </IconButton>
                                            {canDelete(permissionSet) && (
                                                <IconButton
                                                    tooltip="Delete"
                                                    onClick={() => {
                                                        setSegmentToDelete({
                                                            name,
                                                            id: segment_id,
                                                        });
                                                        openModal('delete');
                                                    }}
                                                >
                                                    <DeleteIcon
                                                        fill="var(--error-color)"
                                                        height="24"
                                                        width="24"
                                                    />
                                                </IconButton>
                                            )}
                                        </>
                                    )}
                                    <IconButton
                                        tooltip="Exit"
                                        onClick={() => {
                                            openModal('cancel');
                                        }}
                                    >
                                        <BackIcon height="24" width="24" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </ContentHeader>
                    <Modal
                        options={getModalOptions(modalType)}
                        isOpen={modalIsOpen}
                        setOpen={setIsOpen}
                    />
                    {!isPreview ? (
                        <div className="segment-body">
                            <DndProvider backend={HTML5Backend}>
                                <SegmentDragLayer />
                                <div className="segment-table-list">
                                    <SideMenu header="Fields" width="auto" minHeight="560px">
                                        <Accordion
                                            items={tablePropertyItemsForAccordion}
                                            loading={loadingTableProperties || loadingTableTree}
                                            padding="10px"
                                        />
                                    </SideMenu>
                                </div>
                                <div className="segment-builder-container" ref={segmentBuilderRef}>
                                    {isLoadingSegmentBuilder ||
                                    isLoadingSegment ||
                                    loadingTableProperties ? (
                                        <div className="segment-builder-loader">
                                            <Loader />
                                        </div>
                                    ) : (
                                        <div
                                            className={`segment-builder ${
                                                isSegmentBuilderFixed ? 'fixed' : ''
                                            }`}
                                            style={{
                                                width: segmentBuilderWidth,
                                                left: segmentBuilderLeftPos,
                                            }}
                                        >
                                            {errorMsgs?.length > 0 && (
                                                <div className="segment-errors">
                                                    {errorMsgs.map((msg, i) => (
                                                        <Alert key={i} variant="error">
                                                            {msg}
                                                        </Alert>
                                                    ))}
                                                </div>
                                            )}
                                            <div
                                                className={`include filter-group-card${
                                                    includeCollapsed ? ' collapsed' : ''
                                                }`}
                                            >
                                                <div
                                                    className="vertical-header"
                                                    onClick={() =>
                                                        setIncludeCollapsed((prev) => !prev)
                                                    }
                                                >
                                                    <p className="vertical-header-title">Include</p>
                                                </div>
                                                <div
                                                    className="overlay"
                                                    onClick={() =>
                                                        setIncludeCollapsed((prev) => !prev)
                                                    }
                                                ></div>
                                                <div className="drop-zone">
                                                    <FilterGroup
                                                        filterGroup={includeFilterGroup}
                                                        updateFilterGroup={(options) => {
                                                            updateFilterGroup('include', options);
                                                        }}
                                                        callUpdateFilterGroupOnHover={
                                                            callUpdateFilterGroupOnHover
                                                        }
                                                        triggerClearAllPlaceholders={
                                                            triggerClearAllPlaceholders
                                                        }
                                                        isCombinePreviewActive={
                                                            isCombinePreviewActive
                                                        }
                                                        setIsCombinePreviewActive={
                                                            setIsCombinePreviewActive
                                                        }
                                                        primaryTableId={primaryTableId}
                                                        primaryTableTree={primaryTableTree}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={`exclude filter-group-card${
                                                    excludeCollapsed ? ' collapsed' : ''
                                                }`}
                                            >
                                                <div
                                                    className="vertical-header"
                                                    onClick={() =>
                                                        setExcludeCollapsed(!excludeCollapsed)
                                                    }
                                                >
                                                    <p className="vertical-header-title">Exclude</p>
                                                </div>
                                                <div
                                                    className="overlay"
                                                    onClick={() =>
                                                        setExcludeCollapsed(!excludeCollapsed)
                                                    }
                                                ></div>
                                                <div className="drop-zone">
                                                    <FilterGroup
                                                        filterGroup={excludeFilterGroup}
                                                        updateFilterGroup={(options) => {
                                                            updateFilterGroup('exclude', options);
                                                        }}
                                                        callUpdateFilterGroupOnHover={
                                                            callUpdateFilterGroupOnHover
                                                        }
                                                        triggerClearAllPlaceholders={
                                                            triggerClearAllPlaceholders
                                                        }
                                                        isCombinePreviewActive={
                                                            isCombinePreviewActive
                                                        }
                                                        setIsCombinePreviewActive={
                                                            setIsCombinePreviewActive
                                                        }
                                                        primaryTableId={primaryTableId}
                                                        primaryTableTree={primaryTableTree}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </DndProvider>
                        </div>
                    ) : (
                        <div className="segment-preview">
                            <SegmentPreview
                                name={name}
                                segmentType={segmentType}
                                primaryTable={joinTables.find(
                                    (t) => t.global_id === primaryTableId
                                )}
                                includeFilterGroup={includeFilterGroup}
                                excludeFilterGroup={excludeFilterGroup}
                                consent={consent}
                                consentJoinPath={consentTableProperty?.joinPath}
                            ></SegmentPreview>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Segment;

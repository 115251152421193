import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import PropTypes from 'prop-types';

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({ title, data, innerRef = null }) {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: title,
                text: title,
            },
        },
    };

    return <Pie options={options} data={data} ref={innerRef} />;
}

PieChart.propTypes = {
    title: PropTypes.string,
    data: PropTypes.object.isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

export default PieChart;

class TableProperty {
    constructor(tableProperty, primaryTableTree, joinPath = null, joinPathId = null) {
        this.joinPathId = joinPathId;
        this.tableProperty = tableProperty;
        this.primaryTableTree = primaryTableTree;
        if (this.primaryTableTree) {
            this.treePaths = this.findTreePathsToFilterTable(
                primaryTableTree,
                tableProperty,
                !tableProperty.table.__typename
            ).sort((a, b) => a.length - b.length);
        } else {
            this.treePaths = [];
        }

        // if join path is not passed, set join tree path index to 0
        if (!joinPath) {
            this.selectedTreePathIndex = 0;
        } else {
            // finding matching join tree path
            const joinObject =
                typeof joinPath === 'string' || joinPath instanceof String
                    ? JSON.parse(joinPath)
                    : joinPath;
            for (let i = 0; i < this.treePaths.length; i++) {
                const treePath = this.getJoinPathAtIndex(i);
                if (!treePath) {
                    this.selectedTreePathIndex = 0;
                    return;
                }
                if (treePath.length !== joinObject.length) continue;
                let pathMatches = true;
                for (let j = 0; j < treePath.length; j++) {
                    if (
                        treePath[j].table.id !== joinObject[j].table.id ||
                        treePath[j].table_property.id !== joinObject[j].table_property.id
                    ) {
                        pathMatches = false;
                        break;
                    }
                }
                if (pathMatches) {
                    this.selectedTreePathIndex = i;
                    break;
                }
            }
        }
    }

    get tableName() {
        return this.tableProperty.table.name;
    }

    get tableId() {
        return this.tableProperty.table.id;
    }

    get tableGlobalId() {
        return this.tableProperty.table.global_id;
    }

    get propertyGlobalId() {
        return this.tableProperty.global_id;
    }

    get propertyId() {
        return this.tableProperty.id;
    }

    get propertyName() {
        return this.tableProperty.property;
    }

    get type() {
        return this.tableProperty.type.type;
    }

    get joinPath() {
        return this.getJoinPathAtIndex(this.selectedTreePathIndex);
    }

    get numberOfJoinPaths() {
        return this.treePaths.length;
    }

    setSelectedTreePathIndex(index) {
        this.selectedTreePathIndex = index;
        return this.joinPath;
    }

    getJoinPathAtIndex(pathIndex) {
        const selectedTreePath = this.treePaths[pathIndex];
        if (!selectedTreePath || selectedTreePath.length === 1) return null;
        const path = [];
        for (let i = 1; i < selectedTreePath.length; i++) {
            const previousNode = selectedTreePath[i - 1];
            const currentNode = selectedTreePath[i];
            if (currentNode.direction === 'asc') {
                path.push({
                    table: { id: currentNode.table.id, name: currentNode.table.name },
                    table_property: {
                        id: currentNode.join.from.id,
                        name: currentNode.join.from.name,
                    },
                    join_table: { id: previousNode.table.id, name: previousNode.table.name },
                    join_table_property: {
                        id: currentNode.join.to.id,
                        name: currentNode.join.to.name,
                    },
                });
            } else {
                // desc
                path.push({
                    table: { id: currentNode.table.id, name: currentNode.table.name },
                    table_property: { id: currentNode.join.to.id, name: currentNode.join.to.name },
                    join_table: { id: previousNode.table.id, name: previousNode.table.name },
                    join_table_property: {
                        id: currentNode.join.from.id,
                        name: currentNode.join.from.name,
                    },
                });
            }
        }
        return path;
    }

    findTreePathsToFilterTable(tree, tablePropertyToFind, useGlobalId = false, currentPath = []) {
        if (!tree) {
            return [];
        }

        // add the current tree node to the path
        const pathWithCurrentNode = [
            ...currentPath,
            { table: tree.table, direction: tree.direction, join: tree.join },
        ];

        // tablePropertyToFind table found, return path
        if (useGlobalId && tree.table.global_id === tablePropertyToFind.table.global_id) {
            return [pathWithCurrentNode];
        } else if (!useGlobalId && tree.table.global_id === tablePropertyToFind.table.id) {
            return [pathWithCurrentNode];
        }

        // continue searching in children, if not found
        // flatMap is same as .map(...args).flat()
        const childrenOccurrences = (tree.children || []).flatMap((child) =>
            this.findTreePathsToFilterTable(
                child,
                tablePropertyToFind,
                useGlobalId,
                pathWithCurrentNode
            )
        );

        return childrenOccurrences;
    }
}

export default TableProperty;

import './CustomDataTable.css';

import { useMemo } from 'react';

const CustomDataTable = ({
    data,
    columns,
    progressPending,
    progressComponent,
    noDataComponent = '',
}) => {
    const cells = useMemo(() => {
        return data.map((row, rowIndex) =>
            columns.map((column, i) => {
                const _style = {
                    flex: `0 0 ${column?.width}`,
                    width: column?.width || '100%',
                    minWidth: column?.minWidth,
                    maxWidth: column?.maxWidth,
                };
                return (
                    <div className="tbl-td" key={`tbl-td-col${i}-row${rowIndex}`} style={_style}>
                        {column.cell(row, rowIndex)}
                    </div>
                );
            })
        );
    }, [data, columns]);

    const Loading = () => {
        if (progressComponent) return progressComponent;
        return 'Loading...';
    };

    return progressPending ? (
        <Loading />
    ) : (
        <div className="tbl-table">
            <div className="tbl-thead">
                <div className="tbl-tr">
                    {columns.map((column, key) => {
                        const _style = {
                            flex: `0 0 ${column?.width}`,
                            width: column?.width || '100px',
                            minWidth: column?.minWidth,
                            maxWidth: column?.maxWidth,
                        };
                        return (
                            <div className="tbl-th" key={key} style={_style}>
                                {column.name}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="tbl-tbody">
                {cells.length === 0 && noDataComponent}
                {cells.map((rowCells, index) => (
                    <div className="tbl-tr" key={index}>
                        {rowCells}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomDataTable;

import './Download.css';

// Libraries
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';
import FieldSelection from '../FieldSelection/FieldSelection';
import LoaderSecondary from 'components/LoaderSecondary/LoaderSecondary';

// Utils
import User from 'utils/user.util';
import { useQuery, GET_SEGMENT } from 'utils/graphql';

// Assets
import { ReactComponent as BackIcon } from 'assets/icons/back_icon_24.svg';
import { formatDate } from 'utils/date.util';
import Button from 'components/Buttons/Button/Button';
import IconButton from 'components/Buttons/IconButton/IconButton';

function Download() {
    let navigate = useNavigate();
    let { segment_id } = useParams();
    const [downloading, setDownloading] = useState(false);
    const [selectedFields, setSelectedFields] = useState([]);
    const [validating, setValidating] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const { data: segmentData } = useQuery(GET_SEGMENT, {
        skip: !segment_id,
        variables: { id: segment_id },
    });

    const handleSelection = (selection) => {
        setSelectedFields(selection);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const download = async () => {
        setDownloading(true);
        const properties = selectedFields.map((field) => {
            return {
                table: field.tableProperty.tableName,
                property: field.tableProperty.propertyName,
                aggregate_function: field.aggregateFunction,
                join_path: field.tableProperty.joinPath
                    ? `${JSON.stringify(field.tableProperty.joinPath)}`
                    : null,
            };
        });

        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/segmentation/exportCsv`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${User.getToken()}`,
                },
                body: JSON.stringify({
                    segmentation_id: segment_id,
                    properties,
                }),
            }
        );

        if (response.status === 200) {
            // Name
            const segmentName = segmentData?.segmentation?.name?.split(' ').join('_') || segment_id;
            const dated = formatDate(new Date(), 'YYYY_MM_DD_HH_mm');

            // Content
            const _blob = await response.blob();

            // Download file
            const _export = window.URL.createObjectURL(new Blob([_blob]));
            const link = document.createElement('a');
            link.setAttribute('href', _export);
            link.setAttribute('download', `${segmentName}_${dated}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setDownloading(false);
        setIsValid(false);
    };

    useEffect(() => {
        if (!isValid) return;
        setValidating(false);
        download();
    }, [isValid]);

    return (
        <div className="download-wrapper">
            <ContentPanel
                title={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className="header-col">
                            Download CSV
                            <div className="sub-header">{segmentData?.segmentation?.name}</div>
                        </div>
                        <IconButton onClick={handleBackClick}>
                            <BackIcon height="24" width="24" fill="var(--font-color)" />
                        </IconButton>
                    </div>
                }
            >
                <div className="download-field-selection">
                    <FieldSelection
                        handleSelection={handleSelection}
                        segmentation={segmentData?.segmentation}
                        validate={validating}
                        setValidate={setValidating}
                        setIsValid={setIsValid}
                    />
                    <div className="download-btn-wrapper">
                        <Button
                            onClick={() => setValidating(true)}
                            isDisabled={downloading}
                            width="300px"
                        >
                            {downloading || validating ? (
                                <LoaderSecondary size="35px" />
                            ) : (
                                'Download'
                            )}
                        </Button>
                    </div>
                </div>
            </ContentPanel>
        </div>
    );
}

export default Download;

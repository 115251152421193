import moment from 'moment';

export function isValidDate(date) {
    if (!(date instanceof Date) || isNaN(date.valueOf())) {
        return false;
    }
    return true;
}

export function formatTimeToUTC(time) {
    if (!time) return;
    const localTime = moment(time, 'HH:mm');
    return localTime.utc().format('HH:mm');
}

export function formatTimeToLocal(time) {
    if (!time) return;
    const timeUtc = moment.utc(time, 'HH:mm');
    return timeUtc.local().format('HH:mm');
}

export function formatToUTCDate(date) {
    if (!isValidDate(date)) {
        throw Error(`formatToUTCDate expects a Date object but got ${typeof date}`);
    }
    return date.toISOString().split('T')[0];
}

export function formatToLocalDate(date) {
    if (!isValidDate(date)) {
        throw Error(`formatToLocalDate expects a Date object but got ${typeof date}`);
    }
    return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
}

export function formatDate(date, format = 'YYYY-MM-DD') {
    return moment(date).format(format);
}

export function getMinute(date) {
    return moment(date).minute();
}

export function getHour(date) {
    return moment(date).hour();
}

export function getDate(date) {
    return moment(date).date();
}

export function getMonth(date) {
    return moment(date).month();
}

import './ContentPanel.css';

import React from 'react';
import PropTypes from 'prop-types';

function ContentPanel({ children, title, width = '100%', style = {}, variant = 'light-grey' }) {
    style = { width, ...style };
    return (
        <div className={`content-panel ${variant}`} style={style}>
            {title && <div className="content-panel-header">{title}</div>}
            <div className="content-panel-content">{children}</div>
        </div>
    );
}

ContentPanel.propTypes = {
    children: PropTypes.node,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    width: PropTypes.string,
    variant: PropTypes.oneOf(['white', 'light-grey']),
};

export default ContentPanel;

import { gql } from './apollo';

export const GET_SETTINGS = gql`
    query {
        settingsList {
            edges {
                node {
                    id
                    setting
                    value
                }
            }
        }
    }
`;

export const GET_SETTING = gql`
    query ($setting: String!, $convertToGlobalIdObject: String) {
        setting(setting: $setting, convertToGlobalIdObject: $convertToGlobalIdObject)
    }
`;

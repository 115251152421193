import Tooltip from 'components/Tooltip/Tooltip';
import './IconButton.css';
import PropTypes from 'prop-types';

const IconButton = ({
    children,
    onClick,
    isDisabled = false,
    type = 'button',
    className = '',
    padding = '10px',
    tooltip = null,
    tooltipWidth = 'auto',
    tooltipPosition = 'bottom-center',
}) => {
    const btn = (
        <button
            className={`icon-btn ${className}`}
            onClick={(e) => {
                onClick && onClick(e);
            }}
            disabled={isDisabled}
            type={type}
            style={{ padding }}
        >
            {children}
        </button>
    );
    return (
        <>
            {tooltip ? (
                <Tooltip tip={tooltip} width={tooltipWidth} position={tooltipPosition}>
                    {btn}
                </Tooltip>
            ) : (
                btn
            )}
        </>
    );
};

IconButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    className: PropTypes.string,
    padding: PropTypes.string,
    tooltip: PropTypes.string,
    tooltipWidth: PropTypes.string,
    tooltipPosition: PropTypes.string,
};

export default IconButton;

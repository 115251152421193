function canPerformAction(permissionList, action) {
    return permissionList.find((p) => p.action === action);
}

export function hasPermissionFor(permissionList, object, action) {
    return Boolean(permissionList.find((p) => p.object === object && p.action === action));
}

export function canCreate(permissionList) {
    return canPerformAction(permissionList, 'create');
}

export function canUpdate(permissionList) {
    return canPerformAction(permissionList, 'update');
}

export function canDelete(permissionList) {
    return canPerformAction(permissionList, 'delete');
}

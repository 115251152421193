import './DraggablePill.css';

// Libraries
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import DragItem from 'views/Segmentation/DragItem/DragItem';

// Assets
import { ReactComponent as DragIcon } from 'assets/icons/drag_indicator_icon.svg';
import { ReactComponent as TextIcon } from 'assets/icons/text_field_icon_24.svg';
import { ReactComponent as NumberIcon } from 'assets/icons/hash_icon_24.svg';
import { ReactComponent as DateIcon } from 'assets/icons/date_field_icon_24.svg';
import { ReactComponent as UuidIcon } from 'assets/icons/fingerprint_icon_24.svg';
import { ReactComponent as FunctionIcon } from 'assets/icons/function_icon_24.svg';
import { ReactComponent as BooleanIcon } from 'assets/icons/toggle_off_icon_24.svg';

const DraggablePill = ({
    data,
    text,
    index,
    type,
    className = '',
    dragHandler = (item, monitor) => {},
    dependencies = [],
}) => {
    const pillRef = useRef(null);

    const getTypeIcon = (type) => {
        let icon = {};
        switch (type) {
            case 'Boolean':
                icon.icon = BooleanIcon;
                break;
            case 'Number':
                icon.icon = NumberIcon;
                break;
            case 'Date':
                icon.icon = DateIcon;
                break;
            case 'UUID':
                icon.icon = UuidIcon;
                break;
            case 'Function':
                icon.icon = FunctionIcon;
                break;
            case 'Text':
            default:
                icon.icon = TextIcon;
                break;
        }
        return <icon.icon fill="var(--dark-grey-color)" height={20} width={20} />;
    };

    /**
     * Drag animation
     */
    useEffect(() => {
        function handleDragStart() {
            this.childNodes[0].classList.add('dragging');
        }

        function handleDragStop() {
            this.childNodes[0].classList.remove('dragging');
        }

        const pillRefElement = pillRef.current;

        if (!pillRefElement) return;
        pillRefElement.addEventListener('dragstart', handleDragStart);
        pillRefElement.addEventListener('dragend', handleDragStop);
        return () => {
            if (!pillRefElement) return;
            pillRefElement.removeEventListener('dragstart', handleDragStart);
            pillRefElement.removeEventListener('dragend', handleDragStop);
        };
    }, []);

    return (
        <DragItem
            innerRef={pillRef}
            type={type}
            index={index}
            data={data}
            dragHandler={dragHandler}
            dependencies={dependencies}
        >
            <div className={`draggable-pill ${className}`}>
                <div className="pill-drag-icon">
                    <DragIcon />
                </div>
                <div className="pill-text">{text}</div>
                {data.type && <div className="pill-type-icon">{getTypeIcon(data.type.type)}</div>}
            </div>
        </DragItem>
    );
};

DraggablePill.propTypes = {
    data: PropTypes.object.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    index: PropTypes.number.isRequired,
    type: PropTypes.string,
    className: PropTypes.string,
    dragHandler: PropTypes.func,
    dependencies: PropTypes.arrayOf(PropTypes.object),
};

export default DraggablePill;

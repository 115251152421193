import './HighlightField.css';

// Libraries
import PropTypes from 'prop-types';

const HighlightField = ({ value, width = 'auto' }) => {
    return (
        <div className="highlight-field" style={{ width }}>
            <div className="field-wrapper">
                <div className="field">
                    <p>{value}</p>
                </div>
                <div className="highlight-wrapper">
                    <div className="highlight"></div>
                </div>
            </div>
        </div>
    );
};

HighlightField.propTypes = {
    value: PropTypes.string,
    width: PropTypes.string,
};

export default HighlightField;

import './Consent.css';

// Libraries
import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Utils
import { ucWords } from 'utils/text.util';
import { formatToUTCDate, isValidDate } from 'utils/date.util';
import {
    GET_SEGMENTS,
    GET_SEGMENTATION_COUNT,
    DELETE_SEGMENT,
    useQuery,
    useLazyQuery,
    useMutation,
} from 'utils/graphql';
import { canCreate, canDelete } from 'utils/permission.util';

// Components
import Search from 'components/Search/Search';
import DataTable from 'components/DataTable/DataTable';
import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader/Loader';
import IconButton from 'components/Buttons/IconButton/IconButton';
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';

// Assets
import { ReactComponent as AddIcon } from 'assets/icons/add_icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_icon.svg';
import { ReactComponent as ErrorIconBig } from 'assets/icons/error_icon.svg';

function Consent() {
    const { permissionSet } = useContext(CurrentPermissionContext);
    let navigate = useNavigate();
    const location = useLocation();
    const [filter, setFilter] = useState('');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState('delete');
    const [segmentToDelete, setSegmentToDelete] = useState();
    const [tableData, setTableData] = useState();
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [perPage, setPerPage] = useState(15);
    const [page, setPage] = useState(1);

    const { data: count, refetch: refreshCount } = useQuery(GET_SEGMENTATION_COUNT, {
        variables: { isConsent: true, name: filter },
    });

    const [getSegments] = useLazyQuery(GET_SEGMENTS, {
        fetchPolicy: 'no-cache',
        variables: {
            isConsent: true,
            offset: 0,
            limit: perPage,
            orderBy: 'modified_at',
            orderByDirection: 'DESC',
        },
        onCompleted: (data) => {
            refreshCount();
            setTableData(data);
            setColumns(getColumns());
            setLoading(false);
        },
    });

    const [deleteSegment] = useMutation(DELETE_SEGMENT);

    const handleNewClick = () => {
        navigate(`/consent/new`);
    };

    const getColumns = () => [
        {
            name: 'Name',
            sortable: false,
            selector: (row) => row.name,
            format: (row) => ucWords(row.name),
        },
        {
            name: 'Modified',
            sortable: false,
            selector: (row) => {
                const last_audit_date = new Date(
                    Math.max(...row?.audits.edges.map((audit) => new Date(audit.node.createdAt)))
                );
                const modified_date = new Date(last_audit_date?.toString() || row.created_at);
                if (!isValidDate(modified_date)) return '';
                return formatToUTCDate(modified_date);
            },
        },
        {
            name: canCreate(permissionSet) && (
                <IconButton onClick={handleNewClick} padding="8px">
                    <AddIcon fill="var(--accent-color)" height="24" width="24" />
                </IconButton>
            ),
            grow: 0,
            sortable: false,
            width: 'auto',
            selector: (row) =>
                canDelete(permissionSet) && (
                    <IconButton
                        padding="8px"
                        onClick={() => {
                            setSegmentToDelete(row);
                            openModal('delete');
                        }}
                    >
                        <DeleteIcon fill="var(--error-color)" height="24" width="24" />
                    </IconButton>
                ),
        },
    ];

    const openModal = (type) => {
        setModalType(type);
        setIsOpen(true);
    };

    const getModalOptions = (type) => {
        switch (type) {
            case 'delete':
                return {
                    type: 'confirmation',
                    title: 'Warning',
                    content: (
                        <div>
                            <p>Are you sure you want to delete this consent? </p>
                            <b>{segmentToDelete?.name}</b>
                        </div>
                    ),
                    confirmBtnText: 'Yes',
                    cancelBtnText: 'No',
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => {
                        deleteSegment({
                            variables: { id: segmentToDelete.id },
                            onCompleted: (response) => {
                                if (response?.deleteSegmentation) {
                                    getSegments({
                                        variables: {
                                            isConsent: true,
                                            search: filter,
                                            offset: perPage * (page - 1),
                                            limit: perPage,
                                        },
                                    });
                                    openModal('deleted');
                                } else {
                                    openModal('error');
                                }
                            },
                        });
                    },
                };
            case 'deleted':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <p>Consent has been deleted.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => navigate('/consent'),
                };
            case 'error':
                return {
                    title: 'Error',
                    content: (
                        <div>
                            <ErrorIconBig fill="var(--error-color)" />
                            <div>
                                <p>Unable to delete consent.</p>
                                <small>
                                    This could be because it is currently associated to a segment.
                                </small>
                            </div>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handleSearch = useCallback(() => {
        setLoading(true);

        window.history.replaceState(
            null,
            '',
            `?query=${encodeURIComponent(filter)}&page=${page}&perPage=${perPage}`
        );

        getSegments({
            variables: { search: filter, offset: perPage * (page - 1), limit: perPage },
        });
    }, [getSegments, filter, page, perPage]);

    const handleSegmentSelect = (row) => {
        navigate(`/consent/${row.id}`);
    };

    /**
     * Execute initial blank search if nothing in url query params
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        if (!query) {
            getSegments();
        }
    }, []);

    /**
     * Update search if any of the dependencies change
     */
    useEffect(() => {
        handleSearch();
    }, [filter, page, perPage]);

    /**
     * Execute search if in url query params
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const query = queryParams.get('query') || '';
        const _page = parseInt(queryParams.get('page') || page);
        const _perPage = parseInt(queryParams.get('perPage') || perPage);

        setFilter(query);
        setPage(_page);
        setPerPage(_perPage);
    }, []);

    return (
        <div className="consent-list">
            <div className="consent-list-header">
                <Search handler={(search) => setFilter(search)} />
            </div>
            <DataTable
                columns={columns}
                data={tableData?.segmentationList.edges.map((row) => {
                    return row.node;
                })}
                pagination={true}
                paginationServer={true}
                paginationTotalRows={count?.segmentationListCount || 0}
                paginationPerPage={perPage}
                paginationDefaultPage={page}
                progressPending={loading}
                noDataComponent="You don't have any consent segments."
                progressComponent={<Loader />}
                defaultSortFieldId={1}
                highlightOnHover={true}
                onRowClicked={handleSegmentSelect}
                onChangeRowsPerPage={handlePerPageChange}
                onChangePage={handlePageChange}
                persistTableHead={true}
                selectableRows={false}
            />
            <Modal options={getModalOptions(modalType)} isOpen={modalIsOpen} setOpen={setIsOpen} />
        </div>
    );
}

export default Consent;

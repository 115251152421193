import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarChart({ title, data, xAxisTitle = null, yAxisTitle = null, innerRef = null }) {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: title,
                text: title,
            },
        },
        scales: {
            x: {
                title: {
                    display: xAxisTitle != null,
                    text: xAxisTitle,
                },
            },
            y: {
                title: {
                    display: yAxisTitle != null,
                    text: yAxisTitle,
                },
            },
        },
    };

    return <Bar options={options} data={data} ref={innerRef} />;
}

BarChart.propTypes = {
    title: PropTypes.string,
    data: PropTypes.object.isRequired,
    xAxisTitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

export default BarChart;

import './Slider.css';

// Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Slider = ({
    min,
    max,
    value,
    onChange,
    width = '150px',
    isFloat = false,
    displayValue = true,
}) => {
    const [sliderValue, setSliderValue] = useState(value);

    useEffect(() => {
        setSliderValue(value);
    }, [value]);

    const handleChange = (event) => {
        const newValue = isFloat
            ? parseFloat(event.target.value).toFixed(2)
            : parseInt(event.target.value, 10);
        setSliderValue(newValue);
        onChange(newValue);
    };

    return (
        <div className="slider" style={{ width }}>
            <input
                className="slider-input"
                type="range"
                min={min}
                max={max}
                step={isFloat ? '0.01' : '1'}
                value={sliderValue}
                onChange={handleChange}
            />
            {displayValue && <span className="slider-value">{sliderValue}</span>}
        </div>
    );
};

Slider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    width: PropTypes.string,
    isFloat: PropTypes.bool,
    displayValue: PropTypes.bool,
};

export default Slider;

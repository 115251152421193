import './Accordion.css';

// Libraries
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import Loader from 'components/Loader/Loader';

// Assets
import { ReactComponent as ExpandMoreIcon } from 'assets/icons/expand_more_24.svg';
import { ReactComponent as ExpandLessIcon } from 'assets/icons/expand_less_icon_24.svg';

const AccordionItem = ({ header, body, isExpanded = false, padding }) => {
    const [expanded, setExpanded] = useState(isExpanded);
    const [expandedHeight, setExpandedHeight] = useState('0px');
    const contentEl = useRef();

    const handleToggle = () => {
        setExpanded((prev) => !prev);
    };

    useEffect(() => {
        if (!contentEl.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if (!contentEl.current) return;
            // updating the accordion height when the accordion content height changes
            setExpandedHeight(contentEl.current?.scrollHeight);
        });
        resizeObserver.observe(contentEl.current);
        return () => resizeObserver.disconnect();
    }, []);

    return (
        <li className={`accordion-item ${expanded ? 'active' : ''}`}>
            <button className="accordion-item-header" onClick={handleToggle}>
                <div className="accordion-item-btn">
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}{' '}
                </div>
                {header}
            </button>

            <div
                className="accordion-item-body-wrapper"
                style={expanded ? { height: expandedHeight } : { height: '0px' }}
            >
                <div ref={contentEl} className="accordion-item-body" key={body} style={{ padding }}>
                    {body}
                </div>
            </div>
        </li>
    );
};

const Accordion = ({ items, padding = '20px', loading = false }) => {
    return (
        <ul className="accordion">
            {loading ? (
                <div className="accordion-loader">
                    <Loader />
                </div>
            ) : (
                items.map((item, i) => (
                    <AccordionItem
                        key={i}
                        header={item.header}
                        body={item.body}
                        isExpanded={item.isExpanded}
                        padding={padding}
                    />
                ))
            )}
        </ul>
    );
};

Accordion.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            isExpanded: PropTypes.bool,
        })
    ).isRequired,
    padding: PropTypes.string,
    loading: PropTypes.bool,
};

export default Accordion;

import ContentPanel from 'components/ContentPanel/ContentPanel';
import './Dashboard.css';
import welcome from 'assets/images/welcome.svg';

// Libraries
import React from 'react';
import { capFirst } from 'utils/text.util';
import User from 'utils/user.util';

function Dashboard() {
    return (
        <ContentPanel width="1000px">
            <div className="dashboard">
                <div className="welcome-text">Welcome back {capFirst(User.getFirstName())}</div>
                <img className="welcome-img" src={welcome} />
            </div>
        </ContentPanel>
    );
}

export default Dashboard;

import LoaderSecondary from 'components/LoaderSecondary/LoaderSecondary';
import './LoadingIconButton.css';
import IconButton from '../IconButton/IconButton';
import PropTypes from 'prop-types';

const LoadingIconButton = ({
    children,
    isLoading,
    onClick,
    isDisabled = false,
    type = 'button',
    className = '',
    tooltip,
    loaderColor = 'light',
}) => {
    return (
        <div className={`loading-icon-btn ${className}`}>
            {isLoading && <LoaderSecondary color={loaderColor} size="20px" className="loader" />}
            <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                <IconButton
                    isDisabled={isLoading || isDisabled}
                    onClick={onClick}
                    type={type}
                    tooltip={tooltip}
                >
                    {children}
                </IconButton>
            </span>
        </div>
    );
};

LoadingIconButton.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    className: PropTypes.string,
    tooltip: PropTypes.string,
    loaderColor: PropTypes.oneOf(['light', 'dark', 'accent']),
};

export default LoadingIconButton;

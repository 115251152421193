import './Nav.css';

// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PermissionsContext } from 'App';

// Components
import Button from 'components/Buttons/Button/Button';

// Assets
import { ReactComponent as AppsIcon } from 'assets/icons/apps_icon.svg';
import { ReactComponent as ConsentIcon } from 'assets/icons/consent_icon.svg';
import { ReactComponent as DatabaseIcon } from 'assets/icons/database_icon.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/download_icon.svg';
import { ReactComponent as SegmentationIcon } from 'assets/icons/segmentation_icon.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/expand_sidebar.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/group_icon.svg';
import { ReactComponent as CollapseIcon } from 'assets/icons/collapse_sidebar.svg';
import { ReactComponent as InsightsIcon } from 'assets/icons/insights_icon_24.svg';

// Utils & Hooks
import { capFirst } from 'utils/text.util';
import { useNavCollapse, useNavCollapseUpdate } from 'contexts/NavCollapseContext';
import useScreenResize from 'hooks/useScreenResize';

function Nav() {
    let navigate = useNavigate();
    const { pathname } = useLocation();
    const [selected, setSelected] = useState();
    const { permissions } = useContext(PermissionsContext);
    const [isToggleBtnSticky, setIsToggleBtnSticky] = useState(true);
    const navCollapsed = useNavCollapse();
    const toggleNavCollapsed = useNavCollapseUpdate();
    const navRef = useScreenResize(() => {
        if (navRef.current) {
            setIsToggleBtnSticky(navRef.current.getBoundingClientRect().height > 600);
        }
    });

    const handleNav = (selection) => {
        setSelected(selection);
        const path = selection.path ? selection.path : selection.name;
        navigate(`/${path}`, { replace: true });
    };

    const renderNavItems = () => {
        let routes = [
            { name: 'apps', icon: AppsIcon },
            { name: 'consent', icon: ConsentIcon },
            { name: 'database', icon: DatabaseIcon },
            // { name: 'import', icon: ImportIcon },
            { name: 'segmentation', icon: SegmentationIcon },
            { name: 'kpis', icon: InsightsIcon },
            { name: 'unify', icon: GroupIcon },
        ];

        // Check nav menu page permission
        routes = routes.filter((route) => {
            let hasPermission = false;
            permissions?.forEach((permission) => {
                if (
                    permission.action === 'read' &&
                    ((route.path && permission.object.includes(route.path)) ||
                        permission.object.includes(route.name))
                ) {
                    return (hasPermission = true);
                }
            });

            if (hasPermission) return route;
        });

        return routes.map((route, i) => {
            return (
                <li key={i}>
                    <div
                        className={'link' + `${selected?.name === route.name ? ' selected' : ''}`}
                        onClick={() => handleNav(route)}
                    >
                        <div className="icon">
                            <route.icon
                                fill={
                                    selected?.name === route.name
                                        ? 'var(--accent-color)'
                                        : 'var(--dark-grey-color)'
                                }
                                className="icon"
                            />
                        </div>
                        <div className="title">
                            <p>{capFirst(route.name)}</p>
                        </div>
                        <div className="selection-highlight">
                            <span></span>
                        </div>
                    </div>
                </li>
            );
        });
    };

    const renderNavToggleBtn = () => {
        return (
            <Button onClick={toggleNavCollapsed} width="80px">
                {navCollapsed ? <ExpandIcon fill="#FFF" /> : <CollapseIcon fill="#FFF" />}
            </Button>
        );
    };

    useEffect(() => {
        // Update nav selection based on route
        const route = pathname.substring(1);
        setSelected({ name: route });
    }, [pathname]);

    return (
        <div className={'nav-wrapper' + `${navCollapsed ? ' collapsed' : ''}`} ref={navRef}>
            <ul>
                {renderNavItems()}
                {!isToggleBtnSticky && <li className="collapse-toggle">{renderNavToggleBtn()}</li>}
            </ul>
            {isToggleBtnSticky && (
                <div className="sticky-collapse-toggle">{renderNavToggleBtn()}</div>
            )}
        </div>
    );
}

export default Nav;

import './UserForm.css';

// Libraries
import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, GET_ROLES, UPDATE_USER, CREATE_USER } from 'utils/graphql';

// Components
import Modal from 'components/Modal/Modal';
import Tooltip from 'components/Tooltip/Tooltip';
import LoaderSecondary from 'components/LoaderSecondary/LoaderSecondary';

// Utils
import User from 'utils/user.util';
import { isEmailValid } from 'utils/validation.util';

// Asset
import { ReactComponent as CheckIcon } from 'assets/icons/check_icon.svg';
import { ReactComponent as SmallCheckIcon } from 'assets/icons/check_icon_24.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon.svg';
import Button from 'components/Buttons/Button/Button';
import Input from 'components/Form/Input/Input';
import Dropdown from 'components/Form/Dropdown/Dropdown';

function UserForm({ data, isNew = false }) {
    const [id, setId] = useState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [activationKey, setActivationKey] = useState();
    const [email, setEmail] = useState('');
    const [role, setRole] = useState();
    const [active, setActive] = useState(false);
    const [roles, setRoles] = useState([]);
    const [modalType, setModalType] = useState();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [refreshingToken, setRefreshingToken] = useState(false);
    const [tokenRefreshed, setTokenRefreshed] = useState(false);
    const [errors, setErrors] = useState({ firstName: '', lastName: '', email: '' });

    const [updateUser, { loading: updating }] = useMutation(UPDATE_USER);

    const [createUser, { loading: creating }] = useMutation(CREATE_USER);

    let isLoading = creating || updating;

    useQuery(GET_ROLES, {
        variables: { offset: 0, limit: 99 },
        onCompleted: (results) => {
            setRoles(results?.roleList.edges.map((row) => row.node));
        },
    });

    const handleResend = () => {
        setRefreshingToken(true);
        fetch(process.env.REACT_APP_BACKEND_URL + '/api/user/refresh_activation_key', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + User.getToken(),
            },
            body: JSON.stringify({
                user_id: id,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res?.success) {
                    setTokenRefreshed(true);
                }
                setRefreshingToken(false);
            })
            .catch((err) => {
                console.log(err);
                setRefreshingToken(false);
            });
    };

    const validate = () => {
        const _errors = { firstName: '', lastName: '', email: '' };
        let isValid = true;

        if (!firstName || firstName.length === 0) {
            _errors.firstName = 'First name is required.';
            isValid = false;
        }

        if (!lastName || lastName.length === 0) {
            _errors.lastName = 'Last name is required.';
            isValid = false;
        }

        if (!email || email.length === 0) {
            _errors.email = 'Email is required.';
            isValid = false;
        } else if (!isEmailValid(email)) {
            _errors.email = 'Must be a valid email address.';
            isValid = false;
        }

        setErrors(_errors);
        return isValid;
    };

    const handleSubmit = () => {
        if (!validate()) return;
        if (isNew) {
            createUser({
                variables: {
                    email: email,
                    username: username,
                    firstname: firstName,
                    lastname: lastName,
                    roleid: role,
                    active: false,
                },
                onCompleted: (response) => {
                    if (!response.createUser) {
                        setModalType('error');
                    } else {
                        setModalType('success');
                        setTimeout(() => {
                            window.location.href = '/settings/users';
                        }, 2000);
                    }
                    setIsOpen(true);
                },
                onError: (error) => {
                    setModalType('error');
                    setIsOpen(true);
                },
            });
        } else {
            updateUser({
                variables: {
                    id,
                    firstname: firstName,
                    lastname: lastName,
                    roleid: role,
                    active,
                },
                onCompleted: (response) => {
                    if (!response.updateUser) {
                        setModalType('error');
                    } else {
                        setModalType('success');
                    }
                    setIsOpen(true);
                },
                onError: (error) => {
                    setModalType('error');
                    setIsOpen(true);
                },
            });
        }
    };

    const getModalOptions = (type) => {
        switch (type) {
            case 'success':
                return {
                    title: 'Success',
                    content: <CheckIcon fill="green" />,
                    width: '250px',
                    textAlign: 'center',
                };
            case 'error':
                return {
                    title: 'Error',
                    content: <ErrorIcon fill="red" />,
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                break;
        }
    };

    /**
     * Set values for existing user
     */
    useEffect(() => {
        if (isNew) return;
        setId(data.id);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setUsername(data.username);
        setEmail(data.email);
        setRole(data.role.id);
        setActive(data.active);
        setActivationKey(data.activationKey);
    }, [data]);

    /**
     * Set default Role for new user
     */
    useEffect(() => {
        if (!isNew || roles.length === 0) return;
        const userRole = roles.find((_role) =>
            [('user', 'member')].includes(_role.name.toLowerCase())
        );
        if (!userRole) return;
        setRole(userRole.id);
    }, [roles]);

    return (
        <>
            <form
                className="user-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <div className="user-form-item with-error">
                    <p className="user-input-title">First Name</p>
                    <Input
                        placeholder="First Name"
                        value={firstName}
                        setValue={(e) => {
                            setFirstName(e.target.value);
                        }}
                        width="250px"
                        error={errors.firstName}
                        displayError={true}
                    />
                </div>
                <div className="user-form-item with-error">
                    <p className="user-input-title">Last Name</p>
                    <Input
                        placeholder="Last Name"
                        value={lastName}
                        setValue={(e) => {
                            setLastName(e.target.value);
                        }}
                        width="250px"
                        error={errors.lastName}
                        displayError={true}
                    />
                </div>
                <div className="user-form-item with-error">
                    <p className="user-input-title">Email</p>
                    <Input
                        placeholder="Email"
                        value={email}
                        setValue={(e) => {
                            setEmail(e.target.value);
                            setUsername(e.target.value);
                        }}
                        readOnly={!isNew}
                        width="250px"
                        error={errors.email}
                        displayError={true}
                    />
                </div>
                <div className="user-form-item">
                    <p className="user-input-title">Username</p>
                    <Input placeholder="Username" value={username} readOnly={true} width="250px" />
                </div>
                <div className="user-form-item">
                    <p className="user-input-title">Role</p>
                    <Dropdown
                        value={role || ''}
                        values={roles.map((role) => ({ text: role.name, value: role.id }))}
                        setValue={(e) => setRole(e.target.value)}
                        width="250px"
                    />
                </div>
                <div className="user-form-item">
                    <p className="user-input-title">Active</p>
                    <Dropdown
                        value={active}
                        values={[
                            { text: 'True', value: true },
                            { text: 'False', value: false },
                        ]}
                        setValue={(e) => {
                            setActive(e.target.value === 'true');
                        }}
                        width="250px"
                        readOnly={isNew || activationKey}
                    />
                </div>
                {activationKey && (
                    <div className="user-form-item">
                        <p className="user-input-title activation-title">Pending Activation</p>
                        <Tooltip
                            tip="This will send a new email with a new activation key and invalidate any previous activation keys."
                            width="250px"
                            isDisabled={refreshingToken || tokenRefreshed}
                            position="top-center"
                        >
                            <Button
                                variant="secondary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleResend();
                                }}
                                isDisabled={refreshingToken || tokenRefreshed}
                            >
                                {(() => {
                                    if (refreshingToken) {
                                        return <LoaderSecondary size="30px" color="dark" />;
                                    } else if (tokenRefreshed) {
                                        return <SmallCheckIcon fill="#000" />;
                                    } else {
                                        return 'Refresh';
                                    }
                                })()}
                            </Button>
                        </Tooltip>
                    </div>
                )}
                <div className="btn-row">
                    <Button type="submit" disabled={isLoading}>
                        {isLoading ? <LoaderSecondary size="35px" /> : isNew ? 'Create' : 'Update'}
                    </Button>
                </div>
            </form>
            <Modal options={getModalOptions(modalType)} isOpen={modalIsOpen} setOpen={setIsOpen} />
        </>
    );
}

export default UserForm;

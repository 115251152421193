import './Input.css';

// Libraries
import { useEffect, useState, useRef, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

// Assets
import { ReactComponent as EditIcon } from 'assets/icons/edit_icon.svg';

// Components
import InputError from '../InputError/InputError';

const Input = memo(
    ({
        value,
        setValue,
        min,
        max,
        type = 'text',
        placeholder = '',
        readOnly = false,
        required = false,
        disabled = false,
        editIcon = false,
        height = '45px',
        width = '100%',
        minWidth = '100px',
        maxWidth,
        marginBottom = '0',
        label = null,
        error = null,
        displayError = false,
        variant = 'default',
        inputIcon = null,
        autoComplete = null,
    }) => {
        const containerRef = useRef(null);
        const [selectionStart, setSelectionStart] = useState(null);
        const [selectionEnd, setSelectionEnd] = useState(null);
        const [editable, setEditable] = useState(disabled);
        const options = {
            checked: Boolean(value) == true ? 'checked' : '',
            disabled: Boolean(!editable),
            required,
            readOnly,
            placeholder,
            autoComplete,
            type,
            value,
            min,
            max,
        };

        // Track cursor position
        const handleSelectionChange = useCallback(
            (e) => {
                setSelectionStart(e.target.selectionStart);
                setSelectionEnd(e.target.selectionEnd);
            },
            [selectionStart, selectionEnd]
        );

        let _class = `form-input ${variant}`;
        if (editIcon) {
            _class += editable ? ' editable' : ' non-editable';
        }
        if (disabled || readOnly) {
            _class += ' disabled';
        }

        useEffect(() => {
            if (editIcon === true) {
                setEditable(false);
            } else {
                setEditable(true);
            }
        }, [editIcon]);

        useEffect(() => {
            const handleClickOutside = (event) => {
                if (containerRef.current && !containerRef.current.contains(event.target)) {
                    // Clicked outside of the component
                    if (editIcon === true) {
                        setEditable(false);
                    }
                }
            };

            // Attach the event listener on component mount
            document.addEventListener('click', handleClickOutside);

            // Clean up the event listener on component unmount
            return () => {
                document.removeEventListener('click', handleClickOutside);
            };
        }, []);

        return (
            <div
                className="input"
                style={{ width, minWidth, maxWidth, marginBottom }}
                ref={containerRef}
            >
                {label && <div className="label">{label}</div>}
                <div className={_class} style={{ height }}>
                    {inputIcon && <div className="form-input-icon">{inputIcon}</div>}
                    <input
                        {...options}
                        className={disabled || readOnly ? 'disabled' : ''}
                        onChange={(e) => {
                            if (disabled || readOnly) return;
                            handleSelectionChange(e);
                            setValue(e);
                        }}
                        ref={(input) => {
                            if (
                                input &&
                                selectionStart !== null &&
                                selectionEnd !== null &&
                                !['checkbox', 'number', 'date', 'time'].includes(type)
                            ) {
                                input.setSelectionRange(selectionStart, selectionEnd);
                            }
                        }}
                    />
                    {editIcon && (
                        <div className="form-input-edit" onClick={() => setEditable(!editable)}>
                            <EditIcon />
                        </div>
                    )}
                </div>
                {displayError && <InputError error={error} />}
            </div>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.type === nextProps.type &&
            prevProps.value === nextProps.value &&
            prevProps.disabled === nextProps.disabled &&
            prevProps.readOnly === nextProps.readOnly &&
            prevProps.error === nextProps.error
        );
    }
);

Input.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    setValue: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    editIcon: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    marginBottom: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    displayError: PropTypes.bool,
    variant: PropTypes.oneOf(['default', 'light']),
    inputIcon: PropTypes.node,
};

export default Input;

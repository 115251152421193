import './index.css';

// Libraries
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// Components & Views
import App from './App';
import Forgot from 'views/Forgot/Forgot';
import Reset from 'views/Forgot/Reset/Reset';
import Logout from 'views/Logout/Logout';
import Activate from 'views/Activate/Activate';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                {/* Public routes */}
                <Route
                    path="/forgot"
                    element={
                        <div className="public-wrapper">
                            <Forgot />
                        </div>
                    }
                ></Route>
                <Route
                    path="/resetpassword"
                    element={
                        <div className="public-wrapper">
                            <Reset />
                        </div>
                    }
                ></Route>
                <Route
                    path="/activate"
                    element={
                        <div className="public-wrapper">
                            <Activate />
                        </div>
                    }
                ></Route>
                <Route
                    path="/logout"
                    element={
                        <Logout
                            setToken={() => {
                                localStorage.removeItem('okzou-cdp-token');
                                return null;
                            }}
                        />
                    }
                ></Route>
                <Route path="/login" element={<Navigate to="/" replace />} />
                {/* Private routes */}
                <Route path="/*" element={<App />}></Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

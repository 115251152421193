import { gql } from './apollo';

export const GET_USERS = gql`
    query ($offset: Int, $limit: Int) {
        userList(offset: $offset, limit: $limit) {
            edges {
                node {
                    id
                    username
                    firstName
                    lastName
                    email
                    active
                    activationKey
                    role {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const CREATE_USER = gql`
    mutation (
        $firstname: String
        $lastname: String
        $username: String!
        $email: String!
        $password: String
        $roleid: String!
        $active: Boolean
    ) {
        createUser(
            data: {
                firstName: $firstname
                lastName: $lastname
                username: $username
                email: $email
                password: $password
                roleId: $roleid
                active: $active
            }
        ) {
            user {
                id
            }
        }
    }
`;

export const UPDATE_USER = gql`
    mutation (
        $id: ID!
        $firstname: String
        $lastname: String
        $email: String
        $password: String
        $roleid: String
        $active: Boolean
    ) {
        updateUser(
            data: {
                id: $id
                firstName: $firstname
                lastName: $lastname
                email: $email
                password: $password
                roleId: $roleid
                active: $active
            }
        ) {
            user {
                id
            }
        }
    }
`;

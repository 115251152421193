import './Users.css';

// Libraries
import React, { useState } from 'react';
import { useQuery, GET_USERS } from 'utils/graphql';

// Components
import DataTable from 'components/DataTable/DataTable';
import Search from 'components/Search/Search';
import UserForm from './UserForm/UserForm';

// Assets
import { ReactComponent as PlusIcon } from 'assets/icons/plus_icon.svg';

function Users() {
    const [filter, setFilter] = useState('');
    const { loading, data } = useQuery(GET_USERS, {
        variables: { offset: 0, limit: 99 },
    });
    const [expandedUser, setExpandedUser] = useState(null);

    const columns = [
        {
            name: 'Email',
            sortable: true,
            selector: (row) => row.email,
        },
        {
            name: 'Username',
            sortable: true,
            selector: (row) => row.username,
        },
        {
            name: 'Active',
            sortable: true,
            selector: (row) => row.active.toString(),
        },
        {
            name: 'Role',
            sortable: true,
            selector: (row) => row.role.name,
        },
    ];

    const handleSearch = (search) => {
        setFilter(search);
    };

    return (
        <div className="users-wrapper">
            <div className="users-header">
                <div>
                    <Search handler={handleSearch} delay={500} />
                </div>
            </div>
            <div className="users-content">
                <DataTable
                    columns={columns}
                    data={data?.userList.edges
                        .map((row) => row.node)
                        .filter(
                            (item) =>
                                item.email &&
                                item.email.toLowerCase().includes(filter.toLowerCase())
                        )}
                    pagination
                    progressPending={loading}
                    expandableRows
                    expandableRowExpanded={(row) => row === expandedUser}
                    expandOnRowClicked={true}
                    onRowClicked={(row) => setExpandedUser(row)}
                    onRowExpandToggled={(bool, row) => setExpandedUser(row)}
                    expandableRowsComponent={UserForm}
                    headerBackground={false}
                />
            </div>
            <div className="new-user">
                <div className="new-user-header">New User</div>
                <UserForm isNew={true} />
            </div>
        </div>
    );
}

export default Users;

import './Apps.css';

// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from 'utils/rest/request';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import CardList from 'components/CardList/CardList';
import IconButton from 'components/Buttons/IconButton/IconButton';
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';

// Assets
import { ReactComponent as AddIcon } from 'assets/icons/add_icon.svg';
import { ReactComponent as ImportIcon } from 'assets/icons/download_icon.svg';
import { ReactComponent as MoreVerticalIcon } from 'assets/icons/more_vertical_icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';

// Utils
import { canCreate, canUpdate } from 'utils/permission.util';

function Apps() {
    const { permissionSet } = useContext(CurrentPermissionContext);
    let navigate = useNavigate();
    const [apps, setApps] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();

    // @Todo: Add more. Ideally programatically
    const appColors = [
        'FDDFDF',
        'FCF7DE',
        'DEFDE0',
        'DEF3FD',
        'F0DEFD',
        'E0BBE4',
        '957DAD',
        'D291BC',
        'FEC8D8',
        '83C9F4',
        'A4243B',
        'FFF275',
        '9AD4D6',
        'F86624',
    ];

    const { data, loading } = useFetch('/api/apps/fetch', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const getModalOptions = (type) => {
        switch (type) {
            case 'import-error':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: (
                        <div>
                            <p>This feature is currently unavailable.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'create-warning':
                return {
                    type: 'confirmation',
                    title: 'Warning',
                    content: (
                        <div>
                            <p>
                                Creating a new app is a developer feature and should be used with
                                caution.
                            </p>
                        </div>
                    ),
                    confirmBtnText: 'Proceed',
                    cancelBtnText: 'Stay',
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: () => {
                        navigate(`/apps/new`);
                    },
                };
            default:
                return {};
        }
    };

    const handleImport = () => {
        navigate('/apps/import');
    };

    const handleNewSelect = () => {
        setModalType('create-warning');
        setIsOpen(true);
    };

    const handleAppSelect = (app) => {
        navigate(`/apps/${app.id}`);
    };

    const showAppMenu = (index, show = true) => {
        setApps(apps.map((app, i) => (i === index ? { ...app, isMenuOpen: show } : app)));
    };

    useEffect(() => {
        if (!data) return;

        // const nameIndex = [];
        setApps(
            data
                .filter((_app) => _app.is_active)
                .sort((a, b) => {
                    if (a.app_name < b.app_name) {
                        return -1;
                    }
                    if (a.app_name < b.app_name) {
                        return 1;
                    }
                    return 0;
                })
                .map((_app, i) => {
                    if (_app.is_active) {
                        let name =
                            _app.app_name.substr(0, 1).toUpperCase() +
                            _app.app_name.substr(1, 1).toLowerCase();
                        // while (nameIndex.includes(name)) {
                        //     // console.log(nameIndex.includes(name));
                        //     name += '.';
                        // }

                        // nameIndex.push(name);
                        return {
                            ..._app,
                            name,
                            color: appColors[i],
                            isMenuOpen: false,
                        };
                    }

                    return false;
                })
        );
    }, [data]);

    const editApp = (app) => {
        navigate(`/apps/edit/${app.id}`);
    };

    const exportApp = (app) => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/apps/create_zip`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('okzou-cdp-token')}`,
            },
            body: JSON.stringify({
                id: app.id,
            }),
        })
            .then((response) => response.blob())
            .then((blob) => {
                if (blob) {
                    const link = document.createElement('a');
                    link.setAttribute('href', window.URL.createObjectURL(new Blob([blob])));
                    link.setAttribute('download', `${app.app_name} Code.zip`);
                    document.body.appendChild(link);
                    link.click();
                }
            })
            .catch((error) => {
                console.log({ error });
                alert('Oops! Something went wrong');
            });
    };

    return (
        <div className="apps-wrapper">
            <ContentPanel
                title={
                    <div className="apps-header">
                        <div>Apps</div>
                        {canCreate(permissionSet) && (
                            <div className="menu-items">
                                <IconButton onClick={handleNewSelect} tooltip="Create App">
                                    <AddIcon height="24" width="24" />
                                </IconButton>
                                <IconButton onClick={handleImport} tooltip="Import App">
                                    <ImportIcon height="24" width="24" />
                                </IconButton>
                            </div>
                        )}
                    </div>
                }
            >
                {loading ? (
                    <Loader />
                ) : (
                    <div className="apps-list">
                        {apps?.map((app, i) => {
                            return (
                                <div
                                    key={i}
                                    className="apps-app"
                                    style={{ backgroundColor: '#' + app.color }}
                                    onClick={() => handleAppSelect(app)}
                                >
                                    <p style={{ fontSize: 'larger' }}>{app.name}</p>
                                    <small style={{ fontSize: 'xx-small' }}>{app.app_name}</small>
                                    {!app.is_default && (
                                        <>
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    showAppMenu(i);
                                                }}
                                                className="app-menu-btn"
                                                padding="5px"
                                            >
                                                <MoreVerticalIcon height="24" width="24" />
                                            </IconButton>
                                            <CardList
                                                items={(() => {
                                                    let items = [
                                                        {
                                                            name: 'Export',
                                                            clickCallBack: () => {
                                                                showAppMenu(i, false);
                                                                exportApp(app);
                                                            },
                                                        },
                                                    ];

                                                    if (canUpdate(permissionSet)) {
                                                        items.splice(0, 0, {
                                                            name: 'Edit',
                                                            clickCallBack: () => editApp(app),
                                                        });
                                                    }

                                                    return items;
                                                })()}
                                                onClickOutside={() => showAppMenu(i, false)}
                                                getCardItemContent={(item) => {
                                                    return item.name;
                                                }}
                                                show={app.isMenuOpen}
                                                width="80px"
                                                top="40px"
                                                right="5px"
                                                showDivider="all"
                                            />
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </ContentPanel>
            <Modal options={getModalOptions(modalType)} isOpen={modalIsOpen} setOpen={setIsOpen} />
        </div>
    );
}

export default Apps;

import './FieldSelection.css';

// Libraries
import { useEffect, useReducer, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    GET_SEGMENT_TABLE_TREE,
    VALIDATE_SEGMENTATION_QUERY,
    useLazyQuery,
    useQuery,
} from 'utils/graphql';
import { v4 as uuidv4 } from 'uuid';

// Components
import DropArea from '../DropArea/DropArea';
import Loader from 'components/Loader/Loader';
import DraggablePill from 'components/DraggablePill/DraggablePill';
import IconButton from 'components/Buttons/IconButton/IconButton';
import Modal from 'components/Modal/Modal';
import Alert from 'components/Alert/Alert';
import SideMenu from 'components/SideMenu/SideMenu';
import Accordion from 'components/Accordion/Accordion';
import Tooltip from 'components/Tooltip/Tooltip';
import SelectedTablePropertyPill from 'components/Segment/SelectedTablePropertyPill/SelectedTablePropertyPill';

// Models
import SelectedTableProperty from '../models/selectedTableProperty';

// Assets
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon_24.svg';
import { ReactComponent as ErrorIconBig } from 'assets/icons/error_icon.svg';
import { ReactComponent as StarIcon } from 'assets/icons/star_icon_24.svg';

// utils
import { loadQueryFilterGroup, getTableArrayFromTableTree } from 'utils/segment.util';

const sortPropertiesByTableAndPropertyName = (primaryTableId) => (a, b) => {
    const aTable = a.tableProperty.tableProperty.table;
    const bTable = b.tableProperty.tableProperty.table;
    if (aTable.id === bTable.id) {
        // if tables are the same, sort by table property name
        const aProperty = a.tableProperty.tableProperty.property;
        const bProperty = b.tableProperty.tableProperty.property;
        if (aProperty === 'id') {
            return -1;
        } else if (bProperty === 'id') {
            return 1;
        } else if (aProperty < bProperty) {
            return -1;
        } else if (aProperty > bProperty) {
            return 1;
        }
        return 0;
    } else if (aTable.global_id === primaryTableId) {
        return -1;
    } else if (bTable.global_id === primaryTableId) {
        return 1;
    }
    const aTableName = aTable.name.toLowerCase();
    const bTableName = bTable.name.toLowerCase();
    if (aTableName < bTableName) {
        return -1;
    } else if (aTableName > bTableName) {
        return 1;
    }
    return 0;
};

const sortPropertiesByPropertyNameWithIdHighestPriority = () => (a, b) => {
    const aProperty = a['property'];
    const bProperty = b['property'];
    if (aProperty === 'id') {
        return -1;
    } else if (bProperty === 'id') {
        return 1;
    } else if (aProperty < bProperty) {
        return -1;
    } else if (aProperty > bProperty) {
        return 1;
    }
    return 0;
};

const sortTables = (primaryTableId) => (a, b) => {
    if (a.global_id === primaryTableId) {
        return -1;
    } else if (b.global_id === primaryTableId) {
        return 1;
    }
    const aTableName = a.name.toLowerCase();
    const bTableName = b.name.toLowerCase();
    if (aTableName < bTableName) {
        return -1;
    } else if (aTableName > bTableName) {
        return 1;
    }
    return 0;
};

function FieldSelection({
    segmentation,
    handleSelection = () => {},
    requiredFields = [],
    initSelectedFields = [],
    validate = false,
    setValidate,
    setIsValid,
}) {
    const [primaryTableTree, setPrimaryTableTree] = useState();
    const [joinTables, setJoinTables] = useReducer((currentState, newState) => {
        // ordering the tables
        const orderedTables = newState.sort(sortTables(segmentation.primaryTable.id));
        // ordering the fields for each table
        for (let table of orderedTables) {
            table.properties = table.properties.sort(
                sortPropertiesByPropertyNameWithIdHighestPriority()
            );
        }
        return orderedTables;
    }, []);
    const [selectedFields, setSelectedFields] = useReducer((currentState, newState) => {
        return newState.sort(sortPropertiesByTableAndPropertyName(segmentation.primaryTable.id));
    }, []);
    const [selectedReqFieldMap, setSelectedReqFieldMap] = useState([]);
    const [hasRequiredFields, setHasRequiredFields] = useState(false);
    const [modalOptions, setModalOptions] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFieldsError, setSelectedFieldsError] = useState('');
    const [selectFieldItems, setSelectFieldItems] = useState([]);
    const [includeFilterGroup, setIncludeFilterGroup] = useState();
    const [excludeFilterGroup, setExcludeFilterGroup] = useState();

    const { data: tableTree, loading: loadingTableTree } = useQuery(GET_SEGMENT_TABLE_TREE, {
        skip: !segmentation?.primaryTable.id,
        variables: { tableId: segmentation?.primaryTable.id },
    });

    const [validateSegmentation] = useLazyQuery(VALIDATE_SEGMENTATION_QUERY, {
        variables: {
            segmentationId: segmentation?.id,
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const addSelectedField = (item) => {
        const _joinTables = [...joinTables];
        const _selectedFields = [...selectedFields];
        const table = _joinTables.find((joinTable) => joinTable.id === item.data.table.id);

        let mergedFields = _selectedFields;
        if (item.type === 'all_fields') {
            mergedFields = _selectedFields.concat(
                table.properties.map(
                    (property) => new SelectedTableProperty(property, primaryTableTree)
                )
            );
            table.properties = [];
        } else {
            const propertyIndex = table.properties.findIndex((_prop) => _prop.id === item.data.id);
            const selectedTableProperty = new SelectedTableProperty(
                table.properties.splice(propertyIndex, 1)[0],
                primaryTableTree
            );
            mergedFields = _selectedFields.concat(selectedTableProperty);
        }

        setSelectedFields(mergedFields);
        setJoinTables(_joinTables);
    };

    const validateSelectedFields = async () => {
        setSelectedFieldsError('');
        // validating required fields
        if (requiredFields && requiredFields.length > 0) {
            let requiredFieldErrors = false;
            const _selectedReqFieldMap = [...selectedReqFieldMap];
            for (let requiredField of requiredFields) {
                const selectedReqField =
                    _selectedReqFieldMap.find((req) => req.requiredFieldId === requiredField.id) ||
                    {};
                if (!selectedReqField?.selectedField) {
                    selectedReqField.hasError = true;
                    requiredFieldErrors = true;
                }
            }
            if (requiredFieldErrors) {
                setSelectedReqFieldMap(_selectedReqFieldMap);
                setValidate(false);
                setSelectedFieldsError('The highlighted fields below are required.');
                return;
            }
        } else if (selectedFields.length === 0) {
            setSelectedFieldsError('Please select at least one field to proceed.');
            setValidate(false);
            return;
        }

        const aggregateFields = [];
        const regularFields = [];
        let errors = false;
        for (let field of selectedFields) {
            // resetting all selected field errors
            field.hasError = false;
            if (field.aggregateFunction) {
                aggregateFields.push(field);
            } else {
                regularFields.push(field);
            }
        }

        for (let aggregateField of aggregateFields) {
            for (let regularField of regularFields) {
                if (regularField.tableProperty.tableId === aggregateField.tableProperty.tableId) {
                    aggregateField.hasError = true;
                    regularField.hasError = true;
                    errors = true;
                }
            }
        }

        if (errors) {
            setSelectedFieldsError(
                'Please review the highlighted fields below. You cannot select an aggregate function field and an other field from the same table.'
            );
            setValidate(false);
            return;
        }

        const { data, error } = await validateSegmentation({
            variables: {
                columns: selectedFields.map((field) => ({
                    table: field.tableProperty.tableName,
                    property: field.tableProperty.propertyName,
                    aggregateFunction: field.aggregateFunction,
                    joinPath: field.tableProperty.joinPath
                        ? `${JSON.stringify(field.tableProperty.joinPath)}`
                        : null,
                })),
            },
        });

        if (!data.segmentationQueryValidation.valid) {
            for (let col of data.segmentationQueryValidation.error_columns) {
                const errorField = selectedFields.find(
                    (field) =>
                        field.tableProperty.tableName === col.table &&
                        field.tableProperty.propertyName === col.property
                );
                errorField.hasError = true;
            }
            setSelectedFieldsError(
                'Please review the highlighted fields below. You cannot select a field from a table that already has an aggregate filter from the same table in the segmentation.'
            );
            setValidate(false);
            return;
        }
        let segmentTableIds = [segmentation.primaryTable.id];
        if (includeFilterGroup) {
            segmentTableIds = segmentTableIds.concat(includeFilterGroup.childFilterTableIds);
        }
        if (excludeFilterGroup) {
            segmentTableIds = segmentTableIds.concat(excludeFilterGroup.childFilterTableIds);
        }

        for (let selectedField of selectedFields) {
            const segmentTable = segmentTableIds.find(
                (tableId) => tableId === selectedField.tableProperty.tableGlobalId
            );
            if (!segmentTable) {
                setModalOptions({
                    type: 'confirmation',
                    title: <ErrorIconBig fill="var(--error-color)" />,
                    content:
                        'With these selected table properties, the results might not match the ' +
                        'results when running the segmentation query. Are you sure you want to continue?',
                    confirmBtnText: 'Continue',
                    cancelBtnText: 'Cancel',
                    width: '360px',
                    textAlign: 'center',
                    confirmationBtnAction: () => setIsValid(true),
                });
                setIsModalOpen(true);
                setValidate(false);
                return;
            }
        }
        setIsValid(true);
    };

    const removeRequiredSelectedField = (requiredFieldId, selectedFieldIndex) => {
        const _selectedReqFieldMap = [...selectedReqFieldMap];
        const _selectedReqField = _selectedReqFieldMap.find(
            (f) => f.requiredFieldId === requiredFieldId
        );
        _selectedReqField.selectedField = null;
        setSelectedReqFieldMap(_selectedReqFieldMap);
        removeSelectedField(selectedFieldIndex);
    };

    const removeSelectedField = (index) => {
        const _selectedFields = [...selectedFields];
        const selectedField = _selectedFields.splice(index, 1)[0];
        setSelectedFields(_selectedFields);

        const _joinTables = [...joinTables];
        const table = _joinTables.find(
            (joinTable) => joinTable.id === selectedField.tableProperty.tableId
        );
        table.properties.push(selectedField.tableProperty.tableProperty);
        setJoinTables(_joinTables);
    };

    const removeAllSelectedFields = () => {
        const _joinTables = [...joinTables];
        const tableProperties = selectedFields.map((field) => field.tableProperty.tableProperty);
        for (let tableProperty of tableProperties) {
            const table = _joinTables.find((joinTable) => joinTable.id === tableProperty.table.id);
            table.properties.push(tableProperty);
        }

        setJoinTables(_joinTables);
        setSelectedFields([]);
        setSelectedFieldsError('');
    };

    const updateSelectedFields = () => {
        if (typeof handleSelection !== 'function') return;
        handleSelection(
            selectedFields.map((f) => {
                const req = selectedReqFieldMap.find((req) => req.selectedFieldId === f.propertyId);
                return { ...f, requiredFieldId: req?.requiredFieldId };
            })
        );
    };

    useEffect(() => {
        if (!requiredFields || requiredFields.length === 0) return;
        setHasRequiredFields(true);
    }, [requiredFields]);

    /**
     * Callback to return selected fields to parent.
     */
    useEffect(() => {
        if (typeof handleSelection !== 'function') return;
        handleSelection(
            selectedFields.map((f) => {
                const req = selectedReqFieldMap.find((req) => req.selectedField.id === f.id);
                return { ...f, requiredFieldId: req?.requiredFieldId };
            })
        );
    }, [selectedFields]);

    useEffect(() => {
        if (!initSelectedFields || !primaryTableTree) return;

        // When app has required fields,
        // re-create map of dependencies to selected fields.
        if (requiredFields.length > 0) {
            const _selectedReqFieldMap = requiredFields.reduce((res, req) => {
                const selectedField = initSelectedFields.find((f) => f.dependency_id === req.id);
                const item = {
                    requiredFieldId: req.id,
                    selectedField: selectedField ? selectedField : null,
                    hasError: false,
                };
                res.push(item);
                return res;
            }, []);
            setSelectedReqFieldMap(_selectedReqFieldMap);
        }

        // When an initial selection has been passed
        // setting selected fields and updating join table properties
        if (initSelectedFields?.length > 0) {
            const _joinTables = [...joinTables];
            const _selectedFields = [];

            initSelectedFields.forEach((field) => {
                const table = _joinTables.find((joinTable) => joinTable.id === field.table.id);
                const tablePropIndex = table.properties.findIndex((_prop) => _prop.id === field.id);
                const selectedTableProperty = new SelectedTableProperty(
                    table.properties.splice(tablePropIndex, 1)[0],
                    primaryTableTree,
                    field.join_path,
                    field.aggregate_function
                );
                _selectedFields.push(selectedTableProperty);
            });

            setSelectedFields(_selectedFields);
            setJoinTables(_joinTables);
        }
    }, [initSelectedFields, primaryTableTree]);

    useEffect(() => {
        if (!validate) return;
        validateSelectedFields();
    }, [validate]);

    useEffect(() => {
        if (!joinTables) return;
        const _items = [];
        for (let table of joinTables) {
            _items.push({
                header: (
                    <div className="fields-table-header">
                        {table.name}
                        {table.global_id === segmentation.primaryTable.id && (
                            <Tooltip tip="Primary table" width="150px" position="right-center">
                                <StarIcon fill="var(--accent-color)" />
                            </Tooltip>
                        )}
                    </div>
                ),
                body: (
                    <>
                        <DraggablePill
                            index={0}
                            type="all_fields"
                            data={{ ...table, table: { id: table.id } }}
                            text="All Fields"
                        />
                        {table.properties.map((property, i) => {
                            const pillText =
                                property.property === 'id' ? (
                                    <>
                                        id <span className="cdp-id">(CDP id)</span>
                                    </>
                                ) : (
                                    property.property
                                );
                            return (
                                <DraggablePill
                                    key={i}
                                    index={i + 1}
                                    type="field"
                                    data={property}
                                    text={pillText}
                                    dependencies={[property]}
                                />
                            );
                        })}
                    </>
                ),
            });
        }

        setSelectFieldItems(_items);
    }, [joinTables]);

    useEffect(() => {
        if (!segmentation || !primaryTableTree) return;
        if (segmentation.includeFilterGroup) {
            setIncludeFilterGroup(
                loadQueryFilterGroup(segmentation.includeFilterGroup, primaryTableTree, true)
            );
        }
        if (segmentation.excludeFilterGroup) {
            setExcludeFilterGroup(
                loadQueryFilterGroup(segmentation.excludeFilterGroup, primaryTableTree, true)
            );
        }
    }, [segmentation, primaryTableTree]);

    useEffect(() => {
        if (!tableTree) return;
        setPrimaryTableTree(tableTree.segmentationTableTree);
        const tables = getTableArrayFromTableTree(tableTree.segmentationTableTree);
        setJoinTables(
            tables.map((joinTable) => {
                const _joinTable = { ...joinTable };
                _joinTable.properties = [
                    {
                        id: uuidv4(),
                        property: 'id',
                        table: { ...joinTable },
                        type: { type: 'UUID' },
                    },
                    ..._joinTable.properties,
                ];
                return _joinTable;
            })
        );
    }, [tableTree]);

    return (
        <div className="fieldselection">
            <DndProvider backend={HTML5Backend}>
                <SideMenu header={'Fields'} className="fields-col">
                    {loadingTableTree ? (
                        <Loader />
                    ) : (
                        <Accordion items={selectFieldItems} padding="10px" />
                    )}
                </SideMenu>
                <div className="selected-fields-col">
                    {selectedFieldsError && (
                        <Alert variant="error" width="100%">
                            {selectedFieldsError}
                        </Alert>
                    )}
                    {hasRequiredFields ? (
                        <div className="required-fields">
                            {requiredFields.map((requiredField, i) => {
                                const selectedReqField = selectedReqFieldMap.find(
                                    (req) => req.requiredFieldId === requiredField.id
                                );
                                const selectedFieldIndex = selectedFields.findIndex((f) => {
                                    return (
                                        f?.tableProperty.tableProperty.id ===
                                        selectedReqField?.selectedField.id
                                    );
                                });

                                return (
                                    <div key={i} className="required-field-row">
                                        <p>{requiredField.name}</p>
                                        {selectedFieldIndex >= 0 ? (
                                            <SelectedTablePropertyPill
                                                primaryTableId={segmentation.primaryTable.id}
                                                selectedTableProperty={
                                                    selectedFields[selectedFieldIndex]
                                                }
                                                removeFieldFunc={() =>
                                                    removeRequiredSelectedField(
                                                        selectedReqField.requiredFieldId,
                                                        selectedFieldIndex
                                                    )
                                                }
                                                updateSelectedTableProperty={updateSelectedFields}
                                            />
                                        ) : (
                                            <div
                                                key={i}
                                                className={`required-field-pill ${
                                                    selectedReqField?.hasError
                                                        ? 'required-pill-error'
                                                        : ''
                                                }`}
                                            >
                                                <DropArea
                                                    title="field-selection-da"
                                                    type={['field']}
                                                    dropHandler={(item) => {
                                                        const _selectedReqFieldMap = [
                                                            ...selectedReqFieldMap,
                                                        ];
                                                        const _selectedReqField =
                                                            _selectedReqFieldMap.find(
                                                                (req) =>
                                                                    req.requiredFieldId ===
                                                                    requiredField.id
                                                            );
                                                        _selectedReqField.selectedField = {
                                                            id: item.data.id,
                                                        };
                                                        _selectedReqField.hasError = false;

                                                        setSelectedReqFieldMap(
                                                            _selectedReqFieldMap
                                                        );
                                                        addSelectedField(item);
                                                    }}
                                                ></DropArea>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="selected-fields">
                            <DropArea
                                className="selected-fields-drop-area"
                                type={['field', 'all_fields']}
                                dropHandler={(item) => {
                                    addSelectedField(item);
                                }}
                            >
                                <div className="selections-row">
                                    <div className="selections-col">
                                        {selectedFields.map((field, i) => (
                                            <SelectedTablePropertyPill
                                                key={i}
                                                primaryTableId={segmentation.primaryTable.id}
                                                selectedTableProperty={field}
                                                removeFieldFunc={() => removeSelectedField(i)}
                                                updateSelectedTableProperty={updateSelectedFields}
                                            />
                                        ))}
                                    </div>
                                    {selectedFields.length > 1 && (
                                        <div className="selections-col">
                                            <div className="remove-selections-wrapper">
                                                <IconButton
                                                    onClick={removeAllSelectedFields}
                                                    tooltip="Remove all properties"
                                                    tooltipWidth="100px"
                                                    tooltipPosition="right-center"
                                                >
                                                    <CloseIcon
                                                        width={24}
                                                        height={24}
                                                        fill="var(--error-color)"
                                                    />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </DropArea>
                        </div>
                    )}
                </div>
            </DndProvider>
            <Modal options={modalOptions} isOpen={isModalOpen} setOpen={setIsModalOpen} />
        </div>
    );
}

export default FieldSelection;

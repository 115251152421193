import { client, gql } from './apollo';

const formatProperties = (fields) => {
    let properties = '';
    fields.forEach((field) => {
        properties += `
                {
                    ${field.id ? 'id: "' + field.id + '",' : ''}
                    property: "${field.property}",
                    typeId: "${field.type.value}",
                    nullable: ${field.nullable},
                    unique: ${field.unique},
                    encrypt: ${field.encrypt},
                    ${field.length ? 'length: ' + field.length + ',' : ''}
                    ${
                        field.defaultValue || field.type.text === 'Boolean'
                            ? 'defaultValue: "' + field.defaultValue + '",'
                            : ''
                    }
                    ${field.foreignKey ? 'foreignKey: "' + field.foreignKey + '"' : ''}
                },
            `;
    });
    return properties;
};

export const buildTableSearchFilters = (search, tableProperties) => {
    let filters = [];
    if (search) {
        const isUUID = search?.replaceAll('-', '').length === 32;

        for (const property of tableProperties) {
            if (property?.type?.type === 'Text') {
                filters.push({
                    left: property.property,
                    right: `%${search}%`,
                    expression: 'iLIKE',
                });
            } else if (property?.type?.type === 'UUID' && isUUID) {
                filters.push({
                    left: property.property,
                    right: search,
                    expression: '=',
                });
            }
        }

        if (isUUID) {
            filters.push({
                left: 'id',
                right: search,
                expression: '=',
            });
        }

        // Hack to get no results back when no filters
        if (filters.length === 0) {
            filters = [{ and_: [{ left: 'id', right: 'null', expression: '=' }] }];
        } else {
            filters = [{ or_: filters }];
        }
    }

    return filters;
};

export const GET_TABLE = gql`
    query table(
        $name: String!
        $limit: Int
        $offset: Int
        $orderBy: String
        $orderByDirection: GraphQLDirection
        $filter: [GraphQLFilter]
    ) {
        table(
            name: $name
            limit: $limit
            offset: $offset
            orderBy: $orderBy
            orderByDirection: $orderByDirection
            filter: $filter
        )
    }
`;

export const GET_TABLES = gql`
    query ($name: String, $offset: Int, $limit: Int) {
        tableSchemaList(name: $name, offset: $offset, limit: $limit) {
            totalCount
            edges {
                node {
                    id
                    name
                    isJunction
                }
            }
        }
    }
`;

export const GET_TABLES_COUNT = gql`
    query ($name: String, $offset: Int, $limit: Int) {
        tableSchemaList(name: $name, offset: $offset, limit: $limit) {
            totalCount
        }
    }
`;

export const GET_TABLE_AND_PROPERTIES = gql`
    query tableSchema($id: ID!) {
        tableSchema(id: $id) {
            id
            name
            isJunction
            properties {
                edges {
                    node {
                        id
                        property
                        length
                        defaultValue
                        nullable
                        unique
                        encrypt
                        foreignKey
                        type {
                            id
                            type
                        }
                    }
                }
            }
        }
    }
`;

export const GET_TABLES_AND_PROPERTIES = gql`
    query ($name: String, $offset: Int, $limit: Int) {
        tableSchemaList(name: $name, offset: $offset, limit: $limit) {
            edges {
                node {
                    id
                    name
                    isJunction
                    properties {
                        edges {
                            node {
                                id
                                property
                                length
                                defaultValue
                                nullable
                                unique
                                encrypt
                                foreignKey
                                type {
                                    id
                                    type
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_TABLES_AND_PROPERTIES_WITH_UUIDS = gql`
    query {
        tableList
    }
`;

export const createTable = async (table) => {
    try {
        const properties = formatProperties(table.fields);

        return await client.mutate({
            mutation: gql`
                mutation($name: String!, $isJunction: Boolean!) {
                    createTable(
                        table: {
                            name: $name, 
                            isJunction: $isJunction
                        }, 
                        properties: [
                            ${properties}
                        ]
                    ) {
                        table {
                            id,
                            name
                        }
                    }
                }
            `,
            variables: {
                name: table.name,
                isJunction: table.junction,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const updateTable = async (table) => {
    const properties = formatProperties(table.fields);

    return await client.mutate({
        mutation: gql`
            mutation($id: ID!, $name: String!, $isJunction: Boolean!) {
                updateTable(table: {
                    id: $id,
                    name: $name,
                    isJunction: $isJunction
                },
                properties: [
                    ${properties}
                ]) {
                    table {
                        id,
                        name,
                        isJunction
                    }
                }
            }
        `,
        variables: {
            id: table.id,
            name: table.name,
            isJunction: table.junction,
        },
    });
};

export const deleteTable = async (id) => {
    return await client.mutate({
        mutation: gql`
            mutation ($id: ID!) {
                deleteTable(id: $id) {
                    tableName
                }
            }
        `,
        variables: {
            id: id,
        },
    });
};

export const deleteTableProperty = async (table_id, property_ids) => {
    return await client.mutate({
        mutation: gql`
            mutation ($table_id: ID!, $property_ids: [DeleteTablePropertyID]!) {
                deleteTableColumns(table: $table_id, properties: $property_ids) {
                    tableProperties
                }
            }
        `,
        variables: {
            table_id,
            property_ids,
        },
    });
};

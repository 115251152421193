import './App.css';

// Libraries
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';
import { useFetch } from 'utils/rest/request';

function App() {
    let { app } = useParams();
    const [title, setTitle] = useState('');

    const { data } = useFetch(`/api/apps/fetch/${app}`);

    useEffect(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/apps/${app}`;
        const token = localStorage.getItem('okzou-cdp-token');
        const iframe = document.createElement('iframe');
        iframe.className = 'app-content';

        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.onreadystatechange = handler;
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.send();

        function handler() {
            if (this.readyState === this.DONE) {
                if (this.status === 200) {
                    // this.response is a Blob, because we set responseType above
                    var data_url = URL.createObjectURL(this.response);
                    iframe.src = data_url;
                    document.getElementById('userapp').innerHTML = '';
                    document.getElementById('userapp').appendChild(iframe);
                } else {
                    console.error('counldnt load app');
                }
            }
        }

        window.addEventListener(
            'message',
            (event) => {
                if (event.data && event.data.height) {
                    iframe.style.height = event.data.height;
                }
            },
            false
        );
    }, []);

    useEffect(() => {
        if (!data) return;
        setTitle(data.app_name);
    }, [data]);

    return (
        <ContentPanel title={title}>
            <div id="userapp"></div>
        </ContentPanel>
    );
}

export default App;

import './Dropdown.css';

// Libraries
import PropTypes from 'prop-types';

function Dropdown({
    setValue,
    value,
    values,
    width = '100%',
    minWidth = '100px',
    height = '45px',
    readOnly = false,
}) {
    return (
        <div className="form-select" style={{ width, minWidth }}>
            <label>
                <select value={value} onChange={setValue} style={{ height }} disabled={readOnly}>
                    {values?.map((value, i) => {
                        return (
                            <option key={i} value={value?.value} disabled={value?.disabled}>
                                {value?.text}
                            </option>
                        );
                    })}
                </select>
            </label>
        </div>
    );
}

Dropdown.propTypes = {
    setValue: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    values: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
            text: PropTypes.string,
            disabled: PropTypes.bool,
        })
    ).isRequired,
    width: PropTypes.string,
    minWidth: PropTypes.string,
    height: PropTypes.string,
    readOnly: PropTypes.bool,
};

export default Dropdown;

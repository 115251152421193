import './SingleValue.css';

// Libraries
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// Variables
import { singleValueDisplayTypes } from 'views/Kpis/KpiBuilder/definitions';

const SingleValue = ({
    title,
    numerator,
    denominator = 1,
    displayType = singleValueDisplayTypes.INT,
    maxWidth = 'auto',
    maxHeight = '100%',
}) => {
    const [value, setValue] = useState(0);

    const getSingleValueDisplay = () => {
        switch (displayType) {
            case singleValueDisplayTypes.INT:
                return value;
            case singleValueDisplayTypes.DOLLAR:
                return `$${value}`;
            case singleValueDisplayTypes.FRACTION:
                const num = isNaN(numerator) ? 0 : Math.round(numerator).toLocaleString('en-US');
                const den = isNaN(denominator)
                    ? 0
                    : Math.round(denominator).toLocaleString('en-US');
                return (
                    <div className="fraction">
                        <div className="fraction-num">{num}</div>
                        <div className="fraction-den">{den}</div>
                    </div>
                );
            case singleValueDisplayTypes.PERCENT:
                return `${value}%`;
        }
    };

    useEffect(() => {
        if (!numerator || !denominator) return;
        if (displayType === singleValueDisplayTypes.INT) {
            setValue(Math.round(numerator / denominator).toLocaleString('en-US'));
        } else if (displayType === singleValueDisplayTypes.PERCENT) {
            setValue(Number(((numerator / denominator) * 100).toFixed(2)).toLocaleString('en-US'));
        } else if (displayType === singleValueDisplayTypes.DOLLAR) {
            setValue(
                Number(
                    (Math.round((numerator / denominator) * 100) / 100).toFixed(2)
                ).toLocaleString('en-US')
            );
        }
    }, [numerator, denominator, displayType]);

    return (
        <div className="single-value" style={{ maxHeight, maxWidth }}>
            <h2 className="title">{title}</h2>
            <div className="value">{getSingleValueDisplay()}</div>
        </div>
    );
};

SingleValue.propTypes = {
    title: PropTypes.string,
    displayType: PropTypes.oneOf(Object.values(singleValueDisplayTypes)),
    numerator: PropTypes.number,
    denominator: PropTypes.number,
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string,
};

export default SingleValue;

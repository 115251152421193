import TableProperty from 'views/Segmentation/models/tableProperty';

class SelectedTableProperty {
    constructor(
        tableProperty,
        primaryTableTree,
        joinPath = null,
        aggregateFunction = null,
        isMenuOpen = false
    ) {
        this.tableProperty = new TableProperty(tableProperty, primaryTableTree, joinPath);
        this.isMenuOpen = isMenuOpen;
        this.aggregateFunction = aggregateFunction;
        this.hasError = false;
    }

    get tableId() {
        return this.tableProperty.tableId;
    }

    get tableGlobalId() {
        return this.tableProperty.tableGlobalId;
    }

    get propertyGlobalId() {
        return this.tableProperty.propertyGlobalId;
    }

    get propertyId() {
        return this.tableProperty.propertyId;
    }

    setAggregateFunction(aggregateFunction) {
        this.aggregateFunction = aggregateFunction;
    }
}

export default SelectedTableProperty;

import TableProperty from 'views/Segmentation/models/tableProperty';

class KpiTableAxis {
    constructor(
        table,
        tableProperty = null,
        primaryTableTree = null,
        joinPath = null,
        showAggregateField = false,
        aggregateFunction = '',
        displayName = '',
        showPartitionsField = false,
        numberOfPartitions = 4,
        title = null,
        previewName = null,
        id = null
    ) {
        this.id = id;
        this.table = table;
        this.showAggregateField = showAggregateField;
        this.aggregateFunction = aggregateFunction;
        this.displayName = displayName;
        this.title = title;
        this.previewName = previewName;
        this.showPartitionsField = showPartitionsField;
        this.numberOfPartitions = numberOfPartitions;
        if (!tableProperty) {
            tableProperty = table.properties[0];
        }
        this.tableProperty = new TableProperty(
            tableProperty,
            primaryTableTree,
            joinPath ? joinPath.path : null,
            joinPath ? joinPath.id : null
        );
    }

    get tableId() {
        return this.table.id;
    }

    get tableName() {
        return this.table.name;
    }

    get tableGlobalId() {
        return this.table.global_id;
    }

    get tablePropertyId() {
        return this.tableProperty.propertyId;
    }

    get tablePropertyName() {
        return this.tableProperty.propertyName;
    }

    get tablePropertyType() {
        return this.tableProperty.type;
    }

    get primaryTableTree() {
        return this.tableProperty.primaryTableTree;
    }

    get joinPath() {
        return this.tableProperty.joinPath;
    }

    get joinPathId() {
        return this.tableProperty.joinPathId;
    }

    get numberOfJoinPaths() {
        return this.tableProperty.numberOfJoinPaths;
    }
}

export default KpiTableAxis;

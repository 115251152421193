import './Scheduler.css';

import React, { useImperativeHandle, forwardRef, useState } from 'react';
import _ from 'underscore';

// Components
import Button from 'components/Buttons/Button/Button';
import Input from 'components/Form/Input/Input';
import Dropdown from 'components/Form/Dropdown/Dropdown';

// Utils
import { capFirst } from 'utils/text.util';
import { formatDate, formatTimeToLocal, formatTimeToUTC, getDate, getMonth } from 'utils/date.util';

const ScheduleCreator = forwardRef((props, ref) => {
    const initValues = props?.initValues || {};
    const [preset, setPreset] = useState(initValues?.preset || 'weekly');
    const [repeat, setRepeat] = useState(initValues?.repeat || '');
    const [repeatEveryPeriod, setRepeatEveryPeriod] = useState(
        initValues?.repeat_every_period || 'week'
    );
    const [repeatEveryNumber, setRepeatEveryNumber] = useState(
        initValues?.repeat_every_number || 1
    );
    const [repeatOn, setRepeatOn] = useState(initValues?.repeat_on || ['mon']);
    const [time, setTime] = useState(
        initValues?.time ? formatTimeToLocal(initValues.time) : '00:00'
    );
    const [startDate, setStartDate] = useState(initValues?.start_date || '');
    const [endOption, setEndOption] = useState(initValues?.ends || 'never');
    const [occurrences, setOccurrences] = useState(initValues?.ends_occurences || '');
    const [endDate, setEndDate] = useState(initValues?.ends_specific_date || '');
    const presets = ['daily', 'weekly', 'monthly', 'yearly', 'custom'];

    const initCronExpression = () => {
        return {
            minute: '0',
            hour: '0',
            day_month: '*',
            month: '*',
            day_week: 'mon',
        };
    };

    const [cronExpression, setCronExpression] = useState(initCronExpression());

    const updateCron = () => {
        const _time = formatTimeToUTC(time).split(':');
        const hour = parseInt(_time[0] || 0);
        const minute = parseInt(_time[1] || 0);

        // Default
        let cron = {
            minute,
            hour,
            day_month: '*',
            month: '*',
            day_week: '*',
        };

        switch (preset) {
            case 'daily':
                // Same as default
                break;
            case 'weekly':
                cron = { ...cron, ...{ day_week: repeatOn.join(',') } };
                break;
            case 'monthly':
                cron = { ...cron, ...{ day_month: repeat || getDate(startDate) } };
                break;
            case 'yearly':
                cron = {
                    ...cron,
                    ...{
                        day_month: getDate(startDate),
                        month: getMonth(startDate) + 1,
                    },
                };
                break;
            case 'custom':
                if (repeatEveryPeriod === 'day') {
                    cron = { ...cron, ...{ day_month: `1/${repeatEveryNumber}` } };
                } else if (repeatEveryPeriod === 'week') {
                    cron = { ...cron, ...{ day_week: repeatOn.join(',') } };
                } else if (repeatEveryPeriod === 'month') {
                    cron = {
                        ...cron,
                        ...{
                            day_month: getDate(startDate),
                            month: `*/${repeatEveryNumber}`,
                        },
                    };
                } else if (repeatEveryPeriod === 'year') {
                    cron = {
                        ...cron,
                        ...{
                            day_month: getDate(startDate),
                            month: getMonth(startDate) + 1,
                        },
                    };
                }
                break;
            default:
                break;
        }

        const updatedCron = { ...cronExpression, ...cron };
        setCronExpression(updatedCron);
        return updatedCron;
    };

    const handlePresetChange = (value) => {
        setPreset(value);
    };

    const handleRepeatOnDayChange = (day) => {
        setRepeatOn((prev) =>
            prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
        );
    };

    const handleRepeatChange = (day) => {
        setRepeat((prev) => (prev === day ? '' : day));
    };

    useImperativeHandle(ref, () => ({
        getSelection() {
            const cron = updateCron();

            // Modify '' values to null and make compatible with backend
            let scheduleData = {
                preset: preset || null,
                repeat: repeat || null,
                repeat_on: repeatOn || null,
                repeat_every_number: repeatEveryNumber || null,
                repeat_every_period: repeatEveryPeriod || null,
                time: time ? formatTimeToUTC(time) : null,
                start_date: startDate || null,
                ends: endOption || null,
                ends_occurences: endOption === 'occurrences' ? occurrences : null,
                ends_specific_date: endOption === 'date' ? endDate : null,
            };

            // Only keep preset data. Discard rest of form.
            switch (preset) {
                case 'daily':
                    scheduleData = _.omit(scheduleData, [
                        'repeat',
                        'repeat_on',
                        'repeat_every_number',
                        'repeat_every_period',
                    ]);
                    break;
                case 'weekly':
                    scheduleData = _.omit(scheduleData, [
                        'repeat',
                        'repeat_every_number',
                        'repeat_every_period',
                    ]);
                    break;
                case 'monthly':
                    scheduleData = _.omit(scheduleData, [
                        'repeat_on',
                        'repeat_every_number',
                        'repeat_every_period',
                    ]);
                    break;
                case 'yearly':
                    scheduleData = _.omit(scheduleData, [
                        'repeat',
                        'repeat_on',
                        'repeat_every_number',
                        'repeat_every_period',
                    ]);
                    break;
                case 'custom':
                    scheduleData = _.omit(scheduleData, ['repeat', 'repeat_on']);
                    break;
                default:
                    break;
            }

            return {
                cron,
                form: scheduleData,
            };
        },
    }));

    return (
        <div className="scheduler-wrapper">
            <form>
                <div className="scheduler-presets">
                    {presets.map((_preset, i) => {
                        return (
                            <Button
                                className={`preset-btn ${_preset === preset && 'selected'}`}
                                key={i}
                                onClick={() => handlePresetChange(_preset)}
                            >
                                {capFirst(_preset)}
                            </Button>
                        );
                    })}
                </div>

                {preset === 'weekly' && (
                    <div className="scheduler-selection-row">
                        <div className="scheduler-selection-row-title">Repeat on</div>
                        {['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].map((day, i) => {
                            return (
                                <Button
                                    className={`repeat-on-btn ${
                                        repeatOn.includes(day) && 'selected'
                                    }`}
                                    variant="secondary"
                                    width="70px"
                                    key={i}
                                    onClick={() => handleRepeatOnDayChange(day)}
                                >
                                    {capFirst(day)}
                                </Button>
                            );
                        })}
                    </div>
                )}

                {preset === 'custom' && (
                    <div className="scheduler-selection-row">
                        <div className="scheduler-selection-row-title">Repeat every</div>
                        <div style={{ marginRight: '10px' }}>
                            <Input
                                readOnly={['week', 'year'].includes(repeatEveryPeriod)}
                                type="number"
                                height="38px"
                                width="75px"
                                minWidth="75px"
                                min={1}
                                max={999}
                                value={(() =>
                                    repeatEveryPeriod === 'week' ? 1 : repeatEveryNumber)()}
                                setValue={(e) => setRepeatEveryNumber(e.target.value)}
                                variant="light"
                            />
                        </div>
                        <Dropdown
                            height="38px"
                            values={[
                                { value: 'day', text: 'day' },
                                { value: 'week', text: 'week' },
                                { value: 'month', text: 'month' },
                                { value: 'year', text: 'year' },
                            ]}
                            width="100px"
                            value={repeatEveryPeriod}
                            setValue={(e) => setRepeatEveryPeriod(e.target.value)}
                        />
                    </div>
                )}

                <div className="scheduler-selection-row">
                    <div className="scheduler-selection-row-title">Time</div>
                    <Input
                        type="time"
                        height="38px"
                        width="175px"
                        value={time || '00:00'}
                        setValue={(e) => setTime(e.target.value)}
                        variant="light"
                    />
                </div>

                <div className="scheduler-selection-row">
                    <div className="scheduler-selection-row-title">Start date</div>
                    <Input
                        type="date"
                        height="38px"
                        width="175px"
                        value={startDate || formatDate(new Date())}
                        setValue={(e) => setStartDate(e.target.value)}
                        variant="light"
                    />
                </div>

                {preset === 'monthly' && (
                    <div className="scheduler-selection-row">
                        <div className="scheduler-selection-row-title">Repeat</div>
                        <Button
                            variant="secondary"
                            width="250px"
                            onClick={() => handleRepeatChange('LW')}
                            className={`repeat-on-btn ${repeat === 'LW' && 'selected'}`}
                        >
                            Last weekday of every month
                        </Button>
                        <Button
                            variant="secondary"
                            width="250px"
                            onClick={() => handleRepeatChange('L')}
                            className={`repeat-on-btn ${repeat === 'L' && 'selected'}`}
                        >
                            Last day of every month
                        </Button>
                    </div>
                )}

                <div className="scheduler-selection-row" style={{ alignItems: 'flex-start' }}>
                    <div className="scheduler-selection-row-title" style={{ marginTop: '5px' }}>
                        Ends
                    </div>
                    <div className="scheduler-selection-radio-group">
                        <div className="scheduler-selection-radio-group-item">
                            <label htmlFor="item1">
                                <input
                                    id="item1"
                                    type="radio"
                                    name="endOption"
                                    value="never"
                                    checked={endOption === 'never'}
                                    onChange={() => setEndOption('never')}
                                />
                                Never
                            </label>
                        </div>
                        <div className="scheduler-selection-radio-group-item">
                            <label htmlFor="item2">
                                <input
                                    id="item2"
                                    type="radio"
                                    name="endOption"
                                    value="occurrences"
                                    checked={endOption === 'occurrences'}
                                    onChange={() => setEndOption('occurrences')}
                                />
                                After
                                <Input
                                    type="number"
                                    height="38px"
                                    width="75px"
                                    minWidth="75px"
                                    min={1}
                                    value={occurrences || 1}
                                    disabled={endOption !== 'occurrences'}
                                    setValue={(e) => setOccurrences(e.target.value)}
                                    variant="light"
                                />
                                occurrences
                            </label>
                        </div>
                        <div className="scheduler-selection-radio-group-item">
                            <label htmlFor="item3">
                                <input
                                    id="item3"
                                    type="radio"
                                    name="endOption"
                                    value="date"
                                    checked={endOption === 'date'}
                                    onChange={() => setEndOption('date')}
                                />
                                On
                                <label
                                    htmlFor="item3"
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <div style={{ marginRight: '10px' }}>Specific date</div>
                                    <Input
                                        type="date"
                                        height="38px"
                                        width="175px"
                                        value={endDate}
                                        setValue={(e) => setEndDate(e.target.value)}
                                        disabled={endOption !== 'date'}
                                        variant="light"
                                    />
                                </label>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
});

export default ScheduleCreator;

import { default as FilterGroupModel } from 'views/Segmentation/models/filterGroup';
import { default as FilterModel } from 'views/Segmentation/models/filter';
import { Operators } from 'views/Segmentation/models/definitions';

export const loadQueryFilterGroup = (filterGroupToLoad, primaryTableTree, isRoot = false) => {
    const filterGroup = new FilterGroupModel(
        isRoot,
        filterGroupToLoad.operator,
        [],
        false,
        false,
        false,
        true,
        filterGroupToLoad.id
    );

    if (filterGroupToLoad.childrenQueryFilterGroups) {
        for (let childFilterGroup of filterGroupToLoad.childrenQueryFilterGroups.edges) {
            childFilterGroup = childFilterGroup.node;
            filterGroup.addChildFilter(
                loadQueryFilterGroup(childFilterGroup, primaryTableTree, false)
            );
        }
    }

    if (filterGroupToLoad.childrenQueryFilters) {
        for (let childFilter of filterGroupToLoad.childrenQueryFilters.edges) {
            childFilter = { ...childFilter.node };
            if (childFilter.aggregateFunction && childFilter.aggregateFunction === 'count') {
                childFilter.tableProperty = { ...childFilter.tableProperty }; // Make mutable
                childFilter.tableProperty.property = 'count';
                childFilter.tableProperty.type = { ...childFilter.tableProperty.type }; // Make mutable
                childFilter.tableProperty.type.type = 'Function';
            }
            const filterType = childFilter.aggregateFunction
                ? 'Function'
                : childFilter.tableProperty.type.type;
            const operator = Operators[filterType].find((o) => o.text === childFilter.operator);
            let value = childFilter.value;
            if (filterType === 'Boolean') {
                value = childFilter.value === 'true';
            }
            filterGroup.addChildFilter(
                new FilterModel(
                    childFilter.tableProperty,
                    operator,
                    value,
                    primaryTableTree,
                    childFilter.aggregateFunction,
                    false,
                    false,
                    childFilter.joinPath,
                    childFilter.id
                )
            );
        }
    }
    return filterGroup;
};

export const getTableArrayFromTableTree = (
    tableTree,
    getJunctionTables = false,
    tablesMap = new Map()
) => {
    if (
        (getJunctionTables || (!getJunctionTables && !tableTree.table.is_junction)) &&
        !tablesMap.has(tableTree.table.id)
    ) {
        tablesMap.set(tableTree.table.id, tableTree.table);
    }

    if (tableTree.children) {
        for (const child of tableTree.children) {
            getTableArrayFromTableTree(child, getJunctionTables, tablesMap);
        }
    }
    return Array.from(tablesMap.values());
};

import './ContentWrapper.css';

// Libraries
import React from 'react';

// Components
import Nav from 'components/Nav/Nav';
import Version from 'components/Version/Version';

// Contexts
import { NavCollapseProvider } from 'contexts/NavCollapseContext';

/**
 * This wrapper was created in order to avoid the collapsed
 * state from refreshing parent (App.js) component.
 */
function ContentWrapper({ children }) {
    return (
        <div className="content-wrapper">
            <NavCollapseProvider>
                <Nav />
                <div className="content-container">{children}</div>
            </NavCollapseProvider>
            <Version />
        </div>
    );
}

export default ContentWrapper;

import './Login.css';

// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import User from 'utils/user.util';
import { Link } from 'react-router-dom';

// Assets
import logo from 'assets/okzou_logo.png';
import { ReactComponent as UsernameIcon } from 'assets/icons/username_icon.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/password_icon.svg';

// Components
import Alert from 'components/Alert/Alert';
import Button from 'components/Buttons/Button/Button';
import Input from 'components/Form/Input/Input';
import LoaderSecondary from 'components/LoaderSecondary/LoaderSecondary';

async function loginUser(credentials) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
    })
        .then((data) => data.json())
        .catch((err) => {
            console.log(err);
        });
}

export default function Login({ setToken }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [inputErrors, setInputErrors] = useState({
        username: '',
        password: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validation()) return;

        setIsSubmitting(true);

        const loginResponse = await loginUser({
            username,
            password,
        });
        // error response handling
        if (loginResponse && loginResponse.error) {
            setErrorMsg(loginResponse.error);
            setIsSubmitting(false);
            return;
        }

        User.init(loginResponse?.token)
            .then(() => setToken(loginResponse) && setIsSubmitting(false))
            .catch((e) => setErrorMsg(e.message) && setIsSubmitting(false));
    };

    const validation = () => {
        setErrorMsg('');
        let _inputErrors = {
            username: '',
            password: '',
        };
        let valid = true;

        if (!username || username.length === 0) {
            _inputErrors.username = 'Username is required';
            valid = false;
        }

        if (!password || password.length === 0) {
            _inputErrors.password = 'Password is required';
            valid = false;
        }

        setInputErrors(_inputErrors);
        return valid;
    };

    return (
        <div className="login">
            <div className="login-card">
                <form onSubmit={handleSubmit} noValidate>
                    <div className="logo">
                        <img src={logo} />
                    </div>
                    <div className="login-bottom">
                        <h2>WELCOME</h2>
                        <div className="login-input">
                            <Input
                                type="text"
                                placeholder="Username"
                                value={username}
                                setValue={(e) => setUsername(e.target.value)}
                                inputIcon={
                                    <UsernameIcon
                                        height="20"
                                        width="20"
                                        fill="var(--dark-grey-color)"
                                    />
                                }
                                width="300px"
                                error={inputErrors.username}
                                autoComplete="username"
                                displayError
                            />
                        </div>
                        <div className="login-input">
                            <Input
                                type="password"
                                placeholder="Password"
                                value={password}
                                setValue={(e) => setPassword(e.target.value)}
                                inputIcon={
                                    <PasswordIcon
                                        height="20"
                                        width="20"
                                        fill="var(--dark-grey-color)"
                                    />
                                }
                                width="300px"
                                error={inputErrors.password}
                                autoComplete="current-password"
                                displayError
                            />
                        </div>
                        {errorMsg && (
                            <Alert variant="error" width="300px">
                                {errorMsg}
                            </Alert>
                        )}
                        <Button
                            isDisabled={isSubmitting}
                            className="login-btn"
                            type="submit"
                            onClick={handleSubmit}
                            width="250px"
                        >
                            {isSubmitting ? <LoaderSecondary size="35px" /> : 'LOGIN'}
                        </Button>
                        <Link to="/forgot" className="login-forgot">
                            Forgot Password
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
};

import './Version.css';

import React, { useState, useEffect } from 'react';

import { ReactComponent as InfoIcon } from 'assets/icons/info_icon_48.svg';

const BuildInfo = () => {
    const [version, setVersion] = useState('v0.0.0');

    useEffect(() => {
        const buildInfo = window._env_;
        if (buildInfo?.REACT_APP_GIT_TAG) {
            setVersion(buildInfo?.REACT_APP_GIT_TAG);
        } else if (buildInfo?.REACT_APP_COMMIT_SHA) {
            setVersion(buildInfo?.REACT_APP_COMMIT_SHA);
        }
    }, []);

    return (
        <div className="version-wrapper">
            <p>Version: {version}</p>
            <InfoIcon height="24px" width="24px" fill="#ebebeb" />
        </div>
    );
};

export default BuildInfo;

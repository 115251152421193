import './ObjectEditor.css';

// Libraries
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Utils
import { canCreate, canDelete, canUpdate } from 'utils/permission.util';
import {
    useQuery,
    createTable,
    updateTable,
    GET_TABLE,
    GET_TABLE_AND_PROPERTIES,
    GET_TABLES_AND_PROPERTIES_WITH_UUIDS,
    GET_DATA_TYPES,
    deleteTable,
    deleteTableProperty,
} from 'utils/graphql';

// Components
import ContentHeader from 'components/ContentHeader/ContentHeader';
import Input from 'components/Form/Input/Input';
import Dropdown from 'components/Form/Dropdown/Dropdown';
import CustomDataTable from 'components/CustomDataTable/CustomDataTable';
import Tooltip from 'components/Tooltip/Tooltip';
import Modal from 'components/Modal/Modal';
import Loader from 'components/Loader/Loader';
import IconButton from 'components/Buttons/IconButton/IconButton';
import LoadingIconButton from 'components/Buttons/LoadingIconButton/LoadingIconButton';
import Button from 'components/Buttons/Button/Button';
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';
import HighlightField from 'components/HighlightField/HighlightField';
import EditableHighlightField from 'components/EditableHighlightField/EditableHighlightField';

// Assets
import { ReactComponent as SaveIcon } from 'assets/icons/save_icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check_icon_24.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon_24.svg';
import { ReactComponent as ErrorIconBig } from 'assets/icons/error_icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete_icon.svg';
import { ReactComponent as SubRightIcon } from 'assets/icons/subdirectory_arrow_right_icon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_icon_48.svg';

const STATE_IDLE = 'STATE_IDLE';
const STATE_SAVING = 'STATE_SAVING';
const STATE_SUCCESS = 'STATE_SUCCESS';
const STATE_ERROR = 'STATE_ERROR';

function ObjectEditor() {
    const { permissionSet } = useContext(CurrentPermissionContext);
    let navigate = useNavigate();
    let { table_id } = useParams();
    const [name, setName] = useState('');
    const [table, setTable] = useState();
    const [tables, setTables] = useState([]);
    const [hasData, setHasData] = useState(true);
    const [isJunction, setIsJunction] = useState(false);
    const [dataTypes, setDataTypes] = useState([]);
    const [inputFields, setInputFields] = useState([]);
    const [inputFieldsServer, setInputFieldsServer] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saveError, setSaveError] = useState('');
    const [saveState, setSaveState] = useState(STATE_IDLE);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [modalOptions, setModalOptions] = useState();

    const updateSaveState = ({ state, error = '' }) => {
        setSaveState(state);
        setSaveError(error);
    };

    const columns = useMemo(
        () => [
            {
                name: 'Name',
                width: '275px',
                minWidth: '250px',
                cell: (row, i) => {
                    return (
                        <div className="fk-col" key={i}>
                            <Input
                                minWidth="200px"
                                editIcon={true}
                                value={row.property}
                                setValue={(e) => {
                                    if (
                                        /^[a-z0-9_]+$/i.test(e.target.value) ||
                                        e.target.value === ''
                                    ) {
                                        handleFormChange(i, e.target.value, 'property');
                                    }
                                }}
                            />
                            {row.foreignKey && (
                                <div className="fk-label">
                                    <div className="fk-label-icon">
                                        <SubRightIcon />
                                    </div>
                                    FK Object & Property
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                name: 'Type',
                minWidth: '130px',
                cell: (row, i) => {
                    return (
                        <div className="fk-col">
                            <Dropdown
                                readOnly={row.foreignKey || !row.isNew ? true : false}
                                value={row.type.value}
                                values={dataTypes}
                                setValue={(e) => {
                                    const dataType = dataTypes.find(
                                        (t) => t.value === e.target.value
                                    );
                                    handleFormChange(i, dataType, 'type');

                                    // Default UUID to 36 chars
                                    if (dataType.text === 'UUID') {
                                        handleFormChange(i, 36, 'length');
                                    } else {
                                        handleFormChange(i, '', 'length');
                                    }

                                    if (dataType.text === 'Boolean') {
                                        handleFormChange(i, false, 'defaultValue');
                                    } else {
                                        handleFormChange(i, '', 'defaultValue');
                                    }
                                }}
                            />
                            {row.foreignKey && (
                                <div className="fk-input">
                                    <Dropdown
                                        readOnly={!row.isNew}
                                        height="35px"
                                        value={row.foreignKeyTable}
                                        values={tables
                                            .filter((_table) => _table.id !== table_id)
                                            .map((_table) => {
                                                return {
                                                    text: _table.name,
                                                    value: _table.id,
                                                };
                                            })}
                                        setValue={(e) => {
                                            handleFormChange(
                                                i,
                                                `${e.target.value}.id`,
                                                'foreignKey'
                                            );
                                            handleFormChange(i, e.target.value, 'foreignKeyTable');
                                            handleFormChange(i, 'id', 'foreignKeyProperty');

                                            // Update type to fk type
                                            handleFKChange(row, i, 'id');
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                name: 'Length',
                minWidth: '120px',
                cell: (row, i) => {
                    const isFK = row.foreignKey?.length > 0;
                    return (
                        <div className="fk-col">
                            {['Date', 'Boolean', 'Number', 'Time'].includes(row.type.text) ? (
                                <Input disabled={true} readOnly={true} value="" />
                            ) : (
                                <Input
                                    disabled={[
                                        'Date',
                                        'Boolean',
                                        'Number',
                                        'Time',
                                        'UUID',
                                    ].includes(row.type.text)}
                                    readOnly={isFK}
                                    placeholder=""
                                    type="number"
                                    minWidth="100px"
                                    value={row.length || ''}
                                    setValue={(e) => {
                                        handleFormChange(i, e.target.value, 'length');
                                    }}
                                />
                            )}
                            {row.foreignKey && (
                                <div className="fk-input">
                                    <Dropdown
                                        readOnly={!row.isNew}
                                        height="35px"
                                        value={row.foreignKeyProperty}
                                        values={(() => {
                                            let values = tables
                                                .find((_table) => _table.id === row.foreignKeyTable)
                                                ?.properties.map((p) => {
                                                    return {
                                                        text: p.property,
                                                        value: p.id,
                                                    };
                                                });
                                            if (values?.length > 0) {
                                                values.unshift({
                                                    text: 'id',
                                                    value: 'id',
                                                });
                                                return values;
                                            }
                                        })()}
                                        setValue={(e) => {
                                            handleFormChange(
                                                i,
                                                `${row.foreignKeyTable}.${e.target.value}`,
                                                'foreignKey'
                                            );
                                            handleFormChange(
                                                i,
                                                e.target.value,
                                                'foreignKeyProperty'
                                            );

                                            // Update type to fk type
                                            handleFKChange(row, i, e.target.value);
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                name: 'Default Value',
                minWidth: '120px',
                cell: (row, i) => (
                    <>
                        <Input
                            readOnly={row.type.text === 'UUID'}
                            type={(() => {
                                if (row.type.text === 'Number') {
                                    return 'number';
                                } else if (row.type.text === 'Date') {
                                    return 'date';
                                } else if (row.type.text === 'Time') {
                                    return 'time';
                                } else if (row.type.text === 'Boolean') {
                                    return 'checkbox';
                                }

                                return 'text';
                            })()}
                            minWidth="100px"
                            value={(() => {
                                if (row.type.text === 'Number') {
                                    return parseInt(row.defaultValue) || '';
                                } else if (row.type.text === 'Boolean') {
                                    return row.defaultValue === true || row.defaultValue === 'true';
                                } else if (row.type.text === 'UUID') {
                                    return '';
                                }
                                return String(row.defaultValue);
                            })()}
                            setValue={(e) => {
                                let value = e.target.value;
                                if (row.type.text === 'Boolean') {
                                    value = e.target.checked;
                                }
                                handleFormChange(i, value, 'defaultValue');
                            }}
                        />
                        {row.foreignKey && !row.isNew && (
                            <div className="fk-info">
                                <Tooltip
                                    tip="Cannot edit FK of saved property."
                                    width="150px"
                                    position="right-center"
                                >
                                    <InfoIcon height="24px" width="24px" />
                                </Tooltip>
                            </div>
                        )}
                    </>
                ),
            },
            {
                name: 'Nullable',
                minWidth: '70px',
                cell: (row, i) => {
                    const readOnlyMsg =
                        'New properties and existing properties already set nullable for objects containing data must be set nullable';
                    // When data exists, allow making a row nullable but not removing it
                    const isReadOnly = (() => {
                        // Check if property is saved as nullable already
                        const savedAsNullable =
                            inputFieldsServer.find((f) => f.id === row.id)?.nullable === true;

                        if (hasData && (row.isNew || savedAsNullable)) {
                            return true;
                        }
                        return false;
                    })();
                    return (
                        <Tooltip
                            tip={isReadOnly ? readOnlyMsg : ''}
                            isDisabled={!isReadOnly}
                            position="right-center"
                            width="150px"
                        >
                            <Input
                                readOnly={isReadOnly}
                                type="checkbox"
                                minWidth="70px"
                                value={row.nullable}
                                setValue={(e) => {
                                    handleFormChange(i, e.target.checked, 'nullable');
                                }}
                            />
                        </Tooltip>
                    );
                },
            },
            {
                name: 'Unique',
                minWidth: '70px',
                cell: (row, i) => {
                    const allowedUniqueTypes = ['text', 'number', 'date', 'time', 'uuid'];

                    let readOnlyMsg = 'Cannot set a column to unique when object contains data';
                    const isReadOnly = (() => {
                        if (hasData) {
                            // Readonly if table contains data
                            return true;
                        }
                        if (!allowedUniqueTypes.includes(row?.type.text.toLowerCase())) {
                            readOnlyMsg = 'Data type cannot be unique';

                            // Readonly if data type cannot be unique
                            return true;
                        }
                        return false;
                    })();

                    return (
                        <Tooltip
                            tip={isReadOnly ? readOnlyMsg : ''}
                            isDisabled={!isReadOnly}
                            position="right-center"
                            width="150px"
                        >
                            <Input
                                readOnly={isReadOnly}
                                type="checkbox"
                                minWidth="70px"
                                value={row.unique}
                                setValue={(e) => {
                                    handleFormChange(i, e.target.checked, 'unique');
                                }}
                            />
                        </Tooltip>
                    );
                },
            },
            {
                name: 'Foreign Key',
                minWidth: '70px',
                cell: (row, i) => (
                    <Tooltip
                        tip="Cannot edit FK of saved property."
                        width="150px"
                        position="right-center"
                        isDisabled={!table_id || row.isNew}
                    >
                        <Input
                            readOnly={table_id && !row.isNew}
                            type="checkbox"
                            minWidth="70px"
                            value={row.foreignKey || ''}
                            setValue={(e) => {
                                if (e.target.checked) {
                                    const defaultFkTable = tables[0]?.id;
                                    handleFormChange(i, `${defaultFkTable}.id`, 'foreignKey');
                                    handleFormChange(i, defaultFkTable, 'foreignKeyTable');
                                    handleFormChange(i, 'id', 'foreignKeyProperty');

                                    // Update type to fk type
                                    handleFKChange(row, i, 'id');
                                } else {
                                    handleFormChange(i, false, 'foreignKey');
                                }
                            }}
                        />
                    </Tooltip>
                ),
            },
            {
                name: 'Encrypt',
                minWidth: '70px',
                cell: (row, i) => (
                    <Input
                        type="checkbox"
                        minWidth="70px"
                        value={row.encrypt}
                        setValue={(e) => {
                            handleFormChange(i, e.target.checked, 'encrypt');
                        }}
                    />
                ),
            },
            {
                name: '',
                width: '50px',
                cell: (row, i) => (
                    <IconButton
                        onClick={() => {
                            const removeItem = () => {
                                let _fields = [...inputFields];
                                _fields.splice(i, 1);
                                setInputFields(_fields);
                            };
                            if (row?.isNew) {
                                removeItem();
                            } else {
                                openModal('deleteRow');
                                setModalOptions({
                                    onConfirm: () => {
                                        deleteTableProperty(table_id, [{ id: row.id }])
                                            .then((r) => {
                                                if (r?.errors?.length > 0) {
                                                    openModal('objectDeleteError');
                                                } else {
                                                    removeItem();
                                                    updateSaveState({ state: STATE_SUCCESS });
                                                }
                                            })
                                            .catch((e) => {
                                                console.log(e);
                                                openModal('objectDeleteError');
                                            });
                                    },
                                });
                            }
                        }}
                    >
                        <DeleteIcon fill="var(--error-color)" height="24" width="24" />
                    </IconButton>
                ),
            },
        ],
        [inputFields, dataTypes, tables, hasData]
    );

    const { loading: tableLoading, refetch: refetchProperties } = useQuery(
        GET_TABLE_AND_PROPERTIES,
        {
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
            skip: !table_id,
            variables: {
                id: table_id,
            },
            onCompleted: (results) => {
                setTable(results.tableSchema);
                setName(results.tableSchema?.name);
                setIsJunction(results.tableSchema.isJunction);
                const _inputFields = results.tableSchema.properties.edges
                    .map((p) => {
                        return {
                            id: p.node.id,
                            defaultValue: p.node.defaultValue,
                            foreignKey: p.node.foreignKey,
                            foreignKeyTable: p.node.foreignKey?.split('.')[0] || '',
                            foreignKeyProperty: p.node.foreignKey?.split('.')[1] || '',
                            nullable: p.node.nullable,
                            property: p.node.property,
                            unique: p.node.unique,
                            encrypt: p.node.encrypt,
                            length: p.node['length'],
                            type: {
                                value: p.node.type.id,
                                text: p.node.type.type,
                            },
                        };
                    })
                    .sort((a, b) => {
                        if (a.property < b.property) {
                            return -1;
                        }
                        if (a.property < b.property) {
                            return 1;
                        }
                        return 0;
                    });
                setInputFields(_inputFields);
                // Need to use the spread operator to make a deep copy to compare to what's saved server side
                // otherwise, adding fields to the inputFields array will also add them to the inputFieldsServer
                setInputFieldsServer([..._inputFields]);
            },
        }
    );

    const { loading: tablesLoading } = useQuery(GET_TABLES_AND_PROPERTIES_WITH_UUIDS, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        onCompleted: (results) => {
            setTables(results.tableList);
        },
    });

    useQuery(GET_TABLE, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        skip: !table?.name,
        variables: { name: table?.name, limit: 1 },
        onCompleted: (results) => {
            setHasData(results?.table?.count > 0 || false);
        },
    });

    useQuery(GET_DATA_TYPES, {
        onCompleted: (results) => {
            setDataTypes(
                results.datatypeList.edges.map((type) => {
                    return {
                        text: type.node.type,
                        value: type.node.id,
                    };
                })
            );
        },
    });

    const createNewField = useCallback(() => {
        return {
            defaultValue: '',
            foreignKey: '',
            foreignKeyTable: '',
            foreignKeyProperty: '',
            nullable: table_id ? true : false,
            property: '',
            unique: false,
            encrypt: false,
            length: '',
            type: dataTypes[0],
            isNew: true,
        };
    }, [dataTypes]);

    const handleFKChange = (row, i, _fk) => {
        const _property = tables
            .find((_table) => _table.id === row.foreignKeyTable)
            ?.properties.find((p) => p.id === _fk) || {
            type: { type: 'UUID' },
        };

        const _dataType = dataTypes.find((t) => t.text === _property.type.type);

        // Update type to fk type
        handleFormChange(i, _dataType, 'type');

        const _length = _property.length
            ? _property.length
            : _property.type.type === 'UUID'
            ? 36
            : '';

        // Update length to fk length
        handleFormChange(i, _length, 'length');
    };

    const handleFormChange = useCallback(
        (index, value, property) => {
            setInputFields((prevData) => {
                const updatedData = [...prevData];
                updatedData[index] = { ...updatedData[index], [property]: value };
                return updatedData;
            });
        },
        [inputFields]
    );

    const handleSave = () => {
        updateSaveState({ state: STATE_SAVING });
        if (!validate()) return;

        let _table = {
            name,
            junction: isJunction,
            fields: inputFields,
        };

        if (table_id) {
            // Edit
            _table.id = table_id;
            updateTable(_table)
                .then((result) => {
                    if (result?.errors) {
                        updateSaveState({
                            state: STATE_ERROR,
                            error: result?.errors?.[0]?.message,
                        });
                    } else {
                        updateSaveState({ state: STATE_SUCCESS });
                        refetchProperties();
                    }
                })
                .catch((err) => {
                    updateSaveState({ state: STATE_ERROR, error: err.message });
                });
        } else {
            // New
            createTable(_table)
                .then((result) => {
                    if (result?.errors) {
                        updateSaveState({
                            state: STATE_ERROR,
                            error: result?.errors?.[0]?.message,
                        });
                    } else {
                        const _id = result?.data?.createTable?.table?.id;
                        setTimeout(() => {
                            navigate(`/database/edit/${_id}`, { replace: true });
                        }, 1000);
                        updateSaveState({ state: STATE_SUCCESS });
                    }
                })
                .catch((err) => {
                    updateSaveState({
                        state: STATE_ERROR,
                        error: err.message,
                    });
                });
        }
    };

    const validate = () => {
        let _errors = [];
        if (name.length < 3) {
            _errors.push('Object name must be at least 3 characters long.');
        }
        if (inputFields.length === 0) {
            _errors.push('Object must contain at least 1 property.');
        }
        inputFields.forEach((field) => {
            if (field.property.length === 0 || !field.property) {
                _errors.push('Object properties must have a name.');
            } else if (!/^[a-z0-9_]+$/i.test(field.property)) {
                _errors.push(
                    `Object properties must not contain spaces or special characters: ${field.property}.`
                );
            } else if (field.property === 'id') {
                _errors.push(
                    'Object properties cannot be named "id" as this field is generated automatically.'
                );
            }
        });

        if (_errors.length > 0) {
            updateSaveState({
                state: STATE_ERROR,
                error: (
                    <ul style={{ listStyleType: 'none', padding: '5px' }}>
                        {_errors.map((error, i) => {
                            return <li key={i}>{error}</li>;
                        })}
                    </ul>
                ),
            });
            return false;
        }

        return true;
    };

    const openModal = (type) => {
        setModalType(type);
        setIsOpen(true);
    };

    const getModalOptions = (type) => {
        switch (type) {
            case 'deleteTable':
                return {
                    type: 'confirmation',
                    title: 'Warning',
                    content: (
                        <div>
                            <p>
                                Are you sure you want to delete this Object and all it's data? You
                                will not be able to recover this afterwards.
                            </p>
                            <b>You're about to delete {name}</b>
                        </div>
                    ),
                    confirmBtnText: 'Yes',
                    cancelBtnText: 'No',
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: modalOptions?.onConfirm,
                };
            case 'deleteRow':
                return {
                    type: 'confirmation',
                    title: 'Warning',
                    content: (
                        <div>
                            <p>Are you sure you want to delete this row?</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: modalOptions?.onConfirm,
                };
            case 'nameError':
                return {
                    title: <ErrorIconBig fill="var(--error-color)" />,
                    content: (
                        <div>
                            <p>
                                Object name can only be alphanumeric with the exception of
                                underscores and no longer than 40 characters.
                            </p>
                            <p>No spaces or special characters allowed.</p>
                        </div>
                    ),
                    width: '400px',
                    textAlign: 'center',
                };
            case 'objectDeleteError':
                return {
                    title: <ErrorIconBig fill="var(--error-color)" />,
                    content: (
                        <div>
                            <p>Unable to delete object!</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    useEffect(() => {
        if (tableLoading === false && tablesLoading === false) {
            setLoading(false);
        }
    }, [tableLoading, tablesLoading]);

    /**
     * Remove saved checkmark 3 seconds after displaying.
     */
    useEffect(() => {
        if (saveState === STATE_SUCCESS) {
            setTimeout(() => {
                updateSaveState({ state: STATE_IDLE });
            }, 3000);
        }
    }, [saveState]);

    /**
     * Initialize new table object if we're not editing.
     */
    useEffect(() => {
        if (table_id || !dataTypes?.length > 0) return;
        const _fields = [createNewField()];
        setTable({ isJunction: false, name: '', properties: _fields });
        setInputFields(_fields);
    }, [dataTypes]);

    /**
     * Default for hasData is true because
     * slow network could permit user to perform actions
     * that depend on hasData to be loaded.
     */
    useEffect(() => {
        if (!table?.name) {
            setHasData(false);
        }
    }, []);

    return (
        <div className="object-editor">
            {!loading && table && (
                <>
                    <ContentHeader style={{ justifyContent: 'space-between' }}>
                        {table_id ? (
                            <HighlightField value={name} width="300px" />
                        ) : (
                            <EditableHighlightField
                                defaultIsEditing={!table_id}
                                defaultDisplayName="Object Name"
                                placeholder="Object name"
                                value={name}
                                setValue={(value) => {
                                    if (
                                        (/^[a-z0-9_]+$/i.test(value) && value.length <= 40) ||
                                        value === ''
                                    ) {
                                        setName(value);
                                    } else {
                                        openModal('nameError');
                                    }
                                }}
                            />
                        )}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="object-editor-save-status">
                                {saveState === STATE_ERROR ? (
                                    <Tooltip tip={saveError} width="200px">
                                        <ErrorIcon
                                            fill="var(--error-color)"
                                            style={
                                                saveState === STATE_ERROR
                                                    ? { opacity: 1 }
                                                    : { opacity: 0 }
                                            }
                                        />
                                    </Tooltip>
                                ) : (
                                    <CheckIcon
                                        fill="var(--success-color)"
                                        style={
                                            saveState === STATE_SUCCESS
                                                ? { opacity: 1 }
                                                : { opacity: 0 }
                                        }
                                    />
                                )}
                            </div>
                            {(canUpdate(permissionSet) || canCreate(permissionSet)) && (
                                <LoadingIconButton
                                    isLoading={saveState === STATE_SAVING}
                                    onClick={handleSave}
                                    className="object-editor-save"
                                    loaderColor="dark"
                                >
                                    <SaveIcon fill="var(--accent-color)" height="24" width="24" />
                                </LoadingIconButton>
                            )}
                        </div>
                    </ContentHeader>
                    <div className="object-editor-junction">
                        <Tooltip
                            width="150px"
                            tip={
                                'Object is used to join two other objects and will not show in segmentation'
                            }
                        >
                            <b style={{ marginRight: '5px' }}>Junction</b>
                        </Tooltip>
                        <Input
                            type="checkbox"
                            width="70px"
                            minWidth="70px"
                            value={isJunction}
                            setValue={(e) => {
                                setIsJunction(e.target.checked);
                            }}
                        />
                    </div>
                    <CustomDataTable
                        data={inputFields}
                        columns={columns}
                        progressPending={loading}
                        progressComponent={<Loader />}
                        noDataComponent="Your object has no properties."
                    />
                    <div className="action-btns-row">
                        <Button
                            onClick={() => {
                                const newField = createNewField();
                                inputFields.push(newField);
                                const _inputFields = [...inputFields];
                                setInputFields(_inputFields);
                            }}
                            width="150px"
                        >
                            New Property
                        </Button>
                        {table_id && canDelete(permissionSet) && (
                            <Button
                                variant="danger"
                                onClick={() => {
                                    openModal('deleteTable');
                                    setModalOptions({
                                        onConfirm: () => {
                                            deleteTable(table_id)
                                                .then(() => {
                                                    navigate('/database');
                                                })
                                                .catch(() => {
                                                    openModal('objectDeleteError');
                                                });
                                        },
                                    });
                                }}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                </>
            )}
            {loading && <Loader />}
            <Modal options={getModalOptions(modalType)} isOpen={modalIsOpen} setOpen={setIsOpen} />
        </div>
    );
}

export default ObjectEditor;

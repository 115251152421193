import './Reset.css';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import User from 'utils/user.util';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';
import Input from 'components/Form/Input/Input';
import LoaderSecondary from 'components/LoaderSecondary/LoaderSecondary';
import Loader from 'components/Loader/Loader';
import Button from 'components/Buttons/Button/Button';

// Assets
import logo from 'assets/okzou_logo.png';
import { ReactComponent as PasswordIcon } from 'assets/icons/password_icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check_icon.svg';

function Reset() {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [activated, setActivated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState({
        password: '',
        confirmPassword: '',
    });

    const handleSubmit = () => {
        if (!validate()) return;
        setSubmitting(true);

        // Set password and activate
        const resetToken = searchParams.get('token');
        fetch(process.env.REACT_APP_BACKEND_URL + '/resetpassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: resetToken,
                password,
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res?.success) {
                    setActivated(true);
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 2000);
                }
                setSubmitting(false);
            })
            .catch((err) => {
                console.log(err);
                setSubmitting(false);
            });
    };

    const validate = () => {
        let _validationErrors = {
            password: '',
            confirmPassword: '',
        };
        let valid = true;
        const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

        if (!regex.test(password)) {
            _validationErrors.password =
                'Password must be 8 characters long or more, contain at least 1 capitalized character and 1 special character.';
            valid = false;
        }
        if (password !== confirmPassword) {
            _validationErrors.confirmPassword = 'Password and confirmation do not match.';
            valid = false;
        }

        setValidationErrors(_validationErrors);
        return valid;
    };

    const getSetPwdView = () => {
        return (
            <>
                <h2>Reset Your Password</h2>
                <p>Create your new password.</p>
                <div className="activate-form">
                    <div className="activate-input">
                        <Input
                            width="300px"
                            placeholder="password"
                            type="password"
                            value={password}
                            setValue={(e) => {
                                setPassword(e.target.value);
                            }}
                            inputIcon={<PasswordIcon fill="#575757" />}
                            error={validationErrors.password}
                            displayError={true}
                        />
                    </div>
                    <div className="activate-input">
                        <Input
                            width="300px"
                            placeholder="confirm password"
                            type="password"
                            value={confirmPassword}
                            setValue={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            inputIcon={<PasswordIcon fill="#575757" />}
                            error={validationErrors.confirmPassword}
                            displayError={true}
                        />
                    </div>
                    <Button
                        className="activate-btn"
                        onClick={handleSubmit}
                        isDisabled={submitting}
                        width="250px"
                    >
                        {submitting ? <LoaderSecondary size="35px" /> : 'Update Password'}
                    </Button>
                </div>
            </>
        );
    };

    const getSuccessView = () => {
        return (
            <div className="success">
                <div className="success-icon">
                    <CheckIcon fill="var(--success-color)" width="48" height="48" />
                </div>
                <p>Password Successfully Reset!</p>
            </div>
        );
    };

    const getLoadingView = () => {
        return <Loader />;
    };

    const getErrorView = () => {
        return (
            <div className="error">
                <div className="error-icon">
                    <ErrorIcon fill="var(--error-color)" width="40" height="40" />
                </div>
                <p>{error}</p>
            </div>
        );
    };

    const getView = () => {
        if (loading) {
            return getLoadingView();
        }

        if (error) {
            return getErrorView();
        }

        if (activated) {
            return getSuccessView();
        }

        return getSetPwdView();
    };

    useEffect(() => {
        const resetToken = searchParams.get('token');
        fetch(process.env.REACT_APP_BACKEND_URL + '/validate_reset_token?token=' + resetToken)
            .then((res) => res.json())
            .then((res) => {
                if (res?.error) {
                    setError(res.error);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError('Invalid Activation Token.');
            });
    }, []);

    /**
     * Log previous user out.
     */
    useEffect(() => {
        if (User.isInit()) User.clear();
    }, []);

    return (
        <ContentPanel width="420px" variant="white">
            <div className="activate">
                <div className="logo">
                    <a href="/">
                        <img src={logo} />
                    </a>
                </div>
                {getView()}
            </div>
        </ContentPanel>
    );
}

export default Reset;

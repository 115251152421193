import './ContentHeader.css';

function ContentHeader({ children, style, title }) {
    return (
        <div className="content-header" style={style}>
            {title && (
                <div className="title">
                    <div className="title-text">{title}</div>
                    <div className="highlight-wrapper">
                        <div className="container">
                            <div className="highlight"></div>
                        </div>
                    </div>
                </div>
            )}
            {children}
        </div>
    );
}

export default ContentHeader;

import { createContext, useContext } from 'react';
import { useLocalStorage } from 'utils/localstorage.util';

const NavCollapseContext = createContext();
const NavCollapseUpdateContext = createContext();

export const useNavCollapse = () => {
    return useContext(NavCollapseContext);
};

export const useNavCollapseUpdate = () => {
    return useContext(NavCollapseUpdateContext);
};

export const NavCollapseProvider = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useLocalStorage('navCollapsed', false);

    const toggleNavCollapsed = () => {
        setIsCollapsed((prev) => !prev);
    };

    return (
        <NavCollapseContext.Provider value={isCollapsed}>
            <NavCollapseUpdateContext.Provider value={toggleNavCollapsed}>
                {children}
            </NavCollapseUpdateContext.Provider>
        </NavCollapseContext.Provider>
    );
};

import './DragItem.css';

import { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';

function DragItem({
    children,
    data,
    index,
    type,
    innerRef = null,
    className = '',
    dragHandler = (item, monitor) => {},
    dependencies = [],
}) {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag(
        {
            type,
            item: { index, type, data, fromIndex: index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                dragHandler(item, monitor);
            },
        },
        dependencies
    );
    drag(ref);

    useEffect(() => {
        if (innerRef) {
            innerRef.current = ref.current;
        }
    }, [ref]);

    return (
        <div ref={ref} className={`drag-item ${className} ${isDragging ? 'is-dragging' : ''}`}>
            {children}
        </div>
    );
}

export default DragItem;

import './Pill.css';

// Libraries
import PropTypes from 'prop-types';

const Pill = ({
    children,
    variant = 'secondary',
    onClick = null,
    onMouseEnter = null,
    onMouseLeave = null,
    className = '',
    width = '100px',
    height = 'auto',
    minHeight = '45px',
    isExpanded = false,
}) => {
    return (
        <span
            className={`pill ${variant} ${className} ${isExpanded ? 'expanded' : ''}`}
            style={{ width, height, minHeight, cursor: onClick ? 'pointer' : 'default' }}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </span>
    );
};

Pill.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf([
        'primary',
        'danger',
        'secondary',
        'accent',
        'success',
        'primary-light',
    ]),
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    width: PropTypes.string,
    height: PropTypes.string,
    minHeight: PropTypes.string,
    isExpanded: PropTypes.bool,
};

export default Pill;

import './Account.css';

// Libraries
import React from 'react';
import User from 'utils/user.util';

// Components
import Input from 'components/Form/Input/Input';
import ContentPanel from 'components/ContentPanel/ContentPanel';

function Account() {
    return (
        <ContentPanel title="Account">
            <div className="account">
                <div className="account-input-row">
                    <p>Username</p>
                    <Input value={User.getUserName()} readOnly type="text" placeholder="Username" />
                </div>
                <div className="account-input-row">
                    <p>Email</p>
                    <Input value={User.getEmail()} readOnly type="email" placeholder="Email" />
                </div>
                <div className="account-input-row">
                    <p>First Name</p>
                    <Input
                        type="text"
                        placeholder="First Name"
                        value={User.getFirstName()}
                        readOnly
                    />
                </div>
                <div className="account-input-row">
                    <p>Last Name</p>
                    <Input
                        type="text"
                        placeholder="Last Name"
                        value={User.getLastName()}
                        readOnly
                    />
                </div>
                <div className="account-input-row">
                    <p>Role</p>
                    <Input type="text" placeholder="Username" value={User.getRole()} readOnly />
                </div>
            </div>
        </ContentPanel>
    );
}

export default Account;

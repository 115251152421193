import './Forgot.css';

// Libraries
import React, { useState } from 'react';

// Components
import Input from 'components/Form/Input/Input';
import Button from 'components/Buttons/Button/Button';
import ContentPanel from 'components/ContentPanel/ContentPanel';

// Utils
import { isEmailValid } from 'utils/validation.util';

// Assets
import logo from 'assets/okzou_logo.png';
import forgot from 'assets/images/forgot.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check_icon.svg';
import { ReactComponent as UsernameIcon } from 'assets/icons/username_icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon.svg';

const STATE_IDLE = 'STATE_IDLE';
const STATE_SUCCESS = 'STATE_SUCCESS';
const STATE_ERROR = 'STATE_ERROR';

export default function Forgot() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [submitState, setSubmitState] = useState(STATE_IDLE);

    const validate = () => {
        setError('');
        if (!isEmailValid(email)) {
            setError('Must be a valid email address.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/forgotpassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email.toLowerCase() }),
            })
                .then((r) => r.json())
                .then((r) => setSubmitState(r?.success ? STATE_SUCCESS : STATE_ERROR))
                .catch(() => setSubmitState(STATE_ERROR));
        }
    };

    const statusBtn = () => {
        switch (submitState) {
            case STATE_IDLE:
                return '';
            case STATE_SUCCESS:
                return <CheckIcon fill="var(--success-color)" />;
            case STATE_ERROR:
                return <ErrorIcon fill="var(--error-color)" />;
            default:
                return '';
        }
    };

    return (
        <ContentPanel variant="white" width="950px">
            <div className="forgot">
                <div className="forgot-col">
                    <img className="forgot-img" src={forgot} />
                </div>
                <div className="forgot-col">
                    <div className="forgot-logo">
                        <a href="/">
                            <img src={logo} />
                        </a>
                    </div>
                    <h2>Forgot your password?</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="forgot-input">
                            <Input
                                width="300px"
                                placeholder="Enter email address"
                                type="text"
                                value={email}
                                setValue={(e) => {
                                    setEmail(e.target.value);
                                }}
                                inputIcon={<UsernameIcon fill="var(--dark-grey-color)" />}
                                error={error}
                                displayError={true}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button type="submit" width="300px" className="forgot-btn">
                                Reset Password
                            </Button>
                            {statusBtn()}
                        </div>
                    </form>
                </div>
            </div>
        </ContentPanel>
    );
}

import { Chart as ChartJS, Tooltip, CategoryScale, LinearScale, Title } from 'chart.js';
import { MatrixElement, MatrixController } from 'chartjs-chart-matrix';
import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import PropTypes from 'prop-types';

ChartJS.register(Tooltip, CategoryScale, LinearScale, Title, MatrixElement, MatrixController);

const MatrixChart = ({
    title,
    datasets,
    xAxisTitle = null,
    yAxisTitle = null,
    zAxisTitle = null,
    xAxisLabels = [],
    yAxisLabels = [],
    backgroundRgbBaseColor = { r: 0, g: 128, b: 0 },
    borderRgbBaseColor = { r: 0, g: 100, b: 0 },
    tooltipTitleCallback = () => {
        return '';
    },
    innerRef = null,
}) => {
    const [options, setOptions] = useState({});
    const [dataSets, setDataSets] = useState([]);

    useEffect(() => {
        setOptions({
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: title,
                    text: title,
                },
                tooltip: {
                    callbacks: {
                        title: tooltipTitleCallback,
                        label(context) {
                            const item = context.dataset.data[context.dataIndex];
                            return [
                                `${xAxisTitle ? xAxisTitle : 'x'}: ${item.x}`,
                                `${yAxisTitle ? yAxisTitle : 'y'}: ${item.y}`,
                                `${zAxisTitle ? zAxisTitle : 'z'}: ${item.z}`,
                            ];
                        },
                    },
                },
            },
            scales: {
                x: {
                    type: 'category',
                    labels: xAxisLabels,
                    offset: true,
                    ticks: {
                        display: true,
                    },
                    grid: {
                        display: false,
                    },
                    title: {
                        display: xAxisTitle != null,
                        text: xAxisTitle,
                        font: {
                            size: 15,
                        },
                    },
                },
                y: {
                    type: 'category',
                    labels: yAxisLabels,
                    offset: true,
                    ticks: {
                        display: true,
                    },
                    grid: {
                        display: false,
                    },
                    title: {
                        display: yAxisTitle != null,
                        text: yAxisTitle,
                        font: {
                            size: 15,
                        },
                    },
                },
            },
        });
    }, [title, xAxisTitle, yAxisTitle, zAxisTitle, xAxisLabels, yAxisLabels]);

    useEffect(() => {
        if (!datasets) return;
        const xAxisLabelsLength = xAxisLabels.length;
        const yAxisLabelsLength = yAxisLabels.length;
        for (let dataset of datasets) {
            dataset.backgroundColor = (context) => {
                const value = context.dataset.data[context.dataIndex]?.z;
                const alpha = value / 40;
                return `rgba(${backgroundRgbBaseColor.r}, ${backgroundRgbBaseColor.g}, ${backgroundRgbBaseColor.b}, ${alpha})`;
            };
            dataset.borderColor = (context) => {
                const value = context.dataset.data[context.dataIndex]?.z;
                const alpha = value / 40;
                return `rgba(${borderRgbBaseColor.r}, ${borderRgbBaseColor.g}, ${borderRgbBaseColor.b}, ${alpha})`;
            };
            dataset.width = ({ chart }) => (chart.chartArea || {}).width / xAxisLabelsLength;
            dataset.height = ({ chart }) => (chart.chartArea || {}).height / yAxisLabelsLength;
        }
        setDataSets(datasets);
    }, [datasets, xAxisLabels, yAxisLabels]);

    return <Chart type="matrix" data={{ datasets: dataSets }} options={options} ref={innerRef} />;
};

MatrixChart.propTypes = {
    title: PropTypes.string,
    datasets: PropTypes.arrayOf(PropTypes.object).isRequired,
    xAxisTitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    zAxisTitle: PropTypes.string,
    xAxisLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    yAxisLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
    backgroundRgbBaseColor: PropTypes.shape({
        r: PropTypes.number,
        g: PropTypes.number,
        b: PropTypes.number,
    }),
    borderRgbBaseColor: PropTypes.shape({
        r: PropTypes.number,
        g: PropTypes.number,
        b: PropTypes.number,
    }),
    tooltipTitleCallback: PropTypes.func,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
};

export default MatrixChart;

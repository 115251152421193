import './Settings.css';

// Libraries
import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

// Views
import Users from './Users/Users';
import { OAuth2 } from './Connections/OAuth/OAuth2';
import Connections from './Connections/Connections';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';

function Settings() {
    let navigate = useNavigate();
    const { pathname } = useLocation();
    const [selected, setSelected] = useState();

    const handleNav = (selection) => {
        setSelected(selection);
        navigate(`/settings/${selection}`);
    };

    /**
     * Update nav selection based on route
     */
    useEffect(() => {
        const path = pathname.substring(1);
        const route = path.split('/')[1];
        setSelected(route);
    }, [pathname]);

    /**
     * Default to /users
     */
    useEffect(() => {
        const path = pathname.substring(1);
        const route = path.split('/')[1];
        if (!route) navigate('/settings/users');
    }, []);

    return (
        <div className="settings-wrapper">
            <ContentPanel title="Settings">
                <div className="settings-body">
                    <div className="settings-menu">
                        <ul>
                            <li
                                onClick={() => handleNav('users')}
                                className={`${selected === 'users' ? 'selected' : ''}`}
                            >
                                Users
                            </li>
                            <li
                                onClick={() => handleNav('connections')}
                                className={`${selected === 'connections' ? 'selected' : ''}`}
                            >
                                Connections
                            </li>
                        </ul>
                    </div>
                    <div className="settings-pages">
                        <Routes>
                            <Route path="/users" element={<Users></Users>}></Route>
                            <Route path="/connections" element={<Connections />}></Route>
                            <Route path="/connections/oauth2" element={<OAuth2 />}></Route>
                        </Routes>
                    </div>
                </div>
            </ContentPanel>
        </div>
    );
}

export default Settings;

import './SelectTableJoinPathModal.css';

// Libraries
import { default as ReactModal } from 'react-modal';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// Components
import Button from 'components/Buttons/Button/Button';

// Models
import { default as TablePropertyModel } from 'views/Segmentation/models/tableProperty';

function SelectTableJoinPathModal({
    isOpen,
    setOpen,
    tableProperty,
    selectJoinPathCallBack = () => {},
}) {
    ReactModal.setAppElement('#root');

    // needed for the modal contents to re-render on path change
    const [selectedPathIndex, setSelectedPathIndex] = useState();

    const updateSelectedPathIndex = (i) => {
        setSelectedPathIndex(i);
        const joinPath = tableProperty.setSelectedTreePathIndex(i);
        if (selectJoinPathCallBack) {
            selectJoinPathCallBack(joinPath);
        }
    };

    useEffect(() => {
        if (!tableProperty) return;
        setSelectedPathIndex(tableProperty.selectedTreePathIndex);
    }, [tableProperty]);

    const style = {
        overlay: {
            zIndex: '10',
        },
        content: {
            width: 'auto',
            height: 'auto',
            maxWidth: '95%',
            maxHeight: '95%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '23px',
            overflow: 'hidden',
            padding: '0',
            display: 'flex',
        },
    };

    return (
        <ReactModal isOpen={isOpen} style={style} onRequestClose={() => setOpen(false)}>
            <div className="select-join-path-modal">
                <h2>Select Table Join Path</h2>
                <div className="join-paths">
                    {tableProperty &&
                        tableProperty.treePaths.map((path, i) => {
                            return (
                                <div className="path-container" key={i}>
                                    <div
                                        className={`path ${
                                            i === selectedPathIndex ? 'selected' : ''
                                        }`}
                                        onClick={() => updateSelectedPathIndex(i)}
                                    >
                                        {path.map((node, j) => {
                                            return (
                                                <div className="path-node" key={j}>
                                                    {node.direction && node.direction === 'asc' && (
                                                        <div className="arrow-up">
                                                            <div className="arrow-point" />
                                                            <div className="arrow-body top" />
                                                            <div className="table-fk">
                                                                {node.join.from.name}
                                                            </div>
                                                            <div className="arrow-body bottom" />
                                                        </div>
                                                    )}
                                                    {node.direction && node.direction === 'desc' && (
                                                        <div className="arrow-down">
                                                            <div className="arrow-body top" />
                                                            <div className="table-fk">
                                                                {node.join.from.name}
                                                            </div>
                                                            <div className="arrow-body bottom" />
                                                            <div className="arrow-point" />
                                                        </div>
                                                    )}
                                                    <div className="table-node">
                                                        {node.table.name}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="button-row">
                    <Button width="150px" onClick={() => setOpen(false)}>
                        Select Path
                    </Button>
                </div>
            </div>
        </ReactModal>
    );
}

SelectTableJoinPathModal.propTypes = {
    isOpen: PropTypes.bool,
    setOpen: PropTypes.func,
    tableProperty: PropTypes.instanceOf(TablePropertyModel),
};

export default SelectTableJoinPathModal;

import './InputError.css';

// Libraries
import PropTypes from 'prop-types';

// Assets
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon_24.svg';

const InputError = ({ error }) => {
    return (
        <div className="input-error" style={{ opacity: error ? 1 : 0 }}>
            <div className="input-error-icon">
                <ErrorIcon />
            </div>
            <span className="input-error-text">{error}</span>
        </div>
    );
};

InputError.propTypes = {
    error: PropTypes.string,
};

export default InputError;

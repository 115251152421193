// Libraries
import { useDragLayer } from 'react-dnd';

// Components
import Filter from '../Filter/Filter.js';
import FilterGroup from '../FilterGroup/FilterGroup.js';

// Variables & Models
import { DraggableItemTypes } from 'views/Segmentation/models/definitions.js';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

const getItemStyles = (currentOffset) => {
    if (!currentOffset) {
        return {
            display: 'none',
        };
    }
    const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
};

const SegmentDragLayer = (props) => {
    const { itemType, isDragging, item, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));
    const renderItem = () => {
        switch (itemType) {
            case DraggableItemTypes.FILTER:
                return (
                    <Filter
                        filter={item.data.filter}
                        isDraggingPlaceholder={true}
                        placeholderWidth={item.width}
                    />
                );
            case DraggableItemTypes.FILTERGROUP:
                return (
                    <FilterGroup
                        filterGroup={item.data.filter}
                        isDraggingPlaceholder={true}
                        placeholderWidth={item.width}
                    />
                );
            default:
                return null;
        }
    };
    if (!isDragging) {
        return null;
    }
    return (
        <div style={layerStyles}>
            <div style={getItemStyles(currentOffset, props.snapToGrid)}>{renderItem()}</div>
        </div>
    );
};

export default SegmentDragLayer;

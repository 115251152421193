import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
    useQuery,
    useLazyQuery,
    useMutation,
    gql,
    from,
} from '@apollo/client';
import { TOKEN_NAMESPACE, TOKEN_EXP_NAMESPACE } from 'components/useToken';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_BACKEND_URL}/graphql/v2`,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(TOKEN_NAMESPACE);
    const exp = localStorage.getItem(TOKEN_EXP_NAMESPACE);

    // Check locally if token is expired
    const timestamp = new Date().getTime();
    if (exp && exp < timestamp) {
        console.log('APOLLO.JS :: Token Expired.');
        window.location.href = '/logout';
    }

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const defaultOptions = {
    query: {
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all',
    },
};

const client = new ApolloClient({
    link: from([authLink, httpLink]),
    ssrMode: true,
    cache: new InMemoryCache(),
    defaultOptions,
});

export { ApolloProvider, client, useQuery, useLazyQuery, useMutation, gql };

import './SegmentPreview.css';

// Libraries
import { useEffect, useState, useRef } from 'react';
import {
    GET_KPIS_FOR_TABLE,
    previewSegment,
    useQuery,
    GET_SEGMENT_TABLE_TREE,
} from 'utils/graphql';
import { v4 as uuidv4 } from 'uuid';

// Components
import Dropdown from 'components/Form/Dropdown/Dropdown';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import IconButton from 'components/Buttons/IconButton/IconButton';
import SelectTableJoinPathModal from 'components/Segment/SelectTableJoinPathModal/SelectTableJoinPathModal';

// Assets
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';
import { ReactComponent as RouteIcon } from 'assets/icons/route_icon_24.svg';

// Variables & models
import { visualTypes } from 'views/Kpis/KpiBuilder/definitions';
import TableProperty from '../models/tableProperty';

// Utils & hooks
import useScreenResize from 'hooks/useScreenResize';
import Visualization from 'components/Visualization/Visualization';

const SegmentPreview = ({
    name,
    segmentType,
    primaryTable,
    includeFilterGroup,
    excludeFilterGroup,
    consent,
    consentJoinPath,
}) => {
    const [kpis, setKpis] = useState(['']);
    const [selectedKpiId, setSelectedKpiId] = useState('');
    const [kpiPrimaryTableId, setKpiPrimaryTableId] = useState();
    const [isLoadingChart, setIsLoadingChart] = useState(false);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState();
    const [noCompatibleKpis, setNoCompatibleKpis] = useState(false);
    const [hasNoResults, setHasNoResults] = useState(false);
    const [previewObservable, setPreviewObservable] = useState();
    const [segmentTableProperty, setSegmentTableProperty] = useState();
    const [isTableJoinPathModalOpen, setIsTableJoinPathModalOpen] = useState(false);

    const [title, setTitle] = useState();
    const [chartData, setChartData] = useState();
    const [visualStyle, setVisualStyle] = useState();
    const [previewResults, setPreviewResults] = useState();

    const chartRef = useRef(null);
    const chartContainerRef = useScreenResize(() => {
        chartRef.current && chartRef.current.resize();
    });

    const { loading: isLoadingKpis } = useQuery(GET_KPIS_FOR_TABLE, {
        variables: {
            id: primaryTable.id,
        },
        fetchPolicy: 'cache-and-network',
        skip: !primaryTable.id,
        onCompleted: (results) => {
            const kpis = results.kpiListForTable;
            setKpis(kpis);
            if (kpis.length > 0) {
                setSelectedKpiId(kpis[0].id);
            } else {
                setNoCompatibleKpis(true);
            }
        },
    });

    const { data: tableTreeData, loading: loadingTableTree } = useQuery(GET_SEGMENT_TABLE_TREE, {
        skip: !kpiPrimaryTableId,
        variables: { tableId: kpiPrimaryTableId },
    });

    const abortLatestPreviewSegmentQuery = () =>
        previewObservable && previewObservable.unsubscribe();

    const loadKpiPreview = async (tableProperty) => {
        setIsLoadingChart(true);
        setHasNoResults(false);
        const segmentation = {
            name: name,
            isConsent: segmentType === 'consent' ? true : false,
            segmentationJoinPath: tableProperty.joinPath
                ? JSON.stringify(tableProperty.joinPath)
                : null,
            primaryTableId: primaryTable.id,
            includeFilterGroup: includeFilterGroup,
            excludeFilterGroup: excludeFilterGroup,
            consentId: consent ? consent.global_id : null,
            consentJoinPath: consentJoinPath,
            kpiId: selectedKpiId,
        };

        const previewSegmentObservable = previewSegment(segmentation, {
            fetchPolicy: 'network-only',
        }).subscribe((response) => {
            if (response.errors) {
                openModal('loadingError');
            } else {
                const previewData = response.data.segmentationPreview;
                if (previewData.results.length === 0) {
                    setHasNoResults(true);
                    setIsLoadingChart(false);
                    return;
                }
                setTitle(previewData.name);
                const xAxis = previewData.kpi_dimensions.find(
                    (dimension) => dimension.name === 'x_axis'
                );
                const yAxis = previewData.kpi_dimensions.find(
                    (dimension) => dimension.name === 'y_axis'
                );
                const zAxis = previewData.kpi_dimensions.find(
                    (dimension) => dimension.name === 'z_axis'
                );
                const groupBy = previewData.kpi_dimensions.find(
                    (dimension) => dimension.name === 'grouped_by'
                );
                setChartData({
                    xAxis,
                    yAxis,
                    zAxis,
                    groupBy,
                    displayType: previewData.display_as,
                    useDenominator: previewData.graph_type === visualTypes.SINGLE_VALUE && yAxis,
                });
                setVisualStyle(previewData.graph_type);
                setPreviewResults(previewData.results);
            }
            setIsLoadingChart(false);
        });
        setPreviewObservable(previewSegmentObservable);
    };

    const setJoinPathCallBack = (joinPath) => {
        const updatedTableProperty = new TableProperty(
            segmentTableProperty.tableProperty,
            segmentTableProperty.primaryTableTree,
            joinPath
        );
        setSegmentTableProperty(updatedTableProperty);
        loadKpiPreview(updatedTableProperty);
    };

    const openModal = (type) => {
        setModalType(type);
        setIsOpen(true);
    };

    const getModalOptions = () => {
        switch (modalType) {
            case 'loadingError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: <p>There was an error loading the {segmentType} results.</p>,
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    useEffect(() => {
        if (!selectedKpiId) return;
        // abort the previous preview query
        abortLatestPreviewSegmentQuery();

        const selectedKpi = kpis.find((k) => k.id === selectedKpiId);
        if (selectedKpi.primary_table !== kpiPrimaryTableId) {
            setKpiPrimaryTableId(selectedKpi.primary_table);
            return;
        }

        if (!tableTreeData) return;
        const tableProperty = new TableProperty(
            {
                id: uuidv4(),
                property: 'id',
                table: { ...primaryTable },
                type: { type: 'UUID' },
            },
            tableTreeData.segmentationTableTree
        );
        setSegmentTableProperty(tableProperty);

        // ensuring that table tree has been loaded
        if (loadingTableTree) return;

        loadKpiPreview(tableProperty);
    }, [selectedKpiId, loadingTableTree, tableTreeData]);

    return (
        <div className="segment-preview">
            {isLoadingKpis ? (
                <div className="loader">
                    <Loader />
                </div>
            ) : noCompatibleKpis ? (
                <h2 className="header">This {segmentType} has no compatible KPIs to display.</h2>
            ) : (
                <>
                    <h2 className="header">Select KPI to Visualize</h2>
                    <div
                        style={{
                            marginTop: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Dropdown
                            width="300px"
                            value={selectedKpiId}
                            values={kpis.map((kpi) => {
                                return {
                                    text: kpi.name,
                                    value: kpi.id,
                                };
                            })}
                            setValue={(e) => {
                                setSelectedKpiId(e.target.value);
                            }}
                        />
                        {segmentTableProperty &&
                            segmentTableProperty.tableId !== kpiPrimaryTableId && (
                                <>
                                    <IconButton
                                        tooltip="Select Path"
                                        onClick={() => setIsTableJoinPathModalOpen(true)}
                                        className="select-path-btn"
                                    >
                                        <RouteIcon height={20} width={20} fill="#fff" />
                                    </IconButton>
                                    <SelectTableJoinPathModal
                                        isOpen={isTableJoinPathModalOpen}
                                        setOpen={setIsTableJoinPathModalOpen}
                                        tableProperty={segmentTableProperty}
                                        selectJoinPathCallBack={setJoinPathCallBack}
                                    />
                                </>
                            )}
                    </div>
                </>
            )}
            {!isLoadingKpis &&
                (isLoadingChart || loadingTableTree ? (
                    <div className="loader">
                        <Loader />
                    </div>
                ) : hasNoResults ? (
                    <div className="no-results">
                        <h3>This {segmentType} has no results to display.</h3>
                        <p>Try selecting a different join path.</p>
                    </div>
                ) : (
                    <div ref={chartContainerRef} className="visualization-container">
                        <Visualization
                            title={title}
                            chartData={chartData}
                            visualStyle={visualStyle}
                            chartRef={chartRef}
                            results={previewResults}
                        />
                    </div>
                ))}
            <Modal options={getModalOptions()} isOpen={modalIsOpen} setOpen={setIsOpen} />
        </div>
    );
};

export default SegmentPreview;

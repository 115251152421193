import './Builder.css';

// Libraries
import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';

// Utils
import User from 'utils/user.util';
import { useFetch } from 'utils/rest/request';
import { useNavigate, useParams } from 'react-router-dom';

// Assets
import { ReactComponent as PreviewIcon } from 'assets/icons/preview_icon_40.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save_icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check_icon_24.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon_24.svg';

// Components
import Tooltip from 'components/Tooltip/Tooltip';
import ContentHeader from 'components/ContentHeader/ContentHeader';
import Loader from 'components/Loader/Loader';
import LoadingIconButton from 'components/Buttons/LoadingIconButton/LoadingIconButton';
import IconButton from 'components/Buttons/IconButton/IconButton';
import EditableHighlightField from 'components/EditableHighlightField/EditableHighlightField';

function Builder() {
    let navigate = useNavigate();
    let { app_id } = useParams();
    const editorWidth = '100%';
    const [width, setWidth] = useState({
        html: editorWidth,
        css: '0',
        js: '0',
    });
    const [enabled, setEnabled] = useState('html');
    const [HTML, setHTML] = useState('');
    const [CSS, setCSS] = useState('');
    const [JS, setJS] = useState('');
    const [name, setName] = useState('');
    const [saveStatus, setSaveStatus] = useState();
    const [saveError, setSaveError] = useState('');
    const [updatingPreview, setUpdatingPreview] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [appToEdit, setAppToEdit] = useState(null);

    const editorLanguages = [
        { name: 'HTML', shorthand: 'html', color: '#F49D37', value: HTML, setter: setHTML },
        { name: 'CSS', shorthand: 'css', color: 'var(--accent-color)', value: CSS, setter: setCSS },
        { name: 'Javascript', shorthand: 'js', color: '#8DE969', value: JS, setter: setJS },
    ];

    const { data: app } = useFetch(`/api/apps/fetch/${app_id}`, {
        skip: !app_id,
    });

    const handleEditorChange = (lang) => {
        setEnabled(lang);
        switch (lang) {
            case 'html':
                setWidth({
                    html: editorWidth,
                    css: '0',
                    js: '0',
                });
                break;
            case 'css':
                setWidth({
                    html: '0',
                    css: editorWidth,
                    js: '0',
                });
                break;
            case 'js':
                setWidth({
                    html: '0',
                    css: '0',
                    js: editorWidth,
                });
                break;
            default:
                break;
        }
    };

    const handlePreview = () => {
        setUpdatingPreview(true);
        setTimeout(() => {
            setUpdatingPreview(false);
        }, 1000);

        var iframe = document.createElement('iframe');
        iframe.id = 'app_content';
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 'none';
        iframe.style.overflow = 'hidden';

        var _preview = document.getElementById('preview');
        _preview.innerHTML = '';
        _preview.appendChild(iframe);

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(
            `<head>
                <link rel="stylesheet" href="${
                    process.env.REACT_APP_BACKEND_URL
                }/cdn/css/global.css">
                <style>${CSS}</style>
                <script>
                    API_URL = '${process.env.REACT_APP_BACKEND_URL}/';
                    TOKEN = '${User.getToken()}';
                </script>
                <script src="${process.env.REACT_APP_BACKEND_URL}/cdn/js/paginator.js"></script>
                <script src="//code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
                <script src="${process.env.REACT_APP_BACKEND_URL}/cdn/js/lib.js"></script>
            </head>
            <body>
                ${HTML}
                <script>${JS}</script>
            </body>`
        );
        iframe.contentWindow.document.close();
    };

    const handleSave = async () => {
        setIsSaving(true);
        if (!(await validate())) {
            setIsSaving(false);
            return;
        }

        if (app_id) {
            update();
        } else {
            create();
        }
    };

    const create = () => {
        fetch(process.env.REACT_APP_BACKEND_URL + '/api/apps/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + User.getToken(),
            },
            body: JSON.stringify({
                name,
                html: HTML,
                js: JS,
                css: CSS,
            }),
        })
            .then((res) => res.json())
            .then((save) => {
                setIsSaving(false);
                if (!save.success) {
                    setSaveError('Error saving');
                    setSaveStatus('error');
                    return false;
                }

                showSuccessIcon();
                if (save.id && !app_id) {
                    setTimeout(() => {
                        navigate(`/apps/edit/${save.id}`);
                    }, 1000);
                }
            })
            .catch((err) => {
                setIsSaving(false);
                console.log(err);
                setSaveStatus('error');
            });
    };

    const showSuccessIcon = () => {
        setSaveStatus('success');
        setTimeout(() => {
            setSaveStatus();
        }, 3000);
    };

    const update = () => {
        fetch(process.env.REACT_APP_BACKEND_URL + '/api/apps/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + User.getToken(),
            },
            body: JSON.stringify({
                name,
                id: app_id,
                html: HTML,
                js: JS,
                css: CSS,
            }),
        })
            .then((res) => res.json())
            .then((save) => {
                setIsSaving(false);
                if (!save.success) {
                    setSaveError('Error saving');
                    setSaveStatus('error');
                    return false;
                }

                showSuccessIcon();
            })
            .catch((err) => {
                setIsSaving(false);
                console.log(err);
                setSaveStatus('error');
            });
    };

    const validate = async () => {
        if (name.length < 3) {
            setSaveError('App name must be at least 3 characters long.');
            setSaveStatus('error');
            return false;
        }

        if (!app_id || name !== appToEdit.app_name) {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/apps/validate_name`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + User.getToken(),
                    },
                    body: JSON.stringify({
                        name,
                    }),
                }
            );
            const jsonResponse = await response.json();
            if (jsonResponse && !jsonResponse.success) {
                setSaveError('The app name must be unique.');
                setSaveStatus('error');
                return false;
            }
        }

        setSaveError('');
        setSaveStatus();
        return true;
    };

    useEffect(() => {
        if (loading) return;
        var editor = document.getElementById('editor');

        function handleUpdateSave(event) {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                handlePreview();
                handleSave();
                return false;
            }
        }
        editor.addEventListener('keydown', handleUpdateSave);

        return function cleanupListener() {
            editor.removeEventListener('keydown', handleUpdateSave);
        };
    }, [loading, app_id, HTML, CSS, JS, name]);

    /**
     * Display preview once app is loaded
     */
    useEffect(() => {
        if (loading) return;
        setTimeout(() => {
            if (app_id) {
                handlePreview();
            }
        }, 1000);
    }, [loading]);

    /**
     * Loading existing app
     */
    useEffect(() => {
        if (!app) return;

        // Permission denied
        if (app.is_default) return;

        setAppToEdit(app);
        setName(app.app_name);
        setHTML(app.html);
        setCSS(app.css);
        setJS(app.js);
        setShowPreview(true);
        setLoading(false);
    }, [app]);

    /**
     * Create new app
     */
    useEffect(() => {
        if (app_id) return;
        setLoading(false);
    }, []);

    return (
        <div className="app-builder">
            {!loading ? (
                <>
                    <ContentHeader style={{ justifyContent: 'space-between' }}>
                        <EditableHighlightField
                            defaultIsEditing={!app_id}
                            defaultDisplayName="App Name"
                            placeholder="App Name"
                            value={name}
                            setValue={(value) => setName(value)}
                        />
                        <div className="header-buttons">
                            {saveStatus === 'error' && (
                                <Tooltip tip={saveError} width="120px">
                                    <ErrorIcon fill="var(--error-color)" />
                                </Tooltip>
                            )}
                            {saveStatus === 'success' && <CheckIcon fill="var(--success-color)" />}
                            <LoadingIconButton
                                isLoading={isSaving}
                                onClick={handleSave}
                                className="app-builder-save"
                                loaderColor="dark"
                            >
                                <SaveIcon fill="var(--accent-color)" height="24" width="24" />
                            </LoadingIconButton>
                        </div>
                    </ContentHeader>
                    <div id="preview" className={`preview ${showPreview ? 'active' : ''}`}></div>
                    <ContentHeader>
                        <div className="editor-header">
                            <div className="editor-labels">
                                {editorLanguages.map((editorLang, i) => (
                                    <div
                                        key={i}
                                        className="label"
                                        onClick={() => {
                                            handleEditorChange(editorLang.shorthand);
                                        }}
                                        style={{
                                            borderBottom: `2px solid ${
                                                enabled === editorLang.shorthand
                                                    ? editorLang.color
                                                    : 'rgba(0,0,0,0)'
                                            }`,
                                        }}
                                    >
                                        {editorLang.name}
                                    </div>
                                ))}
                            </div>
                            <IconButton
                                onClick={() => {
                                    handlePreview();
                                    setShowPreview(true);
                                }}
                                tooltip="Preview"
                            >
                                <PreviewIcon
                                    height="24"
                                    width="24"
                                    fill={updatingPreview ? 'var(--accent-color)' : undefined}
                                />
                            </IconButton>
                        </div>
                    </ContentHeader>
                    <div className="editor-blocks" id="editor">
                        {editorLanguages.map((editorLang, i) => (
                            <div
                                key={i}
                                className="editor-block"
                                style={
                                    enabled === editorLang.shorthand
                                        ? { border: `2px solid ${editorLang.color}`, width: '100%' }
                                        : {}
                                }
                            >
                                <AceEditor
                                    placeholder={editorLang.name}
                                    mode={editorLang.name.toLowerCase()}
                                    theme="tomorrow"
                                    width={width[editorLang.shorthand]}
                                    height="600px"
                                    value={editorLang.value}
                                    onChange={(value) => {
                                        editorLang.setter(value);
                                    }}
                                    // name="UNIQUE_ID_OF_DIV"
                                    // editorProps={{ $blockScrolling: true }}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true,
                                        printMarginColumn: 120,
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
}

export default Builder;

class LineChartData {
    constructor(labels, datasets = [], title = null) {
        this.labels = labels;
        this.title = title;
        this.datasets = datasets;
    }

    addDataSet(dataset) {
        if (!this.datasets) {
            this.datasets = [];
        }
        if (dataset) {
            this.dataset.push(dataset);
        }
    }
}

export default LineChartData;
